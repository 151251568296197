import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  AdvanceTextField,
  SearchControlsContainer
} from "$Imports/CommonComponents";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from "$Imports/MaterialUIComponents";

import {
  CustomerBillingStatusEnum,
  QuoteSearchCriteria
} from "$Generated/api";

import {
  ICustomerServiceInjectedProps,
  CustomerService
} from "$State/CustomerFreezerService";

import {
  IQuoteServiceInjectedProps,
  QuoteService
} from "$State/QuoteFreezerService";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

interface IOwnProps {
  billingStatus: CustomerBillingStatusEnum;
}

type OwnProps = IOwnProps
  & ICustomerServiceInjectedProps
  & IQuoteServiceInjectedProps;

const styles: {
  container: string;
} = require("./QuotesBillingStatusSearchForm.scss");

class _QuotesBillingStatusSearchForm extends React.Component<OwnProps, {}> {
  @bind
  private _onQuoteOrPONumChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.props.quoteService.onSearchModelChanged({ quoteOrFreightNumber: e.target.value }, true);
  }

  @bind
  private _onMenuItemChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.value === "") {
      this.props.quoteService.onSearchModelChanged({ [e.target.name]: undefined }, true);
    } else {
      this.props.quoteService.onSearchModelChanged({ [e.target.name]: parseInt(e.target.value)}, true);
    }
  }

  @bind
  private _onFieldKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      this._onSubmit();
    }
  }

  @bind
  private _onSubmit() {
    this.props.quoteService.fetchBillingStatusQuotes(true);
  }

  @bind
  private _onClear() {
    this.props.quoteService.onResetBillingStatusSearchModel(this.props.billingStatus);
    this._onSubmit();
  }

  render() {
    const {
      billingStatusQuotesFetchResults: checkCreditQuotesFetchResults,
      billingStatusSearchCriteria: creditChecksSearchCriteria,
      searchValidationErrors
    } = this.props.quoteService.getState();

    const { billToCustomersResults } = this.props.customerService.getState();
    const customers = billToCustomersResults.data ?? [];

    const validationsParser = new ValidationErrorParser<QuoteSearchCriteria>(searchValidationErrors);

    return (
      <SearchControlsContainer
        className={styles.container}
        onSubmit={this._onSubmit}
        onClear={this._onClear}
        disabled={checkCreditQuotesFetchResults.isFetching}
      >
        <div style={{ flex: "0 0 10rem" }}>
          <AdvanceTextField
            label="Quote  / FB#"
            onChange={this._onQuoteOrPONumChange}
            value={creditChecksSearchCriteria.quoteOrFreightNumber ?? ""}
            error={!validationsParser.isValid("quoteOrFreightNumber")}
            helperText={validationsParser.validationMessage("quoteOrFreightNumber")}
            onKeyDown={this._onFieldKeyDown}
          />
        </div>

        <FormControl style={{ flex: "0 0 14rem" }}>
          <InputLabel shrink>Bill-To Customer</InputLabel>
          <Select
            name="customerId"
            value={creditChecksSearchCriteria.customerId?.toString() ?? ""}
            onChange={(event) => this._onMenuItemChange(event as React.ChangeEvent<HTMLInputElement>)}
            displayEmpty
          >
            <MenuItem value={""}>All</MenuItem>
            {customers.map((customer, index) => (
              <MenuItem key={index} value={customer.id}>
                {customer.customerName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

      </SearchControlsContainer>
    );
  }
}

export const QuotesBillingStatusSearchForm = CustomerService.inject(
  QuoteService.inject(
    _QuotesBillingStatusSearchForm
  )
);
