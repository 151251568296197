/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as querystring from "querystring";
import * as url from "url";

const isomorphicFetch = fetch;
const assign: (target: any, ...sources: any[]) => any = (Object as any).assign;

interface Dictionary<T> { [index: string]: T; }
export interface FetchAPI { (url: string, init?: any): Promise<any>; }

const BASE_PATH = "https://localhost".replace(/\/+$/, "");

export interface FetchArgs {
    url: string;
    options: any;
}

export class BaseAPI {
    basePath: string;
    fetch: FetchAPI;

    constructor(fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) {
        this.basePath = basePath;
        this.fetch = fetch;
    }
};

export interface AboutVM {
    "productName"?: string;
    "productVersion"?: string;
    "salesPortalUrl"?: string;
    "reportingUrl"?: string;
    "pcMilerProductName"?: string;
    "pcMilerProductVersion"?: string;
    "pcMilerApiVersion"?: string;
}

export interface AccessorialCharge {
    "id"?: number;
    "tmChargeCode"?: string;
    "description"?: string;
    "isActive"?: boolean;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "accessorialChargeValues"?: Array<AccessorialChargeValue>;
}

export interface AccessorialChargeValue {
    "id"?: number;
    "accessorialChargeId"?: number;
    "amount"?: number;
    "createdById"?: number;
    "createdOn"?: Date;
    "notes"?: string;
    "quoteId"?: number;
    "status"?: AccessorialChargeValueStatusEnum;
    "accessorialCharge"?: AccessorialCharge;
    "quote"?: Quote;
}

export type AccessorialChargeValueStatusEnum = "Pending" | "Approved";
export interface Activity {
    "id"?: number;
    "customerId"?: number;
    "noteText"?: string;
    "isPinned"?: boolean;
    "createdById"?: number;
    "createdOn"?: Date;
    "isActive"?: boolean;
    "activityType"?: ActivityActivityTypeEnum;
    "createdBy"?: Employee;
    "customer"?: Customer;
}

export type ActivityActivityTypeEnum = "Note" | "PhoneCallCold" | "PhoneCallFirstAppt" | "PhoneCallFollowup" | "InPersonColdCall" | "InPersonFirstAppt" | "InPersonFollowup" | "EmailColdCall" | "EmailFollowup" | "MarketingCampaign" | "Proposal" | "NewAccountHandoff" | "CustomerToProspectHandoff" | "Other";
export interface Address {
    "id"?: number;
    "quoteStopId"?: number;
    "address1"?: string;
    "address2"?: string;
    "city"?: string;
    "regionId"?: number;
    "zipPostalCode"?: string;
    "customerId"?: number;
    "addressType"?: AddressAddressTypeEnum;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "isCurrent"?: boolean;
    "customer"?: Customer;
    "region"?: Region;
}

export type AddressAddressTypeEnum = "Shipper" | "Consignee";
export interface ApplicationSetting {
    "id"?: number;
    "companyId"?: number;
    "settingsKey"?: string;
    "settingsValue"?: string;
    "createdOn"?: Date;
    "createdById"?: number;
    "modifiedOn"?: Date;
    "modifiedById"?: number;
    "company"?: Company;
    "createdBy"?: Employee;
    "modifiedBy"?: Employee;
}

export interface AuditCustomer {
    "customerId"?: number;
    "element"?: string;
    "eventType"?: string;
    "activity"?: string;
    "recordId"?: number;
    "recordName"?: string;
    "fieldChanges"?: Array<FieldChange>;
}

export interface AuditCustomerAuditLog {
    "id"?: number;
    "employeeId"?: number;
    "logType"?: string;
    "log"?: AuditCustomer;
    "createdOn"?: Date;
    "employee"?: Employee;
}

export interface AuditDataChange {
    "oldPropertyValue"?: string;
    "newPropertyValue"?: string;
    "rowIdentifier"?: number;
    "propertyName"?: string;
    "tableName"?: string;
    "relatedEntities"?: Array<RelatedEntity>;
    "message"?: string;
}

export interface AuditDataChangeAuditLog {
    "id"?: number;
    "employeeId"?: number;
    "logType"?: string;
    "log"?: AuditDataChange;
    "createdOn"?: Date;
    "employee"?: Employee;
}

export interface AuditEvent {
    "eventDateTime"?: Date;
    "eventType"?: string;
    "userEmailAddress"?: string;
    "userFirstName"?: string;
    "userLastName"?: string;
}

export interface AuditFetchResult {
    "results"?: Array<AuditEvent>;
    "totalRecords"?: number;
}

export interface AuditRateEngine {
    "companyId"?: number;
    "eventType"?: string;
    "element"?: string;
    "change"?: string;
}

export interface AuditRateEngineAuditLog {
    "id"?: number;
    "employeeId"?: number;
    "logType"?: string;
    "log"?: AuditRateEngine;
    "createdOn"?: Date;
    "employee"?: Employee;
}

export interface AuditSearchCriteria {
    "startDate"?: Date;
    "endDate"?: Date;
    "sortColumn"?: string;
    "sortAscending"?: boolean;
    "startIndex"?: number;
    "pageSize"?: number;
}

export interface BillingStatusQuote {
    "id"?: number;
    "companyKey"?: string;
    "freightBillNumber"?: string;
    "quoteDate"?: Date;
    "pickupDate"?: Date;
    "showPickupTime"?: boolean;
    "negotiatedRate"?: number;
    "createdBy"?: string;
    "billTo"?: Customer;
}

export interface BillingStatusQuoteSearchResult {
    "results"?: Array<BillingStatusQuote>;
    "totalRecords"?: number;
    "numberOfRecords"?: number;
}

export interface BookedSalesParametersVM {
    "companyId"?: number;
    "startDate"?: Date;
    "endDate"?: Date;
    "metricType"?: string;
}

export interface BooleanResponseBase {
    "data"?: boolean;
    "success"?: boolean;
    "error"?: BooleanResponseBaseError;
    "totalCount"?: number;
}

export interface BooleanResponseBaseError {
    "message"?: string;
    "source"?: string;
    "stackTrace"?: string;
}

export interface CalculateRatingVariableResult {
    "ratingVariable"?: number;
    "ratingVariableType"?: CalculateRatingVariableResultRatingVariableTypeEnum;
    "totalLength"?: number;
    "totalWeight"?: number;
    "isWeightOverdimensional"?: boolean;
    "error"?: string;
}

export type CalculateRatingVariableResultRatingVariableTypeEnum = "Error" | "Length" | "Weight" | "Overdimensional";
export interface CancelQuoteParams {
    "quoteId"?: number;
    "companyId"?: number;
    "cancellationReason"?: CancelQuoteParamsCancellationReasonEnum;
    "cancellationDetails"?: string;
}

export type CancelQuoteParamsCancellationReasonEnum = "Customer" | "Kaiser" | "Other";
export interface CapacityRate {
    "id"?: number;
    "companyId"?: number;
    "rateValue"?: number;
    "startDateTime"?: Date;
    "endDateTime"?: Date;
    "isFavorite"?: boolean;
    "favoriteName"?: string;
    "reason"?: string;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "originRegions"?: Array<Region>;
    "destinationRegions"?: Array<Region>;
    "originZones"?: Array<Zone>;
    "destinationZones"?: Array<Zone>;
}

export interface CapacityRateResponseBase {
    "data"?: CapacityRate;
    "success"?: boolean;
    "error"?: BooleanResponseBaseError;
    "totalCount"?: number;
}

export interface CarrierMetrics {
    "takeRate"?: number;
}

export interface CarrierMetricsParameters {
    "metricType"?: string;
    "employeeId"?: number;
    "marketFilter"?: string;
    "startDate"?: Date;
    "endDate"?: Date;
}

export interface Commodity {
    "id"?: number;
    "commodityName"?: string;
    "commodityDescription"?: string;
    "isActive"?: boolean;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "commodityShortName"?: string;
    "isStackable"?: boolean;
    "isSideBySide"?: boolean;
    "tmCommodityId"?: string;
    "commodityValues"?: Array<CommodityValue>;
    "rateModelTests"?: Array<RateModelTest>;
    "commodityQuestions"?: Array<CommodityQuestion>;
    "commodityExclusions"?: Array<CommodityExclusion>;
}

export interface CommodityExclusion {
    "id"?: number;
    "commodityId"?: number;
    "customerId"?: number;
    "commodity"?: Commodity;
    "customer"?: Customer;
}

export interface CommodityQuestion {
    "id"?: number;
    "questionId"?: number;
    "commodityId"?: number;
    "commodity"?: Commodity;
    "question"?: Question;
}

export interface CommodityRate {
    "zoneId"?: number;
    "zoneName"?: string;
    "companyId"?: number;
    "startDateTime"?: Date;
    "endDateTime"?: Date;
    "commodityValues"?: { [key: string]: CommodityValue; };
}

export interface CommodityValue {
    "id"?: number;
    "commodityId"?: number;
    "zoneId"?: number;
    "companyId"?: number;
    "startDateTime"?: Date;
    "endDateTime"?: Date;
    "percentRate"?: number;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "commodity"?: Commodity;
    "company"?: Company;
    "zone"?: Zone;
}

export interface Company {
    "id"?: number;
    "companyKey"?: string;
    "companyName"?: string;
    "primaryRate"?: number;
    "secondaryRate"?: number;
    "tmcompanyId"?: number;
    "adminEmployeeId"?: number;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
}

export interface ContactType {
    "id"?: number;
    "type"?: string;
    "customerContacts"?: Array<CustomerContact>;
}

export interface Customer {
    "id"?: number;
    "accountManagerId"?: number;
    "businessDevManagerId"?: number;
    "customerSourceId"?: number;
    "prospectId"?: number;
    "customerName"?: string;
    "tmcustomerId"?: string;
    "contactName"?: string;
    "emailAddress"?: string;
    "address1"?: string;
    "address2"?: string;
    "city"?: string;
    "regionId"?: number;
    "phoneNumber"?: string;
    "cellNumber"?: string;
    "zipPostalCode"?: string;
    "website"?: string;
    "isCaller"?: boolean;
    "isShipper"?: boolean;
    "isConsignee"?: boolean;
    "isActive"?: boolean;
    "isProspect"?: boolean;
    "sourceDetails"?: string;
    "displayAlert"?: boolean;
    "alert"?: string;
    "customerSince"?: Date;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "hasCustomerPortalAccess"?: boolean;
    "isBillTo"?: boolean;
    "billingStatus"?: CustomerBillingStatusEnum;
    "creditLimit"?: number;
    "dueDays"?: number;
    "hasBillingAddress"?: boolean;
    "billingAddress1"?: string;
    "billingAddress2"?: string;
    "billingCity"?: string;
    "billingRegionId"?: number;
    "billingZipPostalCode"?: string;
    "oneTimeTmSync"?: boolean;
    "estAvgMonthlyFreightBills"?: number;
    "estAvgRevenuePerFreightBill"?: number;
    "accountManager"?: Employee;
    "businessDevManager"?: Employee;
    "customerSource"?: CustomerSource;
    "prospect"?: Prospect;
    "billingRegion"?: Region;
    "region"?: Region;
    "quotes"?: Array<Quote>;
    "commodityExclusions"?: Array<CommodityExclusion>;
    "customerQuotes"?: Array<CustomerQuote>;
    "customerContacts"?: Array<CustomerContact>;
    "activities"?: Array<Activity>;
    "customerHours"?: Array<CustomerHour>;
    "customerLoadingInstructions"?: Array<CustomerLoadingInstruction>;
}

export type CustomerBillingStatusEnum = "CheckCredit" | "CreditConfirmed" | "CODOnly";
export interface CustomerActivityCriteria {
    "companyId"?: number;
    "dateRange"?: CustomerActivityCriteriaDateRangeEnum;
}

export type CustomerActivityCriteriaDateRangeEnum = "CurrentMonth" | "Last30Days" | "Last3Months" | "Last6Months" | "Last9Months" | "Last12Months";
export interface CustomerActivityHistoryData {
    "groupKey"?: Date;
    "quotes"?: number;
    "sales"?: number;
}

export interface CustomerActivityResult {
    "totalQuotes"?: number;
    "acceptedQuotes"?: number;
    "totalCharges"?: number;
    "averageMiles"?: number;
    "averageRatePerKPerMi"?: number;
    "averageRatePerFtPerMi"?: number;
    "history"?: Array<CustomerActivityHistoryData>;
}

export interface CustomerContact {
    "id"?: number;
    "customerId"?: number;
    "customerUserId"?: number;
    "firstName"?: string;
    "lastName"?: string;
    "nameSuffixId"?: number;
    "emailAddress"?: string;
    "phoneNumber"?: string;
    "cellNumber"?: string;
    "title"?: string;
    "notes"?: string;
    "contactTypeId"?: number;
    "isPrimary"?: boolean;
    "isActive"?: boolean;
    "isHidden"?: boolean;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "contactType"?: ContactType;
    "customer"?: Customer;
    "customerUser"?: CustomerUser;
    "nameSuffix"?: NameSuffix;
}

export interface CustomerConvertParams {
    "isShipper"?: boolean;
    "isConsignee"?: boolean;
    "accountManagerId"?: number;
}

export interface CustomerEntityLink {
    "isProspect"?: boolean;
    "linkId"?: number;
    "linkName"?: string;
}

export interface CustomerHour {
    "id"?: number;
    "allDay"?: boolean;
    "closeTime"?: string;
    "closed"?: boolean;
    "customerId"?: number;
    "dayOfWeek"?: CustomerHourDayOfWeekEnum;
    "openTime"?: string;
    "auditDisplayValue"?: string;
    "hasHours"?: boolean;
}

export type CustomerHourDayOfWeekEnum = "Sunday" | "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday";
export interface CustomerLoadingInstruction {
    "id"?: number;
    "customerId"?: number;
    "loadingInstructionId"?: number;
    "customer"?: Customer;
    "loadingInstruction"?: LoadingInstruction;
}

export interface CustomerQuote {
    "id"?: number;
    "companyId"?: number;
    "customerId"?: number;
    "quoteDate"?: Date;
    "expirationDate"?: Date;
    "shipperZipPostalCode"?: string;
    "shipperStartDate"?: Date;
    "shipperEndDate"?: Date;
    "shipperHardTime"?: string;
    "isShipperAppointmentRequired"?: boolean;
    "consigneeZipPostalCode"?: string;
    "consigneeStartDate"?: Date;
    "consigneeEndDate"?: Date;
    "consigneeHardTime"?: string;
    "isConsigneeAppointmentRequired"?: boolean;
    "declaredValue"?: number;
    "description"?: string;
    "notes"?: string;
    "tarpId"?: number;
    "equipmentTypeId"?: number;
    "rateEngineResults"?: string;
    "quoteStatus"?: CustomerQuoteQuoteStatusEnum;
    "quoteStatusMessage"?: string;
    "mileage"?: number;
    "datRate"?: number;
    "rateVariable"?: number;
    "rateVariableFactor"?: CustomerQuoteRateVariableFactorEnum;
    "rate"?: number;
    "createdOn"?: Date;
    "createdById"?: number;
    "isExpedited"?: boolean;
    "quoteNumber"?: number;
    "copiedQuoteNumber"?: number;
    "copiedQuoteRate"?: number;
    "poNumber"?: string;
    "customerContactId"?: number;
    "isReviewed"?: boolean;
    "isMilitaryBase"?: boolean;
    "isTwicCardRequired"?: boolean;
    "fullQuote"?: SimplifiedFullQuote;
    "company"?: Company;
    "createdBy"?: CustomerUser;
    "customer"?: Customer;
    "customerContact"?: CustomerContact;
    "tarp"?: Tarp;
    "equipmentType"?: EquipmentType;
    "customerQuoteFreights"?: Array<CustomerQuoteFreight>;
}

export type CustomerQuoteQuoteStatusEnum = "Accepted" | "Pending" | "ApprovalNeeded" | "Declined" | "InProgress" | "Expired" | "AcceptanceRejected" | "PendingNeedsCustomers" | "Approved" | "AdminRerate" | "PendingResave" | "Convert" | "Converted" | "Canceled" | "Requested" | "Completed";
export type CustomerQuoteRateVariableFactorEnum = "Error" | "Length" | "Weight" | "Overdimensional";
export interface CustomerQuoteFreight {
    "id"?: number;
    "customerQuoteId"?: number;
    "commodityId"?: number;
    "numberOfPieces"?: number;
    "weight"?: number;
    "width"?: number;
    "length"?: number;
    "height"?: number;
    "isStackable"?: boolean;
    "isSideBySide"?: boolean;
    "createdOn"?: Date;
    "commodity"?: Commodity;
    "customerQuote"?: CustomerQuote;
}

export interface CustomerQuotesSearchCriteria {
    "quoteNumber"?: string;
    "quoteOrPONumber"?: string;
    "description"?: string;
    "notes"?: string;
    "dateType"?: CustomerQuotesSearchCriteriaDateTypeEnum;
    "quoteDate"?: Date;
    "startDate"?: Date;
    "endDate"?: Date;
    "isReviewed"?: boolean;
    "companyId"?: number;
    "quoteStatuses"?: Array<CustomerQuotesSearchCriteriaQuoteStatusesEnum>;
    "accountManagerId"?: number;
    "customerId"?: number;
    "quoteListType"?: string;
    "sortColumn"?: string;
    "sortAscending"?: boolean;
    "startIndex"?: number;
    "pageSize"?: number;
}

export type CustomerQuotesSearchCriteriaDateTypeEnum = "QuoteDate" | "DeliveryDate" | "ExpirationDate" | "PickupDate";
export type CustomerQuotesSearchCriteriaQuoteStatusesEnum = "Accepted" | "Pending" | "ApprovalNeeded" | "Declined" | "InProgress" | "Expired" | "AcceptanceRejected" | "PendingNeedsCustomers" | "Approved" | "AdminRerate" | "PendingResave" | "Convert" | "Converted" | "Canceled" | "Requested" | "Completed";
export interface CustomerSearchCriteria {
    "customerName"?: string;
    "regionAbbreviation"?: string;
    "isProspect"?: boolean;
    "isCaller"?: boolean;
    "isShipper"?: boolean;
    "isConsignee"?: boolean;
    "includeProspects"?: boolean;
    "isBillToSearch"?: boolean;
    "accountManagerId"?: number;
    "businessDevManagerId"?: number;
    "callerId"?: number;
    "industryType"?: CustomerSearchCriteriaIndustryTypeEnum;
    "currentProvider"?: string;
    "percentageToClose"?: CustomerSearchCriteriaPercentageToCloseEnum;
    "billingStatus"?: CustomerSearchCriteriaBillingStatusEnum;
    "sortColumn"?: string;
    "sortAscending"?: boolean;
    "startIndex"?: number;
    "pageSize"?: number;
}

export type CustomerSearchCriteriaIndustryTypeEnum = "Aerospace" | "Equipment" | "GeneratorsTransformers" | "MachineTools" | "Steel" | "Other" | "Broker";
export type CustomerSearchCriteriaPercentageToCloseEnum = "NoContactWithDM" | "ContactedDMInterestExpressed" | "Quoted" | "VerballyAcceptedQuote" | "OrderPlaced";
export type CustomerSearchCriteriaBillingStatusEnum = "CheckCredit" | "CreditConfirmed" | "CODOnly";
export interface CustomerSearchResult {
    "results"?: Array<Customer>;
    "totalRecords"?: number;
    "numberOfRecords"?: number;
}

export interface CustomerSource {
    "id"?: number;
    "name"?: string;
    "isActive"?: boolean;
    "requiresAdditionalDetail"?: boolean;
}

export interface CustomerUser {
    "id"?: number;
    "userId"?: string;
    "type"?: CustomerUserTypeEnum;
    "firstName"?: string;
    "lastName"?: string;
    "emailAddress"?: string;
    "tmcustomerId"?: string;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "customerQuotes"?: Array<CustomerQuote>;
    "customerContact"?: CustomerContact;
}

export type CustomerUserTypeEnum = "SalesRep" | "Manager";
export interface CustomersQuotesSearchResult {
    "results"?: Array<SimplifiedCustomersQuote>;
    "totalRecords"?: number;
    "numberOfRecords"?: number;
}

export interface DatAuditReport {
    "quoteId"?: number;
    "quoteNumber"?: number;
    "quoteType"?: DatAuditReportQuoteTypeEnum;
    "quoteDate"?: Date;
    "quoteStatus"?: DatAuditReportQuoteStatusEnum;
    "fbMiles"?: number;
    "length"?: number;
    "weight"?: number;
    "originZip"?: string;
    "originMarket"?: string;
    "destinationZip"?: string;
    "destinationMarket"?: string;
    "datRate"?: number;
    "datTimeFrame"?: number;
    "datMessage"?: string;
}

export type DatAuditReportQuoteTypeEnum = "Full" | "Quick" | "Contract";
export type DatAuditReportQuoteStatusEnum = "Accepted" | "Pending" | "ApprovalNeeded" | "Declined" | "InProgress" | "Expired" | "AcceptanceRejected" | "PendingNeedsCustomers" | "Approved" | "AdminRerate" | "PendingResave" | "Convert" | "Converted" | "Canceled" | "Requested" | "Completed";
export interface DatAuditSearchCriteria {
    "startDate"?: Date;
    "endDate"?: Date;
    "sortColumn"?: string;
    "sortAscending"?: boolean;
    "startIndex"?: number;
    "pageSize"?: number;
}

export interface DatRate {
    "originZipPostalCode"?: string;
    "originMarket"?: string;
    "destZipPostalCode"?: string;
    "destMarket"?: string;
    "rate"?: number;
    "miles"?: number;
    "timeFrameDays"?: number;
    "modifiedOn"?: Date;
    "isCachedZoneRate"?: boolean;
    "hasErrors"?: boolean;
    "message"?: string;
}

export interface DeclaredValueApproval {
    "id"?: number;
    "declaredValue"?: number;
    "companyId"?: number;
    "startDateTime"?: Date;
    "endDateTime"?: Date;
    "createdById"?: number;
    "company"?: Company;
    "createdBy"?: Employee;
}

export interface Employee {
    "id"?: number;
    "userId"?: string;
    "firstName"?: string;
    "lastName"?: string;
    "emailAddress"?: string;
    "isAdmin"?: boolean;
    "isManager"?: boolean;
    "isSalesRep"?: boolean;
    "isBiller"?: boolean;
    "isViewOnly"?: boolean;
    "isCarrierManager"?: boolean;
    "isActive"?: boolean;
    "tmuserCode"?: string;
    "tmsalesAgent"?: string;
    "phoneNumber"?: string;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
}

export interface EmployeeSearchCriteria {
    "isSalesRep"?: boolean;
    "isCarrierRep"?: boolean;
    "isManager"?: boolean;
    "isAccOrBusDevManager"?: boolean;
    "includeInactive"?: boolean;
}

export interface EntityLink {
    "linkId"?: number;
    "linkName"?: string;
}

export interface EquipmentType {
    "id"?: number;
    "name"?: string;
    "tmEquipmentCode"?: string;
    "overdimensionalRulesetId"?: number;
    "isActive"?: boolean;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "equipmentTypeValues"?: Array<EquipmentTypeValue>;
    "quotes"?: Array<Quote>;
    "customerQuotes"?: Array<CustomerQuote>;
}

export interface EquipmentTypeValue {
    "id"?: number;
    "equipmentTypeId"?: number;
    "companyId"?: number;
    "percentRate"?: number;
    "createdById"?: number;
    "startDateTime"?: Date;
    "endDateTime"?: Date;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "company"?: Company;
    "equipmentType"?: EquipmentType;
    "createdBy"?: Employee;
}

export interface FieldChange {
    "dataField"?: string;
    "previousValue"?: string;
    "newValue"?: string;
}

export interface FuelSurcharge {
    "id"?: number;
    "fuelSurchargeValue"?: number;
    "isActive"?: boolean;
    "createdOn"?: Date;
}

export interface LeadSource {
    "id"?: number;
    "name"?: string;
    "isDetailRequired"?: boolean;
    "startDate"?: Date;
    "endDate"?: Date;
    "createdById"?: number;
    "createdOn"?: Date;
    "createdBy"?: Employee;
}

export interface LinearFootCalculation {
    "linearFoot"?: number;
    "percentageOfTrailer"?: number;
    "tssPercentage"?: number;
    "ratePerMile"?: number;
    "pricePerFoot"?: number;
    "miles"?: number;
    "totalRate"?: number;
    "sandboxTSSPercentage"?: number;
    "sandboxRatePerMile"?: number;
    "sandboxPricePerFoot"?: number;
    "sandboxMiles"?: number;
    "sandboxTotalRate"?: number;
}

export interface LinearFootRatesCalculationParams {
    "activeDate"?: Date;
    "sizes"?: Array<number>;
    "companyId"?: number;
    "originZoneId"?: number;
    "destinationZoneId"?: number;
    "originAddress"?: Address;
    "destinationAddress"?: Address;
    "useSandbox"?: boolean;
}

export interface LoadingInstruction {
    "id"?: number;
    "instruction"?: string;
    "appliesTo"?: LoadingInstructionAppliesToEnum;
    "isActive"?: boolean;
}

export type LoadingInstructionAppliesToEnum = "ShippersOnly" | "ConsigneesOnly" | "BothShippersAndConsignees";
export interface LogisticsMarkup {
    "id"?: number;
    "primaryRate"?: number;
    "secondaryRate"?: number;
    "startDateTime"?: Date;
    "endDateTime"?: Date;
    "createdById"?: number;
    "createdBy"?: Employee;
}

export interface NameSuffix {
    "id"?: number;
    "suffixValue"?: string;
    "customerContacts"?: Array<CustomerContact>;
}

export interface Opportunity {
    "id"?: number;
    "opportunityId"?: number;
    "customer"?: Customer;
    "company"?: Company;
    "leadSource"?: EntityLink;
    "leadDetails"?: string;
    "status"?: OpportunityStatusEnum;
    "confidence"?: number;
    "targetRevenue"?: number;
    "description"?: string;
    "closeDate"?: Date;
    "createdBy"?: EntityLink;
    "createdOn"?: Date;
}

export type OpportunityStatusEnum = "Discovery" | "Interested" | "Converted" | "Abandoned";
export interface OpportunitySearchCriteria {
    "customerId"?: number;
    "customerRegionId"?: number;
    "status"?: Array<OpportunitySearchCriteriaStatusEnum>;
    "dateType"?: OpportunitySearchCriteriaDateTypeEnum;
    "startDate"?: Date;
    "endDate"?: Date;
    "createdById"?: number;
}

export type OpportunitySearchCriteriaStatusEnum = "Discovery" | "Interested" | "Converted" | "Abandoned";
export type OpportunitySearchCriteriaDateTypeEnum = "CloseDate" | "CreationDate";
export interface OpsCodeView {
    "tmCompanyId"?: number;
    "opsCode"?: string;
}

export interface OtherFreightInfoCriteria {
    "quoteStopId"?: number;
    "poNumber"?: string;
    "opsCode"?: string;
    "siteId"?: string;
    "externalNotes"?: string;
}

export interface OtherFreightInfosParams {
    "otherFreightInfoCriterias"?: Array<OtherFreightInfoCriteria>;
}

export interface OverdimensionalRuleset {
    "id"?: number;
    "name"?: string;
}

export interface PerStopFee {
    "id"?: number;
    "fee"?: number;
    "companyId"?: number;
    "startDateTime"?: Date;
    "endDateTime"?: Date;
    "createdById"?: number;
    "company"?: Company;
    "createdBy"?: Employee;
}

export interface Place {
    "address"?: string;
    "city"?: string;
    "stateProvince"?: string;
    "zipPostalCode"?: string;
    "county"?: string;
}

export interface Prospect {
    "id"?: number;
    "currentProvider"?: string;
    "decisionMakingType"?: ProspectDecisionMakingTypeEnum;
    "industryType"?: ProspectIndustryTypeEnum;
    "percentageToClose"?: ProspectPercentageToCloseEnum;
    "pricingType"?: ProspectPricingTypeEnum;
    "startDate"?: Date;
    "customer"?: Customer;
}

export type ProspectDecisionMakingTypeEnum = "Customer" | "DealerNetwork" | "Local" | "Other";
export type ProspectIndustryTypeEnum = "Aerospace" | "Equipment" | "GeneratorsTransformers" | "MachineTools" | "Steel" | "Other" | "Broker";
export type ProspectPercentageToCloseEnum = "NoContactWithDM" | "ContactedDMInterestExpressed" | "Quoted" | "VerballyAcceptedQuote" | "OrderPlaced";
export type ProspectPricingTypeEnum = "Contracted" | "SpotQuote";
export interface Question {
    "id"?: number;
    "questionText"?: string;
    "questionType"?: QuestionQuestionTypeEnum;
    "isNa"?: boolean;
    "isActive"?: boolean;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "commodityQuestions"?: Array<CommodityQuestion>;
}

export type QuestionQuestionTypeEnum = "Commodity" | "Upcharge";
export interface QuestionUpdateVM {
    "updatedQuestion"?: Question;
    "previousQuestion"?: Question;
}

export interface QuickQuoteChangeStatusVM {
    "quote"?: Quote;
    "status"?: QuickQuoteChangeStatusVMStatusEnum;
    "overriddenRatingLength"?: number;
    "overrideLengthTimeout"?: number;
}

export type QuickQuoteChangeStatusVMStatusEnum = "Accepted" | "Pending" | "ApprovalNeeded" | "Declined" | "InProgress" | "Expired" | "AcceptanceRejected" | "PendingNeedsCustomers" | "Approved" | "AdminRerate" | "PendingResave" | "Convert" | "Converted" | "Canceled" | "Requested" | "Completed";
export interface QuickRateModelTestRequest {
    "originZipPostalCode"?: string;
    "destZipPostalCode"?: string;
    "modelLevel"?: number;
    "commodityId"?: number;
    "companyId"?: number;
    "futureDate"?: Date;
    "miles"?: number;
    "datRate"?: number;
}

export interface QuickRateModelTestResult {
    "miles"?: number;
    "minimumAmount"?: number;
    "datRateValue"?: number;
    "datRate"?: DatRate;
    "defaultHighLineHaulRate"?: number;
    "defaultLowLineHaulRate"?: number;
    "activeHighLineHaulRate"?: number;
    "activeLowLineHaulRate"?: number;
    "futureHighLineHaulRate"?: number;
    "futureLowLineHaulRate"?: number;
    "sandboxHighLineHaulRate"?: number;
    "sandboxLowLineHaulRate"?: number;
    "defaultCalculationInfo"?: RateModelTestCalculationInfo;
    "activeCalculationInfo"?: RateModelTestCalculationInfo;
    "futureCalculationInfo"?: RateModelTestCalculationInfo;
    "sandboxCalculationInfo"?: RateModelTestCalculationInfo;
}

export interface Quote {
    "id"?: number;
    "quoteNumber"?: number;
    "companyId"?: number;
    "customerId"?: number;
    "billToId"?: number;
    "createdById"?: number;
    "quoteDate"?: Date;
    "deliveryDate"?: Date;
    "negotiatedRate"?: number;
    "isMarketPrimary"?: boolean;
    "notes"?: string;
    "status"?: QuoteStatusEnum;
    "reviewedById"?: number;
    "finalizedById"?: number;
    "expirationDate"?: Date;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "upchargePercentage"?: number;
    "flatUpcharge"?: number;
    "otherFlatUpchargeReason"?: boolean;
    "flatUpchargeReason"?: string;
    "miles"?: number;
    "percentUpchargeReason"?: string;
    "totalFreightLength"?: number;
    "equipmentTypeId"?: number;
    "workflowState"?: string;
    "declineReasonText"?: string;
    "carrierId"?: number;
    "customerContactId"?: number;
    "quoteType"?: QuoteQuoteTypeEnum;
    "contactName"?: string;
    "contactPhoneNumber"?: string;
    "cancellationReason"?: QuoteCancellationReasonEnum;
    "cancellationDetails"?: string;
    "customerQuote"?: EntityLink;
    "billingStatus"?: QuoteBillingStatusEnum;
    "paymentCollected"?: boolean;
    "billTo"?: Customer;
    "company"?: Company;
    "customer"?: Customer;
    "createdBy"?: Employee;
    "reviewedBy"?: Employee;
    "finalizedBy"?: Employee;
    "equipmentType"?: EquipmentType;
    "carrier"?: QuoteCarrier;
    "customerContact"?: CustomerContact;
    "quoteStops"?: Array<QuoteStop>;
    "quoteFreights"?: Array<QuoteFreight>;
    "quoteQuestions"?: Array<QuoteQuestion>;
    "quoteStatusHistories"?: Array<QuoteStatusHistory>;
    "calculatedRates"?: Array<QuoteCalculatedRate>;
    "approvalReasons"?: Array<QuoteApprovalReason>;
    "accessorialChargeValues"?: Array<AccessorialChargeValue>;
}

export type QuoteStatusEnum = "Accepted" | "Pending" | "ApprovalNeeded" | "Declined" | "InProgress" | "Expired" | "AcceptanceRejected" | "PendingNeedsCustomers" | "Approved" | "AdminRerate" | "PendingResave" | "Convert" | "Converted" | "Canceled" | "Requested" | "Completed";
export type QuoteQuoteTypeEnum = "Full" | "Quick" | "Contract";
export type QuoteCancellationReasonEnum = "Customer" | "Kaiser" | "Other";
export type QuoteBillingStatusEnum = "CheckCredit" | "CreditConfirmed" | "CODOnly";
export interface QuoteApprovalReason {
    "id"?: number;
    "quoteId"?: number;
    "approvalNeededReasonId"?: QuoteApprovalReasonApprovalNeededReasonIdEnum;
    "approvalStatus"?: QuoteApprovalReasonApprovalStatusEnum;
    "reviewedOn"?: Date;
    "createdById"?: number;
    "createdOn"?: Date;
    "reviewedById"?: number;
    "reviewedBy"?: Employee;
}

export type QuoteApprovalReasonApprovalNeededReasonIdEnum = "OverDimensional" | "LowNegotiatedRate" | "DeclaredValue" | "MileageDifferenceOverThreshold" | "NegotiatedRateOutOfRange" | "ShipperZoneChanged" | "ConsigneeZoneChanged";
export type QuoteApprovalReasonApprovalStatusEnum = "PendingApproval" | "Approved" | "Denied";
export interface QuoteBillToVM {
    "quoteId"?: number;
    "customerId"?: number;
}

export interface QuoteCalculateRatingResult {
    "quoteCalculatedRate"?: QuoteCalculatedRate;
    "quoteApprovalReasons"?: Array<QuoteApprovalReason>;
    "error"?: string;
}

export interface QuoteCalculateRatingVariableVM {
    "quoteFreight"?: Array<QuoteFreight>;
    "overriddenRatingVariable"?: number;
    "overriddenRatingLength"?: number;
    "overdimensionalRulesetId"?: number;
    "overrideLengthTimeout"?: number;
}

export interface QuoteCalculatedRate {
    "id"?: number;
    "quoteId"?: number;
    "isCurrent"?: boolean;
    "miles"?: number;
    "totalFreightLength"?: number;
    "totalFreightWeight"?: number;
    "rateLevelFactor"?: QuoteCalculatedRateRateLevelFactorEnum;
    "calculatedRateVariable"?: number;
    "overriddenRateVariable"?: number;
    "rateEngineResults"?: string;
    "highRate"?: number;
    "lowRate"?: number;
    "datRate"?: number;
    "datRateMessage"?: string;
    "timeFrameDays"?: number;
    "originMarket"?: string;
    "destMarket"?: string;
    "createdOn"?: Date;
}

export type QuoteCalculatedRateRateLevelFactorEnum = "Error" | "Length" | "Weight" | "Overdimensional";
export interface QuoteCarrier {
    "id"?: number;
    "carrierStatus"?: QuoteCarrierCarrierStatusEnum;
    "vendorId"?: number;
    "carrierRate"?: number;
    "tripNumber"?: number;
    "carrierRepId"?: number;
    "carrierRep"?: Employee;
    "vendor"?: Vendor;
    "quote"?: Quote;
}

export type QuoteCarrierCarrierStatusEnum = "Available" | "Assigned" | "OnHold" | "Picked" | "Delivered";
export interface QuoteCarrierFlattened {
    "id"?: number;
    "carrierStatus"?: QuoteCarrierFlattenedCarrierStatusEnum;
    "vendorId"?: number;
    "carrierRate"?: number;
    "tripNumber"?: number;
    "carrierRepId"?: number;
    "carrierRepName"?: string;
    "vendor"?: Vendor;
    "quoteId"?: number;
    "freightBillNumber"?: string;
    "salesRepName"?: string;
    "customerNegotiatedRate"?: number;
    "originCityRegion"?: string;
    "destinationCityRegion"?: string;
    "shippingDate"?: Date;
    "deliveryDate"?: Date;
    "isMarketPrimary"?: boolean;
    "callerCustomerName"?: string;
    "shipperCustomerName"?: string;
    "consigneeCustomerName"?: string;
}

export type QuoteCarrierFlattenedCarrierStatusEnum = "Available" | "Assigned" | "OnHold" | "Picked" | "Delivered";
export interface QuoteCarrierSaveParams {
    "quoteCarrier"?: QuoteCarrier;
    "quoteId"?: number;
    "isMarketPrimary"?: boolean;
}

export interface QuoteChangeStatusVM {
    "quote"?: Quote;
    "customerConvert"?: CustomerConvertParams;
    "overriddenRateVariable"?: number;
    "overriddenRatingLength"?: number;
    "overrideLengthTimeout"?: number;
    "isEditMode"?: boolean;
}

export interface QuoteContactVM {
    "quoteId"?: number;
    "contactId"?: number;
}

export interface QuoteEditModeVM {
    "quote"?: Quote;
    "timeout"?: number;
}

export interface QuoteEmailRequestVM {
    "quoteId"?: number;
    "pdfString"?: string;
}

export interface QuoteEmailResult {
    "successful"?: boolean;
    "errorMessage"?: string;
}

export interface QuoteFreight {
    "id"?: number;
    "quoteStopId"?: number;
    "commodityId"?: number;
    "weight"?: number;
    "width"?: number;
    "length"?: number;
    "height"?: number;
    "description"?: string;
    "isStackable"?: boolean;
    "isSideBySide"?: boolean;
    "isGrouped"?: boolean;
    "rotated"?: boolean;
    "numberOfPieces"?: number;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "quoteId"?: number;
    "serialRefNumber"?: string;
    "commodity"?: Commodity;
    "quoteStop"?: QuoteStop;
    "quote"?: Quote;
    "quoteStopFreightQuestions"?: Array<QuoteStopFreightQuestion>;
}

export interface QuoteQuestion {
    "id"?: number;
    "quoteId"?: number;
    "questionId"?: number;
    "answer"?: QuoteQuestionAnswerEnum;
    "question"?: Question;
    "quote"?: Quote;
}

export type QuoteQuestionAnswerEnum = "Yes" | "No" | "NA";
export interface QuoteResultVM {
    "quote"?: Quote;
    "state"?: WorkflowState;
}

export interface QuoteSearchCriteria {
    "userId"?: string;
    "dateType"?: QuoteSearchCriteriaDateTypeEnum;
    "quoteDate"?: Date;
    "startDate"?: Date;
    "endDate"?: Date;
    "statuses"?: Array<QuoteSearchCriteriaStatusesEnum>;
    "companyId"?: number;
    "quoteOrFreightNumber"?: string;
    "customerId"?: number;
    "customerName"?: string;
    "quoteType"?: QuoteSearchCriteriaQuoteTypeEnum;
    "billingStatus"?: QuoteSearchCriteriaBillingStatusEnum;
    "sortColumn"?: string;
    "sortAscending"?: boolean;
    "startIndex"?: number;
    "pageSize"?: number;
}

export type QuoteSearchCriteriaDateTypeEnum = "QuoteDate" | "DeliveryDate" | "ExpirationDate" | "PickupDate";
export type QuoteSearchCriteriaStatusesEnum = "Accepted" | "Pending" | "ApprovalNeeded" | "Declined" | "InProgress" | "Expired" | "AcceptanceRejected" | "PendingNeedsCustomers" | "Approved" | "AdminRerate" | "PendingResave" | "Convert" | "Converted" | "Canceled" | "Requested" | "Completed";
export type QuoteSearchCriteriaQuoteTypeEnum = "Full" | "Quick" | "Contract";
export type QuoteSearchCriteriaBillingStatusEnum = "CheckCredit" | "CreditConfirmed" | "CODOnly";
export interface QuoteStatusHistory {
    "id"?: number;
    "quoteId"?: number;
    "employeeId"?: number;
    "quoteStatus"?: QuoteStatusHistoryQuoteStatusEnum;
    "createdOn"?: Date;
    "employee"?: Employee;
    "quote"?: Quote;
}

export type QuoteStatusHistoryQuoteStatusEnum = "Accepted" | "Pending" | "ApprovalNeeded" | "Declined" | "InProgress" | "Expired" | "AcceptanceRejected" | "PendingNeedsCustomers" | "Approved" | "AdminRerate" | "PendingResave" | "Convert" | "Converted" | "Canceled" | "Requested" | "Completed";
export interface QuoteStop {
    "id"?: number;
    "quoteId"?: number;
    "shipperStartDate"?: Date;
    "shipperEndDate"?: Date;
    "isShipperAppointmentRequired"?: boolean;
    "consigneeStartDate"?: Date;
    "consigneeEndDate"?: Date;
    "isConsigneeAppointmentRequired"?: boolean;
    "description"?: string;
    "tarpId"?: number;
    "declaredValue"?: number;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "shipperHardTime"?: string;
    "consigneeHardTime"?: string;
    "shipmentNotes"?: string;
    "freightBillNumber"?: string;
    "freightBillCreatedOn"?: Date;
    "stopNumber"?: number;
    "isShipperExpedited"?: boolean;
    "isShipperLayover"?: boolean;
    "isConsigneeExpedited"?: boolean;
    "isConsigneeLayover"?: boolean;
    "isMilitaryBase"?: boolean;
    "isTwicCardRequired"?: boolean;
    "shipperContactId"?: number;
    "consigneeContactId"?: number;
    "ponumber"?: string;
    "siteId"?: string;
    "opsCode"?: string;
    "externalNotes"?: string;
    "quote"?: Quote;
    "tarp"?: Tarp;
    "shipperContact"?: CustomerContact;
    "consigneeContact"?: CustomerContact;
    "addresses"?: Array<Address>;
    "quoteFreights"?: Array<QuoteFreight>;
}

export interface QuoteStopFreightQuestion {
    "id"?: number;
    "quoteStopFreightId"?: number;
    "questionId"?: number;
    "answer"?: QuoteStopFreightQuestionAnswerEnum;
    "question"?: Question;
    "quoteFreight"?: QuoteFreight;
}

export type QuoteStopFreightQuestionAnswerEnum = "Yes" | "No" | "NA";
export interface QuoteStopRouteResults {
    "stops"?: Array<QuoteStop>;
    "mileage"?: number;
    "numberOfDay"?: number;
    "successful"?: boolean;
    "errorMessage"?: string;
}

export interface RateEngineAuditSearchCriteria {
    "startDate"?: Date;
    "endDate"?: Date;
    "employeeIds"?: Array<number>;
    "eventTypes"?: Array<string>;
    "elementOrChange"?: string;
    "sortColumn"?: string;
    "sortAscending"?: boolean;
    "startIndex"?: number;
    "pageSize"?: number;
}

export interface RateModel {
    "id"?: number;
    "companyId"?: number;
    "modelLevelId"?: number;
    "defaultRate"?: number;
    "sandboxRate"?: number;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "activeRate"?: number;
    "feet"?: number;
    "resetToDefaultOn"?: Date;
}

export interface RateModelTest {
    "id"?: number;
    "modelLevelId"?: number;
    "originZipPostalCode"?: string;
    "destZipPostalCode"?: string;
    "datRate"?: number;
    "datRateObject"?: DatRate;
    "commodityId"?: number;
    "companyId"?: number;
    "miles"?: number;
    "defaultHighLineHaulRate"?: number;
    "defaultLowLineHaulRate"?: number;
    "activeHighLineHaulRate"?: number;
    "activeLowLineHaulRate"?: number;
    "futureHighLineHaulRate"?: number;
    "futureLowLineHaulRate"?: number;
    "sandboxHighLineHaulRate"?: number;
    "sandboxLowLineHaulRate"?: number;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "minimumAmount"?: number;
    "commodity"?: Commodity;
    "defaultCalculationInfo"?: RateModelTestCalculationInfo;
    "activeCalculationInfo"?: RateModelTestCalculationInfo;
    "futureCalculationInfo"?: RateModelTestCalculationInfo;
    "sandboxCalculationInfo"?: RateModelTestCalculationInfo;
}

export interface RateModelTestCalculationInfo {
    "tssPercent"?: number;
    "ratePerMile"?: number;
    "pricePerFoot"?: number;
    "commodityRate"?: number;
    "capacityRates"?: Array<number>;
}

export interface RateModelTestRequestVM {
    "futureDateTime"?: Date;
    "companyId"?: number;
}

export interface Region {
    "id"?: number;
    "regionName"?: string;
    "regionAbbreviation"?: string;
    "countryId"?: number;
    "regionZipPostCodes"?: Array<RegionZipPostCode>;
}

export interface RegionZipPostCode {
    "id"?: number;
    "regionId"?: number;
    "zipPostalCode"?: string;
    "zipCodeStart"?: number;
    "zipCodeEnd"?: number;
    "postalCodePrefix"?: string;
}

export interface RelatedEntity {
    "entityType"?: RelatedEntityEntityTypeEnum;
    "identifier"?: number;
}

export type RelatedEntityEntityTypeEnum = "Customer" | "Question" | "Company" | "Tarp" | "TarpValue" | "Note" | "Quote" | "QuoteStop";
export interface Reminder {
    "id"?: number;
    "customer"?: CustomerEntityLink;
    "quote"?: EntityLink;
    "type"?: ReminderTypeEnum;
    "text"?: string;
    "dueDate"?: Date;
    "isComplete"?: boolean;
    "isActive"?: boolean;
    "createdById"?: number;
    "createdOn"?: Date;
    "createdByName"?: string;
}

export type ReminderTypeEnum = "General" | "Activity" | "Contact" | "Quote" | "Sales";
export interface ReminderSearchCriteria {
    "customerId"?: number;
    "quoteId"?: number;
    "search"?: string;
    "type"?: ReminderSearchCriteriaTypeEnum;
    "startDate"?: Date;
    "endDate"?: Date;
    "createdById"?: number;
    "reminderListType"?: string;
}

export type ReminderSearchCriteriaTypeEnum = "General" | "Activity" | "Contact" | "Quote" | "Sales";
export interface SalesRepCustomerSearchCriteria {
    "employeeId"?: number;
    "latestQuoteStartDate"?: Date;
    "latestQuoteEndDate"?: Date;
    "customerName"?: string;
    "regionAbbreviation"?: string;
    "zipPostalCode"?: string;
    "isProspect"?: boolean;
    "industryType"?: SalesRepCustomerSearchCriteriaIndustryTypeEnum;
    "currentProvider"?: string;
    "percentageToClose"?: SalesRepCustomerSearchCriteriaPercentageToCloseEnum;
    "startDateFrom"?: Date;
    "startDateTo"?: Date;
}

export type SalesRepCustomerSearchCriteriaIndustryTypeEnum = "Aerospace" | "Equipment" | "GeneratorsTransformers" | "MachineTools" | "Steel" | "Other" | "Broker";
export type SalesRepCustomerSearchCriteriaPercentageToCloseEnum = "NoContactWithDM" | "ContactedDMInterestExpressed" | "Quoted" | "VerballyAcceptedQuote" | "OrderPlaced";
export interface SimplifiedCommodityRate {
    "zoneId"?: number;
    "zoneName"?: string;
    "companyId"?: number;
    "startDateTime"?: Date;
    "endDateTime"?: Date;
    "commodityValues"?: { [key: string]: SimplifiedCommodityValue; };
}

export interface SimplifiedCommodityValue {
    "id"?: number;
    "commodityId"?: number;
    "zoneId"?: number;
    "companyId"?: number;
    "zoneName"?: string;
    "startDateTime"?: Date;
    "endDateTime"?: Date;
    "percentRate"?: number;
}

export interface SimplifiedCustomer {
    "id"?: number;
    "customerName"?: string;
}

export interface SimplifiedCustomerQuote {
    "id"?: number;
    "quoteNumber"?: string;
    "salesRep"?: string;
    "customerName"?: string;
    "quoteDate"?: Date;
    "poNumber"?: string;
    "rate"?: number;
    "miles"?: number;
    "rateVariable"?: number;
    "rateVariableFactor"?: SimplifiedCustomerQuoteRateVariableFactorEnum;
    "deliveryDate"?: Date;
    "quoteStatus"?: SimplifiedCustomerQuoteQuoteStatusEnum;
    "customerId"?: number;
    "shipperZipCode"?: string;
    "consigneeZipCode"?: string;
    "salesAgentId"?: number;
    "isReviewed"?: boolean;
    "quoteKind"?: SimplifiedCustomerQuoteQuoteKindEnum;
}

export type SimplifiedCustomerQuoteRateVariableFactorEnum = "Error" | "Length" | "Weight" | "Overdimensional";
export type SimplifiedCustomerQuoteQuoteStatusEnum = "Accepted" | "Pending" | "ApprovalNeeded" | "Declined" | "InProgress" | "Expired" | "AcceptanceRejected" | "PendingNeedsCustomers" | "Approved" | "AdminRerate" | "PendingResave" | "Convert" | "Converted" | "Canceled" | "Requested" | "Completed";
export type SimplifiedCustomerQuoteQuoteKindEnum = "Quote" | "CustomerQuote";
export interface SimplifiedCustomerQuoteSearchResult {
    "results"?: Array<SimplifiedCustomerQuote>;
    "totalRecords"?: number;
    "numberOfRecords"?: number;
}

export interface SimplifiedCustomersQuote {
    "id"?: number;
    "quoteNumber"?: number;
    "company"?: Company;
    "quoteDate"?: Date;
    "negotiatedRate"?: number;
    "deliveryDate"?: Date;
    "quoteStatus"?: SimplifiedCustomersQuoteQuoteStatusEnum;
    "quoteKind"?: SimplifiedCustomersQuoteQuoteKindEnum;
    "expirationDate"?: Date;
    "isReviewed"?: boolean;
    "originLocation"?: string;
    "destinationLocation"?: string;
    "linkedQuoteNumber"?: number;
}

export type SimplifiedCustomersQuoteQuoteStatusEnum = "Accepted" | "Pending" | "ApprovalNeeded" | "Declined" | "InProgress" | "Expired" | "AcceptanceRejected" | "PendingNeedsCustomers" | "Approved" | "AdminRerate" | "PendingResave" | "Convert" | "Converted" | "Canceled" | "Requested" | "Completed";
export type SimplifiedCustomersQuoteQuoteKindEnum = "Quote" | "CustomerQuote";
export interface SimplifiedFullQuote {
    "id"?: number;
    "negotiatedRate"?: number;
    "freightBillNumber"?: string;
}

export interface SimplifiedQuote {
    "createdBy"?: Employee;
    "createdById"?: number;
    "createdOn"?: Date;
    "customer"?: CustomerEntityLink;
    "contactName"?: string;
    "contactPhoneNumber"?: string;
    "finalizedBy"?: Employee;
    "finalizedById"?: number;
    "id"?: number;
    "miles"?: number;
    "negotiatedRate"?: number;
    "quoteDate"?: Date;
    "deliveryDate"?: Date;
    "quoteNumber"?: number;
    "quoteType"?: SimplifiedQuoteQuoteTypeEnum;
    "quoteStops"?: Array<SimplifiedQuoteStop>;
    "reviewedBy"?: Employee;
    "reviewedById"?: number;
    "status"?: SimplifiedQuoteStatusEnum;
    "expirationDate"?: Date;
    "currentRate"?: QuoteCalculatedRate;
    "originLocation"?: string;
    "destinationLocation"?: string;
}

export type SimplifiedQuoteQuoteTypeEnum = "Full" | "Quick" | "Contract";
export type SimplifiedQuoteStatusEnum = "Accepted" | "Pending" | "ApprovalNeeded" | "Declined" | "InProgress" | "Expired" | "AcceptanceRejected" | "PendingNeedsCustomers" | "Approved" | "AdminRerate" | "PendingResave" | "Convert" | "Converted" | "Canceled" | "Requested" | "Completed";
export interface SimplifiedQuoteSearchResult {
    "results"?: Array<SimplifiedQuote>;
    "totalRecords"?: number;
    "numberOfRecords"?: number;
}

export interface SimplifiedQuoteStop {
    "freightBillCreatedOn"?: Date;
    "freightBillNumber"?: string;
    "id"?: number;
    "quoteId"?: number;
    "isConsigneeAppointmentRequired"?: boolean;
    "addresses"?: Array<Address>;
}

export interface SiteView {
    "tmCompanyId"?: number;
    "siteId"?: string;
    "siteName"?: string;
}

export interface Tarp {
    "id"?: number;
    "tarpName"?: string;
    "tarpDescription"?: string;
    "isActive"?: boolean;
    "companyId"?: number;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "company"?: Company;
    "tarpValues"?: Array<TarpValue>;
}

export interface TarpValue {
    "id"?: number;
    "tarpId"?: number;
    "companyId"?: number;
    "rate"?: number;
    "startDateTime"?: Date;
    "endDateTime"?: Date;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "company"?: Company;
    "tarp"?: Tarp;
}

export interface Terminal {
    "id"?: number;
    "terminalName"?: string;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "terminalRegions"?: Array<TerminalRegion>;
}

export interface TerminalRegion {
    "id"?: number;
    "terminalId"?: number;
    "regionId"?: number;
    "region"?: Region;
    "terminal"?: Terminal;
}

export interface TerminalResponseBase {
    "data"?: Terminal;
    "success"?: boolean;
    "error"?: BooleanResponseBaseError;
    "totalCount"?: number;
}

export interface TripLeg {
    "startZipPostalCode"?: string;
    "startStopIndex"?: number;
    "endZipPostalCode"?: string;
    "endStopIndex"?: number;
    "purpose"?: TripLegPurposeEnum;
    "mileage"?: number;
    "hours"?: number;
}

export type TripLegPurposeEnum = "Unknown" | "ToPickUp" | "ToDropOff";
export interface TripResult {
    "successful"?: boolean;
    "mileage"?: number;
    "tripLegs"?: Array<TripLeg>;
    "errorMessage"?: string;
}

export interface UserMetricsBookedQuotesByWeekEntry {
    "weekNumber"?: number;
    "start"?: Date;
    "end"?: Date;
    "charges"?: number;
    "rkm"?: number;
    "rfm"?: number;
}

export interface UserMetricsBookedQuotesView {
    "weekNumber"?: number;
    "current"?: UserMetricsBookedQuotesByWeekEntry;
    "previous"?: UserMetricsBookedQuotesByWeekEntry;
}

export interface UserMetricsKPIView {
    "bills"?: number;
    "totalBills"?: number;
    "totalDistance"?: number;
    "totalWeight"?: number;
    "charges"?: number;
    "totalRollupLength"?: number;
    "conversionRate"?: number;
    "avgAmountPerQuote"?: number;
    "rk"?: number;
    "rkm"?: number;
    "rf"?: number;
    "rfm"?: number;
}

export interface UserMetricsSalesLeaderboardView {
    "companyId"?: number;
    "terminalId"?: number;
    "salesAgent"?: string;
    "username"?: string;
    "charges"?: number;
}

export interface Vendor {
    "id"?: number;
    "address1"?: string;
    "address2"?: string;
    "city"?: string;
    "contactName"?: string;
    "createdOn"?: Date;
    "isActive"?: boolean;
    "modifiedOn"?: Date;
    "phoneNumber"?: string;
    "phoneExtension"?: string;
    "emailAddress"?: string;
    "regionId"?: number;
    "tmvendorId"?: string;
    "vendorName"?: string;
    "vendorSince"?: Date;
    "zipPostalCode"?: string;
    "region"?: Region;
}

export interface WorkflowState {
    "workflowInstanceId"?: string;
    "activityId"?: string;
    "error"?: BooleanResponseBaseError;
    "roleActions"?: { [key: string]: Array<string>; };
}

export type WorkflowStateRoleActionsEnum = "Accepted" | "Pending" | "ApprovalNeeded" | "Declined" | "InProgress" | "Expired" | "AcceptanceRejected" | "PendingNeedsCustomers" | "Approved" | "AdminRerate" | "PendingResave" | "Convert" | "Converted" | "Canceled" | "Requested" | "Completed";
export interface ZipCodePair {
    "originZipPostalCode"?: string;
    "destZipPostalCode"?: string;
}

export interface ZipCodePairMileage {
    "zipCodePair"?: ZipCodePair;
    "miles"?: number;
    "successful"?: boolean;
    "error"?: Array<string>;
}

export interface ZipCodeValidationResult {
    "zipCode"?: string;
    "isValid"?: boolean;
    "error"?: string;
}

export interface Zone {
    "id"?: number;
    "zoneName"?: string;
    "primaryZipPostalCode"?: string;
    "secondaryZipPostalCode"?: string;
    "zoneAbbreviation"?: string;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "zoneRegions"?: Array<ZoneRegion>;
}

export interface ZoneConfiguration {
    "id"?: number;
    "originZoneId"?: number;
    "destZoneId"?: number;
    "companyId"?: number;
    "minimumQuoteAmount"?: number;
    "lowRatePercentage"?: number;
    "destZone"?: Zone;
    "originZone"?: Zone;
    "currentDatRate"?: number;
    "datPollDateTime"?: Date;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
}

export interface ZoneRegion {
    "id"?: number;
    "zoneId"?: number;
    "regionId"?: number;
    "zipPostalCode"?: string;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "region"?: Region;
}



/**
 * AboutApi - fetch parameter creator
 */
export const AboutApiFetchParamCreator = {
    /** 
     * Gets the about information for the application.
     */
    apiV1AboutGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/about`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * AboutApi - functional programming interface
 */
export const AboutApiFp = {
    /** 
     * Gets the about information for the application.
     */
    apiV1AboutGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AboutVM> {
        const fetchArgs = AboutApiFetchParamCreator.apiV1AboutGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * AboutApi - object-oriented interface
 */
export class AboutApi extends BaseAPI {
    /** 
     * Gets the about information for the application.
     */
    apiV1AboutGet(options?: any) {
        return AboutApiFp.apiV1AboutGet(options)(this.fetch, this.basePath);
    }
};

/**
 * AboutApi - factory interface
 */
export const AboutApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Gets the about information for the application.
         */
        apiV1AboutGet(options?: any) {
            return AboutApiFp.apiV1AboutGet(options)(fetch, basePath);
        },
    };
};


/**
 * AccessorialChargeApi - fetch parameter creator
 */
export const AccessorialChargeApiFetchParamCreator = {
    /** 
     * 
     * @param body 
     */
    addAccessorialCharge(params: {  "body"?: AccessorialCharge; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/accessorialCharge/addAccessorialCharge`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     * @param activeOnly 
     */
    getAccessorialCharges(params: {  "activeOnly": boolean; }, options?: any): FetchArgs {
        // verify required parameter "activeOnly" is set
        if (params["activeOnly"] == null) {
            throw new Error("Missing required parameter activeOnly when calling getAccessorialCharges");
        }
        const baseUrl = `/api/v1/accessorialCharge/accessorialCharges/{activeOnly}`
            .replace(`{${"activeOnly"}}`, `${ params["activeOnly"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     * @param body 
     */
    updateAccessorialCharge(params: {  "body"?: AccessorialCharge; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/accessorialCharge/updateAccessorialCharge`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * AccessorialChargeApi - functional programming interface
 */
export const AccessorialChargeApiFp = {
    /** 
     * 
     * @param body 
     */
    addAccessorialCharge(params: { "body"?: AccessorialCharge;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccessorialCharge> {
        const fetchArgs = AccessorialChargeApiFetchParamCreator.addAccessorialCharge(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     * @param activeOnly 
     */
    getAccessorialCharges(params: { "activeOnly": boolean;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<AccessorialCharge>> {
        const fetchArgs = AccessorialChargeApiFetchParamCreator.getAccessorialCharges(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     * @param body 
     */
    updateAccessorialCharge(params: { "body"?: AccessorialCharge;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccessorialCharge> {
        const fetchArgs = AccessorialChargeApiFetchParamCreator.updateAccessorialCharge(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * AccessorialChargeApi - object-oriented interface
 */
export class AccessorialChargeApi extends BaseAPI {
    /** 
     * 
     * @param body 
     */
    addAccessorialCharge(params: {  "body"?: AccessorialCharge; }, options?: any) {
        return AccessorialChargeApiFp.addAccessorialCharge(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     * @param activeOnly 
     */
    getAccessorialCharges(params: {  "activeOnly": boolean; }, options?: any) {
        return AccessorialChargeApiFp.getAccessorialCharges(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     * @param body 
     */
    updateAccessorialCharge(params: {  "body"?: AccessorialCharge; }, options?: any) {
        return AccessorialChargeApiFp.updateAccessorialCharge(params, options)(this.fetch, this.basePath);
    }
};

/**
 * AccessorialChargeApi - factory interface
 */
export const AccessorialChargeApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * 
         * @param body 
         */
        addAccessorialCharge(params: {  "body"?: AccessorialCharge; }, options?: any) {
            return AccessorialChargeApiFp.addAccessorialCharge(params, options)(fetch, basePath);
        },
        /** 
         * 
         * @param activeOnly 
         */
        getAccessorialCharges(params: {  "activeOnly": boolean; }, options?: any) {
            return AccessorialChargeApiFp.getAccessorialCharges(params, options)(fetch, basePath);
        },
        /** 
         * 
         * @param body 
         */
        updateAccessorialCharge(params: {  "body"?: AccessorialCharge; }, options?: any) {
            return AccessorialChargeApiFp.updateAccessorialCharge(params, options)(fetch, basePath);
        },
    };
};


/**
 * ApplicationSettingsApi - fetch parameter creator
 */
export const ApplicationSettingsApiFetchParamCreator = {
    /** 
     * Get all ApplicationSetting entities
     */
    apiV1ApplicationSettingsGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/applicationSettings`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Update an ApplicationSetting
     * @param body 
     */
    apiV1ApplicationSettingsUpsertAppSettingPost(params: {  "body"?: ApplicationSetting; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/applicationSettings/upsertAppSetting`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Update an automatic upcharge ApplicationSetting
     * @param body 
     */
    apiV1ApplicationSettingsUpsertAutoUpchargePost(params: {  "body"?: ApplicationSetting; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/applicationSettings/upsertAutoUpcharge`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ApplicationSettingsApi - functional programming interface
 */
export const ApplicationSettingsApiFp = {
    /** 
     * Get all ApplicationSetting entities
     */
    apiV1ApplicationSettingsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ApplicationSetting>> {
        const fetchArgs = ApplicationSettingsApiFetchParamCreator.apiV1ApplicationSettingsGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Update an ApplicationSetting
     * @param body 
     */
    apiV1ApplicationSettingsUpsertAppSettingPost(params: { "body"?: ApplicationSetting;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApplicationSetting> {
        const fetchArgs = ApplicationSettingsApiFetchParamCreator.apiV1ApplicationSettingsUpsertAppSettingPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Update an automatic upcharge ApplicationSetting
     * @param body 
     */
    apiV1ApplicationSettingsUpsertAutoUpchargePost(params: { "body"?: ApplicationSetting;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApplicationSetting> {
        const fetchArgs = ApplicationSettingsApiFetchParamCreator.apiV1ApplicationSettingsUpsertAutoUpchargePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ApplicationSettingsApi - object-oriented interface
 */
export class ApplicationSettingsApi extends BaseAPI {
    /** 
     * Get all ApplicationSetting entities
     */
    apiV1ApplicationSettingsGet(options?: any) {
        return ApplicationSettingsApiFp.apiV1ApplicationSettingsGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Update an ApplicationSetting
     * @param body 
     */
    apiV1ApplicationSettingsUpsertAppSettingPost(params: {  "body"?: ApplicationSetting; }, options?: any) {
        return ApplicationSettingsApiFp.apiV1ApplicationSettingsUpsertAppSettingPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Update an automatic upcharge ApplicationSetting
     * @param body 
     */
    apiV1ApplicationSettingsUpsertAutoUpchargePost(params: {  "body"?: ApplicationSetting; }, options?: any) {
        return ApplicationSettingsApiFp.apiV1ApplicationSettingsUpsertAutoUpchargePost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * ApplicationSettingsApi - factory interface
 */
export const ApplicationSettingsApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Get all ApplicationSetting entities
         */
        apiV1ApplicationSettingsGet(options?: any) {
            return ApplicationSettingsApiFp.apiV1ApplicationSettingsGet(options)(fetch, basePath);
        },
        /** 
         * Update an ApplicationSetting
         * @param body 
         */
        apiV1ApplicationSettingsUpsertAppSettingPost(params: {  "body"?: ApplicationSetting; }, options?: any) {
            return ApplicationSettingsApiFp.apiV1ApplicationSettingsUpsertAppSettingPost(params, options)(fetch, basePath);
        },
        /** 
         * Update an automatic upcharge ApplicationSetting
         * @param body 
         */
        apiV1ApplicationSettingsUpsertAutoUpchargePost(params: {  "body"?: ApplicationSetting; }, options?: any) {
            return ApplicationSettingsApiFp.apiV1ApplicationSettingsUpsertAutoUpchargePost(params, options)(fetch, basePath);
        },
    };
};


/**
 * AuditApi - fetch parameter creator
 */
export const AuditApiFetchParamCreator = {
    /** 
     * Gets keycloak events filtered by search criteria
     * @param body 
     */
    apiV1AuditGetKeycloakEventsPost(params: {  "body"?: AuditSearchCriteria; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/audit/getKeycloakEvents`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * AuditApi - functional programming interface
 */
export const AuditApiFp = {
    /** 
     * Gets keycloak events filtered by search criteria
     * @param body 
     */
    apiV1AuditGetKeycloakEventsPost(params: { "body"?: AuditSearchCriteria;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AuditFetchResult> {
        const fetchArgs = AuditApiFetchParamCreator.apiV1AuditGetKeycloakEventsPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * AuditApi - object-oriented interface
 */
export class AuditApi extends BaseAPI {
    /** 
     * Gets keycloak events filtered by search criteria
     * @param body 
     */
    apiV1AuditGetKeycloakEventsPost(params: {  "body"?: AuditSearchCriteria; }, options?: any) {
        return AuditApiFp.apiV1AuditGetKeycloakEventsPost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * AuditApi - factory interface
 */
export const AuditApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Gets keycloak events filtered by search criteria
         * @param body 
         */
        apiV1AuditGetKeycloakEventsPost(params: {  "body"?: AuditSearchCriteria; }, options?: any) {
            return AuditApiFp.apiV1AuditGetKeycloakEventsPost(params, options)(fetch, basePath);
        },
    };
};


/**
 * AuditLogApi - fetch parameter creator
 */
export const AuditLogApiFetchParamCreator = {
    /** 
     * 
     * @param customerId 
     */
    getCustomerDetailAuditLogs(params: {  "customerId": number; }, options?: any): FetchArgs {
        // verify required parameter "customerId" is set
        if (params["customerId"] == null) {
            throw new Error("Missing required parameter customerId when calling getCustomerDetailAuditLogs");
        }
        const baseUrl = `/api/v1/auditLog/getCustomerDetailAuditLogs/{customerId}`
            .replace(`{${"customerId"}}`, `${ params["customerId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     * @param body 
     */
    getDataChangeLogs(params: {  "body"?: Array<RelatedEntity>; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/auditLog/getDataChangeLogs`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     */
    getEventTypes(options?: any): FetchArgs {
        const baseUrl = `/api/v1/auditLog/getEventTypes`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     * @param body 
     */
    getRateEngineAuditLogs(params: {  "body"?: RateEngineAuditSearchCriteria; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/auditLog/getRateEngineAuditLogs`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * AuditLogApi - functional programming interface
 */
export const AuditLogApiFp = {
    /** 
     * 
     * @param customerId 
     */
    getCustomerDetailAuditLogs(params: { "customerId": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<AuditCustomerAuditLog>> {
        const fetchArgs = AuditLogApiFetchParamCreator.getCustomerDetailAuditLogs(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     * @param body 
     */
    getDataChangeLogs(params: { "body"?: Array<RelatedEntity>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<AuditDataChangeAuditLog>> {
        const fetchArgs = AuditLogApiFetchParamCreator.getDataChangeLogs(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     */
    getEventTypes(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<string>> {
        const fetchArgs = AuditLogApiFetchParamCreator.getEventTypes(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     * @param body 
     */
    getRateEngineAuditLogs(params: { "body"?: RateEngineAuditSearchCriteria;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<AuditRateEngineAuditLog>> {
        const fetchArgs = AuditLogApiFetchParamCreator.getRateEngineAuditLogs(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * AuditLogApi - object-oriented interface
 */
export class AuditLogApi extends BaseAPI {
    /** 
     * 
     * @param customerId 
     */
    getCustomerDetailAuditLogs(params: {  "customerId": number; }, options?: any) {
        return AuditLogApiFp.getCustomerDetailAuditLogs(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     * @param body 
     */
    getDataChangeLogs(params: {  "body"?: Array<RelatedEntity>; }, options?: any) {
        return AuditLogApiFp.getDataChangeLogs(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     */
    getEventTypes(options?: any) {
        return AuditLogApiFp.getEventTypes(options)(this.fetch, this.basePath);
    }
    /** 
     * 
     * @param body 
     */
    getRateEngineAuditLogs(params: {  "body"?: RateEngineAuditSearchCriteria; }, options?: any) {
        return AuditLogApiFp.getRateEngineAuditLogs(params, options)(this.fetch, this.basePath);
    }
};

/**
 * AuditLogApi - factory interface
 */
export const AuditLogApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * 
         * @param customerId 
         */
        getCustomerDetailAuditLogs(params: {  "customerId": number; }, options?: any) {
            return AuditLogApiFp.getCustomerDetailAuditLogs(params, options)(fetch, basePath);
        },
        /** 
         * 
         * @param body 
         */
        getDataChangeLogs(params: {  "body"?: Array<RelatedEntity>; }, options?: any) {
            return AuditLogApiFp.getDataChangeLogs(params, options)(fetch, basePath);
        },
        /** 
         * 
         */
        getEventTypes(options?: any) {
            return AuditLogApiFp.getEventTypes(options)(fetch, basePath);
        },
        /** 
         * 
         * @param body 
         */
        getRateEngineAuditLogs(params: {  "body"?: RateEngineAuditSearchCriteria; }, options?: any) {
            return AuditLogApiFp.getRateEngineAuditLogs(params, options)(fetch, basePath);
        },
    };
};


/**
 * CacheApi - fetch parameter creator
 */
export const CacheApiFetchParamCreator = {
    /** 
     * 
     */
    clearCache(options?: any): FetchArgs {
        const baseUrl = `/api/v1/cache/clear`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * CacheApi - functional programming interface
 */
export const CacheApiFp = {
    /** 
     * 
     */
    clearCache(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
        const fetchArgs = CacheApiFetchParamCreator.clearCache(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * CacheApi - object-oriented interface
 */
export class CacheApi extends BaseAPI {
    /** 
     * 
     */
    clearCache(options?: any) {
        return CacheApiFp.clearCache(options)(this.fetch, this.basePath);
    }
};

/**
 * CacheApi - factory interface
 */
export const CacheApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * 
         */
        clearCache(options?: any) {
            return CacheApiFp.clearCache(options)(fetch, basePath);
        },
    };
};


/**
 * CapacityRateApi - fetch parameter creator
 */
export const CapacityRateApiFetchParamCreator = {
    /** 
     * Gets a list of CapacityRate
     * @param companyId 
     */
    apiV1CapacityRateGet(params: {  "companyId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/capacityRate`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "companyId": params["companyId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Deletes the specified company identifier.
     * @param companyId The company identifier.
     * @param id The identifier.
     */
    apiV1CapacityRateIdDelete(params: {  "companyId"?: number; "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/capacityRate/id`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "companyId": params["companyId"],
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "DELETE" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Creates a new CapacityRate
     * @param body The CapacityRate.
     */
    apiV1CapacityRatePost(params: {  "body"?: CapacityRate; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/capacityRate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Updates a specific CapacityRate.
     * @param immediateStart Whether the capacity rate should start immediately
     * @param body The updated CapacityRate.
     */
    updateCapacityRate(params: {  "immediateStart": boolean; "body"?: CapacityRate; }, options?: any): FetchArgs {
        // verify required parameter "immediateStart" is set
        if (params["immediateStart"] == null) {
            throw new Error("Missing required parameter immediateStart when calling updateCapacityRate");
        }
        const baseUrl = `/api/v1/capacityRate/{immediateStart}`
            .replace(`{${"immediateStart"}}`, `${ params["immediateStart"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * CapacityRateApi - functional programming interface
 */
export const CapacityRateApiFp = {
    /** 
     * Gets a list of CapacityRate
     * @param companyId 
     */
    apiV1CapacityRateGet(params: { "companyId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CapacityRate>> {
        const fetchArgs = CapacityRateApiFetchParamCreator.apiV1CapacityRateGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Deletes the specified company identifier.
     * @param companyId The company identifier.
     * @param id The identifier.
     */
    apiV1CapacityRateIdDelete(params: { "companyId"?: number; "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BooleanResponseBase> {
        const fetchArgs = CapacityRateApiFetchParamCreator.apiV1CapacityRateIdDelete(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Creates a new CapacityRate
     * @param body The CapacityRate.
     */
    apiV1CapacityRatePost(params: { "body"?: CapacityRate;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CapacityRateResponseBase> {
        const fetchArgs = CapacityRateApiFetchParamCreator.apiV1CapacityRatePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Updates a specific CapacityRate.
     * @param immediateStart Whether the capacity rate should start immediately
     * @param body The updated CapacityRate.
     */
    updateCapacityRate(params: { "immediateStart": boolean; "body"?: CapacityRate;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CapacityRate> {
        const fetchArgs = CapacityRateApiFetchParamCreator.updateCapacityRate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * CapacityRateApi - object-oriented interface
 */
export class CapacityRateApi extends BaseAPI {
    /** 
     * Gets a list of CapacityRate
     * @param companyId 
     */
    apiV1CapacityRateGet(params: {  "companyId"?: number; }, options?: any) {
        return CapacityRateApiFp.apiV1CapacityRateGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Deletes the specified company identifier.
     * @param companyId The company identifier.
     * @param id The identifier.
     */
    apiV1CapacityRateIdDelete(params: {  "companyId"?: number; "id"?: number; }, options?: any) {
        return CapacityRateApiFp.apiV1CapacityRateIdDelete(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Creates a new CapacityRate
     * @param body The CapacityRate.
     */
    apiV1CapacityRatePost(params: {  "body"?: CapacityRate; }, options?: any) {
        return CapacityRateApiFp.apiV1CapacityRatePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Updates a specific CapacityRate.
     * @param immediateStart Whether the capacity rate should start immediately
     * @param body The updated CapacityRate.
     */
    updateCapacityRate(params: {  "immediateStart": boolean; "body"?: CapacityRate; }, options?: any) {
        return CapacityRateApiFp.updateCapacityRate(params, options)(this.fetch, this.basePath);
    }
};

/**
 * CapacityRateApi - factory interface
 */
export const CapacityRateApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Gets a list of CapacityRate
         * @param companyId 
         */
        apiV1CapacityRateGet(params: {  "companyId"?: number; }, options?: any) {
            return CapacityRateApiFp.apiV1CapacityRateGet(params, options)(fetch, basePath);
        },
        /** 
         * Deletes the specified company identifier.
         * @param companyId The company identifier.
         * @param id The identifier.
         */
        apiV1CapacityRateIdDelete(params: {  "companyId"?: number; "id"?: number; }, options?: any) {
            return CapacityRateApiFp.apiV1CapacityRateIdDelete(params, options)(fetch, basePath);
        },
        /** 
         * Creates a new CapacityRate
         * @param body The CapacityRate.
         */
        apiV1CapacityRatePost(params: {  "body"?: CapacityRate; }, options?: any) {
            return CapacityRateApiFp.apiV1CapacityRatePost(params, options)(fetch, basePath);
        },
        /** 
         * Updates a specific CapacityRate.
         * @param immediateStart Whether the capacity rate should start immediately
         * @param body The updated CapacityRate.
         */
        updateCapacityRate(params: {  "immediateStart": boolean; "body"?: CapacityRate; }, options?: any) {
            return CapacityRateApiFp.updateCapacityRate(params, options)(fetch, basePath);
        },
    };
};


/**
 * CarrierApi - fetch parameter creator
 */
export const CarrierApiFetchParamCreator = {
    /** 
     * 
     */
    getCarrierAssignments(options?: any): FetchArgs {
        const baseUrl = `/api/v1/carrierManagement/carrierAssignments`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Currently this only gets the take rate since that&#39;s calculated from Delivered  records, which are not returned by GetCarrierAssignments
     * @param body 
     */
    getCarrierMetrics(params: {  "body"?: CarrierMetricsParameters; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/carrierManagement/carrierMetrics`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     * @param includeInactive 
     */
    getVendors(params: {  "includeInactive": boolean; }, options?: any): FetchArgs {
        // verify required parameter "includeInactive" is set
        if (params["includeInactive"] == null) {
            throw new Error("Missing required parameter includeInactive when calling getVendors");
        }
        const baseUrl = `/api/v1/carrierManagement/vendors/{includeInactive}`
            .replace(`{${"includeInactive"}}`, `${ params["includeInactive"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     * @param body 
     */
    saveCarrierInfo(params: {  "body"?: QuoteCarrierSaveParams; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/carrierManagement/saveCarrier`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * CarrierApi - functional programming interface
 */
export const CarrierApiFp = {
    /** 
     * 
     */
    getCarrierAssignments(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<QuoteCarrierFlattened>> {
        const fetchArgs = CarrierApiFetchParamCreator.getCarrierAssignments(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Currently this only gets the take rate since that&#39;s calculated from Delivered  records, which are not returned by GetCarrierAssignments
     * @param body 
     */
    getCarrierMetrics(params: { "body"?: CarrierMetricsParameters;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CarrierMetrics> {
        const fetchArgs = CarrierApiFetchParamCreator.getCarrierMetrics(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     * @param includeInactive 
     */
    getVendors(params: { "includeInactive": boolean;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Vendor>> {
        const fetchArgs = CarrierApiFetchParamCreator.getVendors(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     * @param body 
     */
    saveCarrierInfo(params: { "body"?: QuoteCarrierSaveParams;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<QuoteCarrier> {
        const fetchArgs = CarrierApiFetchParamCreator.saveCarrierInfo(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * CarrierApi - object-oriented interface
 */
export class CarrierApi extends BaseAPI {
    /** 
     * 
     */
    getCarrierAssignments(options?: any) {
        return CarrierApiFp.getCarrierAssignments(options)(this.fetch, this.basePath);
    }
    /** 
     * Currently this only gets the take rate since that&#39;s calculated from Delivered  records, which are not returned by GetCarrierAssignments
     * @param body 
     */
    getCarrierMetrics(params: {  "body"?: CarrierMetricsParameters; }, options?: any) {
        return CarrierApiFp.getCarrierMetrics(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     * @param includeInactive 
     */
    getVendors(params: {  "includeInactive": boolean; }, options?: any) {
        return CarrierApiFp.getVendors(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     * @param body 
     */
    saveCarrierInfo(params: {  "body"?: QuoteCarrierSaveParams; }, options?: any) {
        return CarrierApiFp.saveCarrierInfo(params, options)(this.fetch, this.basePath);
    }
};

/**
 * CarrierApi - factory interface
 */
export const CarrierApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * 
         */
        getCarrierAssignments(options?: any) {
            return CarrierApiFp.getCarrierAssignments(options)(fetch, basePath);
        },
        /** 
         * Currently this only gets the take rate since that&#39;s calculated from Delivered  records, which are not returned by GetCarrierAssignments
         * @param body 
         */
        getCarrierMetrics(params: {  "body"?: CarrierMetricsParameters; }, options?: any) {
            return CarrierApiFp.getCarrierMetrics(params, options)(fetch, basePath);
        },
        /** 
         * 
         * @param includeInactive 
         */
        getVendors(params: {  "includeInactive": boolean; }, options?: any) {
            return CarrierApiFp.getVendors(params, options)(fetch, basePath);
        },
        /** 
         * 
         * @param body 
         */
        saveCarrierInfo(params: {  "body"?: QuoteCarrierSaveParams; }, options?: any) {
            return CarrierApiFp.saveCarrierInfo(params, options)(fetch, basePath);
        },
    };
};


/**
 * CommodityApi - fetch parameter creator
 */
export const CommodityApiFetchParamCreator = {
    /** 
     * Get commodity rates in structured view models
     * @param companyId 
     * @param showHistory 
     */
    apiV1CommodityCommodityRatesCompanyIdShowHistoryGet(params: {  "companyId": number; "showHistory": boolean; }, options?: any): FetchArgs {
        // verify required parameter "companyId" is set
        if (params["companyId"] == null) {
            throw new Error("Missing required parameter companyId when calling apiV1CommodityCommodityRatesCompanyIdShowHistoryGet");
        }
        // verify required parameter "showHistory" is set
        if (params["showHistory"] == null) {
            throw new Error("Missing required parameter showHistory when calling apiV1CommodityCommodityRatesCompanyIdShowHistoryGet");
        }
        const baseUrl = `/api/v1/commodity/commodityRates/{companyId}/{showHistory}`
            .replace(`{${"companyId"}}`, `${ params["companyId"] }`)
            .replace(`{${"showHistory"}}`, `${ params["showHistory"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get a list of all commodities, not including CommodityValues
     * @param companyId 
     * @param activeOnly 
     */
    apiV1CommodityItemsCompanyIdActiveOnlyGet(params: {  "companyId": number; "activeOnly": boolean; }, options?: any): FetchArgs {
        // verify required parameter "companyId" is set
        if (params["companyId"] == null) {
            throw new Error("Missing required parameter companyId when calling apiV1CommodityItemsCompanyIdActiveOnlyGet");
        }
        // verify required parameter "activeOnly" is set
        if (params["activeOnly"] == null) {
            throw new Error("Missing required parameter activeOnly when calling apiV1CommodityItemsCompanyIdActiveOnlyGet");
        }
        const baseUrl = `/api/v1/commodity/items/{companyId}/{activeOnly}`
            .replace(`{${"companyId"}}`, `${ params["companyId"] }`)
            .replace(`{${"activeOnly"}}`, `${ params["activeOnly"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Adds a new commodity item
     * @param companyId 
     * @param body 
     */
    apiV1CommodityPost(params: {  "companyId"?: number; "body"?: Commodity; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/commodity`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "companyId": params["companyId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Updates a commodity rate item
     * @param companyId 
     * @param body 
     */
    apiV1CommodityPut(params: {  "companyId"?: number; "body"?: Commodity; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/commodity`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "companyId": params["companyId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Save the commodity rates and apply the end date to the previous rates
     * @param body 
     */
    apiV1CommoditySavePost(params: {  "body"?: CommodityRate; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/commodity/save`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Undo the given commodity rate, reverting to the previous one
     * @param body 
     */
    apiV1CommodityUndoPost(params: {  "body"?: CommodityRate; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/commodity/undo`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets a list of commodities with CommodityValues
     * @param companyId 
     * @param activeOnly 
     */
    apiV1CommodityValuesCompanyIdActiveOnlyGet(params: {  "companyId": number; "activeOnly": boolean; }, options?: any): FetchArgs {
        // verify required parameter "companyId" is set
        if (params["companyId"] == null) {
            throw new Error("Missing required parameter companyId when calling apiV1CommodityValuesCompanyIdActiveOnlyGet");
        }
        // verify required parameter "activeOnly" is set
        if (params["activeOnly"] == null) {
            throw new Error("Missing required parameter activeOnly when calling apiV1CommodityValuesCompanyIdActiveOnlyGet");
        }
        const baseUrl = `/api/v1/commodity/values/{companyId}/{activeOnly}`
            .replace(`{${"companyId"}}`, `${ params["companyId"] }`)
            .replace(`{${"activeOnly"}}`, `${ params["activeOnly"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * CommodityApi - functional programming interface
 */
export const CommodityApiFp = {
    /** 
     * Get commodity rates in structured view models
     * @param companyId 
     * @param showHistory 
     */
    apiV1CommodityCommodityRatesCompanyIdShowHistoryGet(params: { "companyId": number; "showHistory": boolean;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SimplifiedCommodityRate>> {
        const fetchArgs = CommodityApiFetchParamCreator.apiV1CommodityCommodityRatesCompanyIdShowHistoryGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get a list of all commodities, not including CommodityValues
     * @param companyId 
     * @param activeOnly 
     */
    apiV1CommodityItemsCompanyIdActiveOnlyGet(params: { "companyId": number; "activeOnly": boolean;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Commodity>> {
        const fetchArgs = CommodityApiFetchParamCreator.apiV1CommodityItemsCompanyIdActiveOnlyGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Adds a new commodity item
     * @param companyId 
     * @param body 
     */
    apiV1CommodityPost(params: { "companyId"?: number; "body"?: Commodity;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Commodity> {
        const fetchArgs = CommodityApiFetchParamCreator.apiV1CommodityPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Updates a commodity rate item
     * @param companyId 
     * @param body 
     */
    apiV1CommodityPut(params: { "companyId"?: number; "body"?: Commodity;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Commodity> {
        const fetchArgs = CommodityApiFetchParamCreator.apiV1CommodityPut(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Save the commodity rates and apply the end date to the previous rates
     * @param body 
     */
    apiV1CommoditySavePost(params: { "body"?: CommodityRate;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
        const fetchArgs = CommodityApiFetchParamCreator.apiV1CommoditySavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Undo the given commodity rate, reverting to the previous one
     * @param body 
     */
    apiV1CommodityUndoPost(params: { "body"?: CommodityRate;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
        const fetchArgs = CommodityApiFetchParamCreator.apiV1CommodityUndoPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets a list of commodities with CommodityValues
     * @param companyId 
     * @param activeOnly 
     */
    apiV1CommodityValuesCompanyIdActiveOnlyGet(params: { "companyId": number; "activeOnly": boolean;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Commodity>> {
        const fetchArgs = CommodityApiFetchParamCreator.apiV1CommodityValuesCompanyIdActiveOnlyGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * CommodityApi - object-oriented interface
 */
export class CommodityApi extends BaseAPI {
    /** 
     * Get commodity rates in structured view models
     * @param companyId 
     * @param showHistory 
     */
    apiV1CommodityCommodityRatesCompanyIdShowHistoryGet(params: {  "companyId": number; "showHistory": boolean; }, options?: any) {
        return CommodityApiFp.apiV1CommodityCommodityRatesCompanyIdShowHistoryGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get a list of all commodities, not including CommodityValues
     * @param companyId 
     * @param activeOnly 
     */
    apiV1CommodityItemsCompanyIdActiveOnlyGet(params: {  "companyId": number; "activeOnly": boolean; }, options?: any) {
        return CommodityApiFp.apiV1CommodityItemsCompanyIdActiveOnlyGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Adds a new commodity item
     * @param companyId 
     * @param body 
     */
    apiV1CommodityPost(params: {  "companyId"?: number; "body"?: Commodity; }, options?: any) {
        return CommodityApiFp.apiV1CommodityPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Updates a commodity rate item
     * @param companyId 
     * @param body 
     */
    apiV1CommodityPut(params: {  "companyId"?: number; "body"?: Commodity; }, options?: any) {
        return CommodityApiFp.apiV1CommodityPut(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Save the commodity rates and apply the end date to the previous rates
     * @param body 
     */
    apiV1CommoditySavePost(params: {  "body"?: CommodityRate; }, options?: any) {
        return CommodityApiFp.apiV1CommoditySavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Undo the given commodity rate, reverting to the previous one
     * @param body 
     */
    apiV1CommodityUndoPost(params: {  "body"?: CommodityRate; }, options?: any) {
        return CommodityApiFp.apiV1CommodityUndoPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets a list of commodities with CommodityValues
     * @param companyId 
     * @param activeOnly 
     */
    apiV1CommodityValuesCompanyIdActiveOnlyGet(params: {  "companyId": number; "activeOnly": boolean; }, options?: any) {
        return CommodityApiFp.apiV1CommodityValuesCompanyIdActiveOnlyGet(params, options)(this.fetch, this.basePath);
    }
};

/**
 * CommodityApi - factory interface
 */
export const CommodityApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Get commodity rates in structured view models
         * @param companyId 
         * @param showHistory 
         */
        apiV1CommodityCommodityRatesCompanyIdShowHistoryGet(params: {  "companyId": number; "showHistory": boolean; }, options?: any) {
            return CommodityApiFp.apiV1CommodityCommodityRatesCompanyIdShowHistoryGet(params, options)(fetch, basePath);
        },
        /** 
         * Get a list of all commodities, not including CommodityValues
         * @param companyId 
         * @param activeOnly 
         */
        apiV1CommodityItemsCompanyIdActiveOnlyGet(params: {  "companyId": number; "activeOnly": boolean; }, options?: any) {
            return CommodityApiFp.apiV1CommodityItemsCompanyIdActiveOnlyGet(params, options)(fetch, basePath);
        },
        /** 
         * Adds a new commodity item
         * @param companyId 
         * @param body 
         */
        apiV1CommodityPost(params: {  "companyId"?: number; "body"?: Commodity; }, options?: any) {
            return CommodityApiFp.apiV1CommodityPost(params, options)(fetch, basePath);
        },
        /** 
         * Updates a commodity rate item
         * @param companyId 
         * @param body 
         */
        apiV1CommodityPut(params: {  "companyId"?: number; "body"?: Commodity; }, options?: any) {
            return CommodityApiFp.apiV1CommodityPut(params, options)(fetch, basePath);
        },
        /** 
         * Save the commodity rates and apply the end date to the previous rates
         * @param body 
         */
        apiV1CommoditySavePost(params: {  "body"?: CommodityRate; }, options?: any) {
            return CommodityApiFp.apiV1CommoditySavePost(params, options)(fetch, basePath);
        },
        /** 
         * Undo the given commodity rate, reverting to the previous one
         * @param body 
         */
        apiV1CommodityUndoPost(params: {  "body"?: CommodityRate; }, options?: any) {
            return CommodityApiFp.apiV1CommodityUndoPost(params, options)(fetch, basePath);
        },
        /** 
         * Gets a list of commodities with CommodityValues
         * @param companyId 
         * @param activeOnly 
         */
        apiV1CommodityValuesCompanyIdActiveOnlyGet(params: {  "companyId": number; "activeOnly": boolean; }, options?: any) {
            return CommodityApiFp.apiV1CommodityValuesCompanyIdActiveOnlyGet(params, options)(fetch, basePath);
        },
    };
};


/**
 * CompanyApi - fetch parameter creator
 */
export const CompanyApiFetchParamCreator = {
    /** 
     * Get list of companies
     */
    apiV1CompanyGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/company`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * CompanyApi - functional programming interface
 */
export const CompanyApiFp = {
    /** 
     * Get list of companies
     */
    apiV1CompanyGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Company>> {
        const fetchArgs = CompanyApiFetchParamCreator.apiV1CompanyGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * CompanyApi - object-oriented interface
 */
export class CompanyApi extends BaseAPI {
    /** 
     * Get list of companies
     */
    apiV1CompanyGet(options?: any) {
        return CompanyApiFp.apiV1CompanyGet(options)(this.fetch, this.basePath);
    }
};

/**
 * CompanyApi - factory interface
 */
export const CompanyApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Get list of companies
         */
        apiV1CompanyGet(options?: any) {
            return CompanyApiFp.apiV1CompanyGet(options)(fetch, basePath);
        },
    };
};


/**
 * CustomerApi - fetch parameter creator
 */
export const CustomerApiFetchParamCreator = {
    /** 
     * Add a commodityExclusion
     * @param customerId 
     * @param commodityId 
     */
    addCommodityExclusion(params: {  "customerId": number; "commodityId": number; }, options?: any): FetchArgs {
        // verify required parameter "customerId" is set
        if (params["customerId"] == null) {
            throw new Error("Missing required parameter customerId when calling addCommodityExclusion");
        }
        // verify required parameter "commodityId" is set
        if (params["commodityId"] == null) {
            throw new Error("Missing required parameter commodityId when calling addCommodityExclusion");
        }
        const baseUrl = `/api/v1/customers/{customerId}/{commodityId}`
            .replace(`{${"customerId"}}`, `${ params["customerId"] }`)
            .replace(`{${"commodityId"}}`, `${ params["commodityId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     * @param body 
     */
    addContact(params: {  "body"?: CustomerContact; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/customers/addContact`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Create customer in our database.
     * @param body 
     */
    addCustomer(params: {  "body"?: Customer; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/customers`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Check if a customer exists in our database.  If yes, return true.  If no, return false.
     * @param body 
     */
    checkCustomerExists(params: {  "body"?: Customer; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/customers/checkCustomerExists`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Create a note
     * @param body 
     */
    createNote(params: {  "body"?: Activity; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/customers/createNote`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Check if a customer has any active Quotes
     * @param customerId 
     */
    customerHasActiveQuotes(params: {  "customerId": number; }, options?: any): FetchArgs {
        // verify required parameter "customerId" is set
        if (params["customerId"] == null) {
            throw new Error("Missing required parameter customerId when calling customerHasActiveQuotes");
        }
        const baseUrl = `/api/v1/customers/hasActiveQuotes/{customerId}`
            .replace(`{${"customerId"}}`, `${ params["customerId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Delete a commodityExclusion
     * @param customerId 
     * @param commodityId 
     */
    deleteCommodityExclusion(params: {  "customerId": number; "commodityId": number; }, options?: any): FetchArgs {
        // verify required parameter "customerId" is set
        if (params["customerId"] == null) {
            throw new Error("Missing required parameter customerId when calling deleteCommodityExclusion");
        }
        // verify required parameter "commodityId" is set
        if (params["commodityId"] == null) {
            throw new Error("Missing required parameter commodityId when calling deleteCommodityExclusion");
        }
        const baseUrl = `/api/v1/customers/{customerId}/{commodityId}`
            .replace(`{${"customerId"}}`, `${ params["customerId"] }`)
            .replace(`{${"commodityId"}}`, `${ params["commodityId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "DELETE" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Delete a note
     * @param noteId 
     */
    deleteNote(params: {  "noteId": number; }, options?: any): FetchArgs {
        // verify required parameter "noteId" is set
        if (params["noteId"] == null) {
            throw new Error("Missing required parameter noteId when calling deleteNote");
        }
        const baseUrl = `/api/v1/customers/deleteNote/{noteId}`
            .replace(`{${"noteId"}}`, `${ params["noteId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Edit a note
     * @param body 
     */
    editNote(params: {  "body"?: Activity; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/customers/editNote`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Return metrics for a specific customer matching given criteria
     * @param customerId 
     * @param body 
     */
    getActivityByCustomer(params: {  "customerId": number; "body"?: CustomerActivityCriteria; }, options?: any): FetchArgs {
        // verify required parameter "customerId" is set
        if (params["customerId"] == null) {
            throw new Error("Missing required parameter customerId when calling getActivityByCustomer");
        }
        const baseUrl = `/api/v1/customers/getActivity/{customerId}`
            .replace(`{${"customerId"}}`, `${ params["customerId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets Id/Name pairs of all BillTo customers that have at least one Accepted CheckCredit Quote
     * @param billingStatus 
     */
    getBillToCustomers(params: {  "billingStatus": string; }, options?: any): FetchArgs {
        // verify required parameter "billingStatus" is set
        if (params["billingStatus"] == null) {
            throw new Error("Missing required parameter billingStatus when calling getBillToCustomers");
        }
        const baseUrl = `/api/v1/customers/billToCustomers/{billingStatus}`
            .replace(`{${"billingStatus"}}`, `${ params["billingStatus"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get commodityExclusions for the specified customer
     * @param customerId 
     */
    getCommodityExclusionForCustomer(params: {  "customerId": number; }, options?: any): FetchArgs {
        // verify required parameter "customerId" is set
        if (params["customerId"] == null) {
            throw new Error("Missing required parameter customerId when calling getCommodityExclusionForCustomer");
        }
        const baseUrl = `/api/v1/customers/getCommodityExclusion/{customerId}`
            .replace(`{${"customerId"}}`, `${ params["customerId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     */
    getContactTypes(options?: any): FetchArgs {
        const baseUrl = `/api/v1/customers/contactTypes`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     * @param customerId 
     * @param activeOnly 
     */
    getContactsForCustomer(params: {  "customerId": number; "activeOnly": boolean; }, options?: any): FetchArgs {
        // verify required parameter "customerId" is set
        if (params["customerId"] == null) {
            throw new Error("Missing required parameter customerId when calling getContactsForCustomer");
        }
        // verify required parameter "activeOnly" is set
        if (params["activeOnly"] == null) {
            throw new Error("Missing required parameter activeOnly when calling getContactsForCustomer");
        }
        const baseUrl = `/api/v1/customers/getContacts/{customerId}/{activeOnly}`
            .replace(`{${"customerId"}}`, `${ params["customerId"] }`)
            .replace(`{${"activeOnly"}}`, `${ params["activeOnly"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     * @param customerId 
     */
    getCustomerById(params: {  "customerId": number; }, options?: any): FetchArgs {
        // verify required parameter "customerId" is set
        if (params["customerId"] == null) {
            throw new Error("Missing required parameter customerId when calling getCustomerById");
        }
        const baseUrl = `/api/v1/customers/{customerId}`
            .replace(`{${"customerId"}}`, `${ params["customerId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     */
    getNameSuffixes(options?: any): FetchArgs {
        const baseUrl = `/api/v1/customers/nameSuffixes`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get notes for the specified customer
     * @param customerId 
     */
    getNotesForCustomer(params: {  "customerId": number; }, options?: any): FetchArgs {
        // verify required parameter "customerId" is set
        if (params["customerId"] == null) {
            throw new Error("Missing required parameter customerId when calling getNotesForCustomer");
        }
        const baseUrl = `/api/v1/customers/getNotes/{customerId}`
            .replace(`{${"customerId"}}`, `${ params["customerId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Returns a list of quotes associated with a customer
     * @param body 
     */
    getQuotesByCustomer(params: {  "body"?: QuoteSearchCriteria; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/customers/getQuotesByCustomer`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Check if a customer is active in TruckMate.  If yes, updates our database with the latest info from TM.  If not, updates our database to set the customer to inactive.
     * @param tmCustomerId 
     */
    isCustomerActiveInTruckMate(params: {  "tmCustomerId": string; }, options?: any): FetchArgs {
        // verify required parameter "tmCustomerId" is set
        if (params["tmCustomerId"] == null) {
            throw new Error("Missing required parameter tmCustomerId when calling isCustomerActiveInTruckMate");
        }
        const baseUrl = `/api/v1/customers/checkIsActive/{tmCustomerId}`
            .replace(`{${"tmCustomerId"}}`, `${ params["tmCustomerId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     * @param body 
     */
    searchCustomers(params: {  "body"?: CustomerSearchCriteria; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/customers/search`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     * @param body 
     */
    setPrimaryContact(params: {  "body"?: CustomerContact; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/customers/setPrimaryContact`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Set or unset the pin on the specified note
     * @param body 
     */
    togglePinnedStatus(params: {  "body"?: Activity; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/customers/togglePin`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     * @param body 
     */
    updateContact(params: {  "body"?: CustomerContact; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/customers/updateContact`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Save updates to an existing customer in our database only  If the customer&#39;s billing status changes from check credit to credit confirmed  the customer&#39;s associated freight bills&#39; bill to information is updated as well
     * @param body 
     */
    updateCustomer(params: {  "body"?: Customer; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/customers`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * CustomerApi - functional programming interface
 */
export const CustomerApiFp = {
    /** 
     * Add a commodityExclusion
     * @param customerId 
     * @param commodityId 
     */
    addCommodityExclusion(params: { "customerId": number; "commodityId": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
        const fetchArgs = CustomerApiFetchParamCreator.addCommodityExclusion(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     * @param body 
     */
    addContact(params: { "body"?: CustomerContact;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CustomerContact> {
        const fetchArgs = CustomerApiFetchParamCreator.addContact(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Create customer in our database.
     * @param body 
     */
    addCustomer(params: { "body"?: Customer;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Customer> {
        const fetchArgs = CustomerApiFetchParamCreator.addCustomer(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Check if a customer exists in our database.  If yes, return true.  If no, return false.
     * @param body 
     */
    checkCustomerExists(params: { "body"?: Customer;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
        const fetchArgs = CustomerApiFetchParamCreator.checkCustomerExists(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Create a note
     * @param body 
     */
    createNote(params: { "body"?: Activity;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Activity> {
        const fetchArgs = CustomerApiFetchParamCreator.createNote(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Check if a customer has any active Quotes
     * @param customerId 
     */
    customerHasActiveQuotes(params: { "customerId": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
        const fetchArgs = CustomerApiFetchParamCreator.customerHasActiveQuotes(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Delete a commodityExclusion
     * @param customerId 
     * @param commodityId 
     */
    deleteCommodityExclusion(params: { "customerId": number; "commodityId": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
        const fetchArgs = CustomerApiFetchParamCreator.deleteCommodityExclusion(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Delete a note
     * @param noteId 
     */
    deleteNote(params: { "noteId": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Activity> {
        const fetchArgs = CustomerApiFetchParamCreator.deleteNote(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Edit a note
     * @param body 
     */
    editNote(params: { "body"?: Activity;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Activity> {
        const fetchArgs = CustomerApiFetchParamCreator.editNote(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Return metrics for a specific customer matching given criteria
     * @param customerId 
     * @param body 
     */
    getActivityByCustomer(params: { "customerId": number; "body"?: CustomerActivityCriteria;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CustomerActivityResult> {
        const fetchArgs = CustomerApiFetchParamCreator.getActivityByCustomer(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets Id/Name pairs of all BillTo customers that have at least one Accepted CheckCredit Quote
     * @param billingStatus 
     */
    getBillToCustomers(params: { "billingStatus": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SimplifiedCustomer>> {
        const fetchArgs = CustomerApiFetchParamCreator.getBillToCustomers(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get commodityExclusions for the specified customer
     * @param customerId 
     */
    getCommodityExclusionForCustomer(params: { "customerId": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CommodityExclusion>> {
        const fetchArgs = CustomerApiFetchParamCreator.getCommodityExclusionForCustomer(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     */
    getContactTypes(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ContactType>> {
        const fetchArgs = CustomerApiFetchParamCreator.getContactTypes(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     * @param customerId 
     * @param activeOnly 
     */
    getContactsForCustomer(params: { "customerId": number; "activeOnly": boolean;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CustomerContact>> {
        const fetchArgs = CustomerApiFetchParamCreator.getContactsForCustomer(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     * @param customerId 
     */
    getCustomerById(params: { "customerId": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Customer> {
        const fetchArgs = CustomerApiFetchParamCreator.getCustomerById(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     */
    getNameSuffixes(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<NameSuffix>> {
        const fetchArgs = CustomerApiFetchParamCreator.getNameSuffixes(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get notes for the specified customer
     * @param customerId 
     */
    getNotesForCustomer(params: { "customerId": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Activity>> {
        const fetchArgs = CustomerApiFetchParamCreator.getNotesForCustomer(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Returns a list of quotes associated with a customer
     * @param body 
     */
    getQuotesByCustomer(params: { "body"?: QuoteSearchCriteria;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CustomersQuotesSearchResult> {
        const fetchArgs = CustomerApiFetchParamCreator.getQuotesByCustomer(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Check if a customer is active in TruckMate.  If yes, updates our database with the latest info from TM.  If not, updates our database to set the customer to inactive.
     * @param tmCustomerId 
     */
    isCustomerActiveInTruckMate(params: { "tmCustomerId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
        const fetchArgs = CustomerApiFetchParamCreator.isCustomerActiveInTruckMate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     * @param body 
     */
    searchCustomers(params: { "body"?: CustomerSearchCriteria;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CustomerSearchResult> {
        const fetchArgs = CustomerApiFetchParamCreator.searchCustomers(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     * @param body 
     */
    setPrimaryContact(params: { "body"?: CustomerContact;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CustomerContact> {
        const fetchArgs = CustomerApiFetchParamCreator.setPrimaryContact(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Set or unset the pin on the specified note
     * @param body 
     */
    togglePinnedStatus(params: { "body"?: Activity;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Activity> {
        const fetchArgs = CustomerApiFetchParamCreator.togglePinnedStatus(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     * @param body 
     */
    updateContact(params: { "body"?: CustomerContact;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CustomerContact> {
        const fetchArgs = CustomerApiFetchParamCreator.updateContact(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Save updates to an existing customer in our database only  If the customer&#39;s billing status changes from check credit to credit confirmed  the customer&#39;s associated freight bills&#39; bill to information is updated as well
     * @param body 
     */
    updateCustomer(params: { "body"?: Customer;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Customer> {
        const fetchArgs = CustomerApiFetchParamCreator.updateCustomer(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * CustomerApi - object-oriented interface
 */
export class CustomerApi extends BaseAPI {
    /** 
     * Add a commodityExclusion
     * @param customerId 
     * @param commodityId 
     */
    addCommodityExclusion(params: {  "customerId": number; "commodityId": number; }, options?: any) {
        return CustomerApiFp.addCommodityExclusion(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     * @param body 
     */
    addContact(params: {  "body"?: CustomerContact; }, options?: any) {
        return CustomerApiFp.addContact(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Create customer in our database.
     * @param body 
     */
    addCustomer(params: {  "body"?: Customer; }, options?: any) {
        return CustomerApiFp.addCustomer(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Check if a customer exists in our database.  If yes, return true.  If no, return false.
     * @param body 
     */
    checkCustomerExists(params: {  "body"?: Customer; }, options?: any) {
        return CustomerApiFp.checkCustomerExists(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Create a note
     * @param body 
     */
    createNote(params: {  "body"?: Activity; }, options?: any) {
        return CustomerApiFp.createNote(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Check if a customer has any active Quotes
     * @param customerId 
     */
    customerHasActiveQuotes(params: {  "customerId": number; }, options?: any) {
        return CustomerApiFp.customerHasActiveQuotes(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Delete a commodityExclusion
     * @param customerId 
     * @param commodityId 
     */
    deleteCommodityExclusion(params: {  "customerId": number; "commodityId": number; }, options?: any) {
        return CustomerApiFp.deleteCommodityExclusion(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Delete a note
     * @param noteId 
     */
    deleteNote(params: {  "noteId": number; }, options?: any) {
        return CustomerApiFp.deleteNote(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Edit a note
     * @param body 
     */
    editNote(params: {  "body"?: Activity; }, options?: any) {
        return CustomerApiFp.editNote(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Return metrics for a specific customer matching given criteria
     * @param customerId 
     * @param body 
     */
    getActivityByCustomer(params: {  "customerId": number; "body"?: CustomerActivityCriteria; }, options?: any) {
        return CustomerApiFp.getActivityByCustomer(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets Id/Name pairs of all BillTo customers that have at least one Accepted CheckCredit Quote
     * @param billingStatus 
     */
    getBillToCustomers(params: {  "billingStatus": string; }, options?: any) {
        return CustomerApiFp.getBillToCustomers(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get commodityExclusions for the specified customer
     * @param customerId 
     */
    getCommodityExclusionForCustomer(params: {  "customerId": number; }, options?: any) {
        return CustomerApiFp.getCommodityExclusionForCustomer(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     */
    getContactTypes(options?: any) {
        return CustomerApiFp.getContactTypes(options)(this.fetch, this.basePath);
    }
    /** 
     * 
     * @param customerId 
     * @param activeOnly 
     */
    getContactsForCustomer(params: {  "customerId": number; "activeOnly": boolean; }, options?: any) {
        return CustomerApiFp.getContactsForCustomer(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     * @param customerId 
     */
    getCustomerById(params: {  "customerId": number; }, options?: any) {
        return CustomerApiFp.getCustomerById(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     */
    getNameSuffixes(options?: any) {
        return CustomerApiFp.getNameSuffixes(options)(this.fetch, this.basePath);
    }
    /** 
     * Get notes for the specified customer
     * @param customerId 
     */
    getNotesForCustomer(params: {  "customerId": number; }, options?: any) {
        return CustomerApiFp.getNotesForCustomer(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Returns a list of quotes associated with a customer
     * @param body 
     */
    getQuotesByCustomer(params: {  "body"?: QuoteSearchCriteria; }, options?: any) {
        return CustomerApiFp.getQuotesByCustomer(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Check if a customer is active in TruckMate.  If yes, updates our database with the latest info from TM.  If not, updates our database to set the customer to inactive.
     * @param tmCustomerId 
     */
    isCustomerActiveInTruckMate(params: {  "tmCustomerId": string; }, options?: any) {
        return CustomerApiFp.isCustomerActiveInTruckMate(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     * @param body 
     */
    searchCustomers(params: {  "body"?: CustomerSearchCriteria; }, options?: any) {
        return CustomerApiFp.searchCustomers(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     * @param body 
     */
    setPrimaryContact(params: {  "body"?: CustomerContact; }, options?: any) {
        return CustomerApiFp.setPrimaryContact(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Set or unset the pin on the specified note
     * @param body 
     */
    togglePinnedStatus(params: {  "body"?: Activity; }, options?: any) {
        return CustomerApiFp.togglePinnedStatus(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     * @param body 
     */
    updateContact(params: {  "body"?: CustomerContact; }, options?: any) {
        return CustomerApiFp.updateContact(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Save updates to an existing customer in our database only  If the customer&#39;s billing status changes from check credit to credit confirmed  the customer&#39;s associated freight bills&#39; bill to information is updated as well
     * @param body 
     */
    updateCustomer(params: {  "body"?: Customer; }, options?: any) {
        return CustomerApiFp.updateCustomer(params, options)(this.fetch, this.basePath);
    }
};

/**
 * CustomerApi - factory interface
 */
export const CustomerApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Add a commodityExclusion
         * @param customerId 
         * @param commodityId 
         */
        addCommodityExclusion(params: {  "customerId": number; "commodityId": number; }, options?: any) {
            return CustomerApiFp.addCommodityExclusion(params, options)(fetch, basePath);
        },
        /** 
         * 
         * @param body 
         */
        addContact(params: {  "body"?: CustomerContact; }, options?: any) {
            return CustomerApiFp.addContact(params, options)(fetch, basePath);
        },
        /** 
         * Create customer in our database.
         * @param body 
         */
        addCustomer(params: {  "body"?: Customer; }, options?: any) {
            return CustomerApiFp.addCustomer(params, options)(fetch, basePath);
        },
        /** 
         * Check if a customer exists in our database.  If yes, return true.  If no, return false.
         * @param body 
         */
        checkCustomerExists(params: {  "body"?: Customer; }, options?: any) {
            return CustomerApiFp.checkCustomerExists(params, options)(fetch, basePath);
        },
        /** 
         * Create a note
         * @param body 
         */
        createNote(params: {  "body"?: Activity; }, options?: any) {
            return CustomerApiFp.createNote(params, options)(fetch, basePath);
        },
        /** 
         * Check if a customer has any active Quotes
         * @param customerId 
         */
        customerHasActiveQuotes(params: {  "customerId": number; }, options?: any) {
            return CustomerApiFp.customerHasActiveQuotes(params, options)(fetch, basePath);
        },
        /** 
         * Delete a commodityExclusion
         * @param customerId 
         * @param commodityId 
         */
        deleteCommodityExclusion(params: {  "customerId": number; "commodityId": number; }, options?: any) {
            return CustomerApiFp.deleteCommodityExclusion(params, options)(fetch, basePath);
        },
        /** 
         * Delete a note
         * @param noteId 
         */
        deleteNote(params: {  "noteId": number; }, options?: any) {
            return CustomerApiFp.deleteNote(params, options)(fetch, basePath);
        },
        /** 
         * Edit a note
         * @param body 
         */
        editNote(params: {  "body"?: Activity; }, options?: any) {
            return CustomerApiFp.editNote(params, options)(fetch, basePath);
        },
        /** 
         * Return metrics for a specific customer matching given criteria
         * @param customerId 
         * @param body 
         */
        getActivityByCustomer(params: {  "customerId": number; "body"?: CustomerActivityCriteria; }, options?: any) {
            return CustomerApiFp.getActivityByCustomer(params, options)(fetch, basePath);
        },
        /** 
         * Gets Id/Name pairs of all BillTo customers that have at least one Accepted CheckCredit Quote
         * @param billingStatus 
         */
        getBillToCustomers(params: {  "billingStatus": string; }, options?: any) {
            return CustomerApiFp.getBillToCustomers(params, options)(fetch, basePath);
        },
        /** 
         * Get commodityExclusions for the specified customer
         * @param customerId 
         */
        getCommodityExclusionForCustomer(params: {  "customerId": number; }, options?: any) {
            return CustomerApiFp.getCommodityExclusionForCustomer(params, options)(fetch, basePath);
        },
        /** 
         * 
         */
        getContactTypes(options?: any) {
            return CustomerApiFp.getContactTypes(options)(fetch, basePath);
        },
        /** 
         * 
         * @param customerId 
         * @param activeOnly 
         */
        getContactsForCustomer(params: {  "customerId": number; "activeOnly": boolean; }, options?: any) {
            return CustomerApiFp.getContactsForCustomer(params, options)(fetch, basePath);
        },
        /** 
         * 
         * @param customerId 
         */
        getCustomerById(params: {  "customerId": number; }, options?: any) {
            return CustomerApiFp.getCustomerById(params, options)(fetch, basePath);
        },
        /** 
         * 
         */
        getNameSuffixes(options?: any) {
            return CustomerApiFp.getNameSuffixes(options)(fetch, basePath);
        },
        /** 
         * Get notes for the specified customer
         * @param customerId 
         */
        getNotesForCustomer(params: {  "customerId": number; }, options?: any) {
            return CustomerApiFp.getNotesForCustomer(params, options)(fetch, basePath);
        },
        /** 
         * Returns a list of quotes associated with a customer
         * @param body 
         */
        getQuotesByCustomer(params: {  "body"?: QuoteSearchCriteria; }, options?: any) {
            return CustomerApiFp.getQuotesByCustomer(params, options)(fetch, basePath);
        },
        /** 
         * Check if a customer is active in TruckMate.  If yes, updates our database with the latest info from TM.  If not, updates our database to set the customer to inactive.
         * @param tmCustomerId 
         */
        isCustomerActiveInTruckMate(params: {  "tmCustomerId": string; }, options?: any) {
            return CustomerApiFp.isCustomerActiveInTruckMate(params, options)(fetch, basePath);
        },
        /** 
         * 
         * @param body 
         */
        searchCustomers(params: {  "body"?: CustomerSearchCriteria; }, options?: any) {
            return CustomerApiFp.searchCustomers(params, options)(fetch, basePath);
        },
        /** 
         * 
         * @param body 
         */
        setPrimaryContact(params: {  "body"?: CustomerContact; }, options?: any) {
            return CustomerApiFp.setPrimaryContact(params, options)(fetch, basePath);
        },
        /** 
         * Set or unset the pin on the specified note
         * @param body 
         */
        togglePinnedStatus(params: {  "body"?: Activity; }, options?: any) {
            return CustomerApiFp.togglePinnedStatus(params, options)(fetch, basePath);
        },
        /** 
         * 
         * @param body 
         */
        updateContact(params: {  "body"?: CustomerContact; }, options?: any) {
            return CustomerApiFp.updateContact(params, options)(fetch, basePath);
        },
        /** 
         * Save updates to an existing customer in our database only  If the customer&#39;s billing status changes from check credit to credit confirmed  the customer&#39;s associated freight bills&#39; bill to information is updated as well
         * @param body 
         */
        updateCustomer(params: {  "body"?: Customer; }, options?: any) {
            return CustomerApiFp.updateCustomer(params, options)(fetch, basePath);
        },
    };
};


/**
 * CustomerQuoteApi - fetch parameter creator
 */
export const CustomerQuoteApiFetchParamCreator = {
    /** 
     * Mark a customerQuote as reviewed
     * @param id 
     */
    apiV1CustomerQuotesIdReviewedPost(params: {  "id": number; }, options?: any): FetchArgs {
        // verify required parameter "id" is set
        if (params["id"] == null) {
            throw new Error("Missing required parameter id when calling apiV1CustomerQuotesIdReviewedPost");
        }
        const baseUrl = `/api/v1/customerQuotes/{id}/reviewed`
            .replace(`{${"id"}}`, `${ params["id"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get a quote based on passed in Id
     * @param quoteId 
     */
    apiV1CustomerQuotesQuoteIdGet(params: {  "quoteId": number; }, options?: any): FetchArgs {
        // verify required parameter "quoteId" is set
        if (params["quoteId"] == null) {
            throw new Error("Missing required parameter quoteId when calling apiV1CustomerQuotesQuoteIdGet");
        }
        const baseUrl = `/api/v1/customerQuotes/{quoteId}`
            .replace(`{${"quoteId"}}`, `${ params["quoteId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets list of quotes based on supplied search criteria
     * @param body 
     */
    apiV1CustomerQuotesSearchPost(params: {  "body"?: CustomerQuotesSearchCriteria; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/customerQuotes/search`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Calculate total freight length for a customer portal quote
     * @param body 
     */
    getFreightLength(params: {  "body"?: Array<CustomerQuoteFreight>; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/customerQuotes/getFreightLength`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets the count of requested customer quotes that have not yet been reviewed
     * @param body 
     */
    getRequestedCustomerQuotesCount(params: {  "body"?: CustomerQuotesSearchCriteria; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/customerQuotes/requestedCustomerQuotesCount`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * CustomerQuoteApi - functional programming interface
 */
export const CustomerQuoteApiFp = {
    /** 
     * Mark a customerQuote as reviewed
     * @param id 
     */
    apiV1CustomerQuotesIdReviewedPost(params: { "id": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = CustomerQuoteApiFetchParamCreator.apiV1CustomerQuotesIdReviewedPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get a quote based on passed in Id
     * @param quoteId 
     */
    apiV1CustomerQuotesQuoteIdGet(params: { "quoteId": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CustomerQuote> {
        const fetchArgs = CustomerQuoteApiFetchParamCreator.apiV1CustomerQuotesQuoteIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets list of quotes based on supplied search criteria
     * @param body 
     */
    apiV1CustomerQuotesSearchPost(params: { "body"?: CustomerQuotesSearchCriteria;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SimplifiedCustomerQuoteSearchResult> {
        const fetchArgs = CustomerQuoteApiFetchParamCreator.apiV1CustomerQuotesSearchPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Calculate total freight length for a customer portal quote
     * @param body 
     */
    getFreightLength(params: { "body"?: Array<CustomerQuoteFreight>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
        const fetchArgs = CustomerQuoteApiFetchParamCreator.getFreightLength(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets the count of requested customer quotes that have not yet been reviewed
     * @param body 
     */
    getRequestedCustomerQuotesCount(params: { "body"?: CustomerQuotesSearchCriteria;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
        const fetchArgs = CustomerQuoteApiFetchParamCreator.getRequestedCustomerQuotesCount(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * CustomerQuoteApi - object-oriented interface
 */
export class CustomerQuoteApi extends BaseAPI {
    /** 
     * Mark a customerQuote as reviewed
     * @param id 
     */
    apiV1CustomerQuotesIdReviewedPost(params: {  "id": number; }, options?: any) {
        return CustomerQuoteApiFp.apiV1CustomerQuotesIdReviewedPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get a quote based on passed in Id
     * @param quoteId 
     */
    apiV1CustomerQuotesQuoteIdGet(params: {  "quoteId": number; }, options?: any) {
        return CustomerQuoteApiFp.apiV1CustomerQuotesQuoteIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets list of quotes based on supplied search criteria
     * @param body 
     */
    apiV1CustomerQuotesSearchPost(params: {  "body"?: CustomerQuotesSearchCriteria; }, options?: any) {
        return CustomerQuoteApiFp.apiV1CustomerQuotesSearchPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Calculate total freight length for a customer portal quote
     * @param body 
     */
    getFreightLength(params: {  "body"?: Array<CustomerQuoteFreight>; }, options?: any) {
        return CustomerQuoteApiFp.getFreightLength(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets the count of requested customer quotes that have not yet been reviewed
     * @param body 
     */
    getRequestedCustomerQuotesCount(params: {  "body"?: CustomerQuotesSearchCriteria; }, options?: any) {
        return CustomerQuoteApiFp.getRequestedCustomerQuotesCount(params, options)(this.fetch, this.basePath);
    }
};

/**
 * CustomerQuoteApi - factory interface
 */
export const CustomerQuoteApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Mark a customerQuote as reviewed
         * @param id 
         */
        apiV1CustomerQuotesIdReviewedPost(params: {  "id": number; }, options?: any) {
            return CustomerQuoteApiFp.apiV1CustomerQuotesIdReviewedPost(params, options)(fetch, basePath);
        },
        /** 
         * Get a quote based on passed in Id
         * @param quoteId 
         */
        apiV1CustomerQuotesQuoteIdGet(params: {  "quoteId": number; }, options?: any) {
            return CustomerQuoteApiFp.apiV1CustomerQuotesQuoteIdGet(params, options)(fetch, basePath);
        },
        /** 
         * Gets list of quotes based on supplied search criteria
         * @param body 
         */
        apiV1CustomerQuotesSearchPost(params: {  "body"?: CustomerQuotesSearchCriteria; }, options?: any) {
            return CustomerQuoteApiFp.apiV1CustomerQuotesSearchPost(params, options)(fetch, basePath);
        },
        /** 
         * Calculate total freight length for a customer portal quote
         * @param body 
         */
        getFreightLength(params: {  "body"?: Array<CustomerQuoteFreight>; }, options?: any) {
            return CustomerQuoteApiFp.getFreightLength(params, options)(fetch, basePath);
        },
        /** 
         * Gets the count of requested customer quotes that have not yet been reviewed
         * @param body 
         */
        getRequestedCustomerQuotesCount(params: {  "body"?: CustomerQuotesSearchCriteria; }, options?: any) {
            return CustomerQuoteApiFp.getRequestedCustomerQuotesCount(params, options)(fetch, basePath);
        },
    };
};


/**
 * CustomerSourceApi - fetch parameter creator
 */
export const CustomerSourceApiFetchParamCreator = {
    /** 
     * 
     * @param body 
     */
    addCustomerSource(params: {  "body"?: CustomerSource; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/customerSource/addCustomerSource`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     * @param activeOnly 
     */
    getCustomerSources(params: {  "activeOnly": boolean; }, options?: any): FetchArgs {
        // verify required parameter "activeOnly" is set
        if (params["activeOnly"] == null) {
            throw new Error("Missing required parameter activeOnly when calling getCustomerSources");
        }
        const baseUrl = `/api/v1/customerSource/customerSources/{activeOnly}`
            .replace(`{${"activeOnly"}}`, `${ params["activeOnly"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     * @param body 
     */
    updateCustomerSource(params: {  "body"?: CustomerSource; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/customerSource/updateCustomerSource`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * CustomerSourceApi - functional programming interface
 */
export const CustomerSourceApiFp = {
    /** 
     * 
     * @param body 
     */
    addCustomerSource(params: { "body"?: CustomerSource;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CustomerSource> {
        const fetchArgs = CustomerSourceApiFetchParamCreator.addCustomerSource(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     * @param activeOnly 
     */
    getCustomerSources(params: { "activeOnly": boolean;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CustomerSource>> {
        const fetchArgs = CustomerSourceApiFetchParamCreator.getCustomerSources(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     * @param body 
     */
    updateCustomerSource(params: { "body"?: CustomerSource;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CustomerSource> {
        const fetchArgs = CustomerSourceApiFetchParamCreator.updateCustomerSource(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * CustomerSourceApi - object-oriented interface
 */
export class CustomerSourceApi extends BaseAPI {
    /** 
     * 
     * @param body 
     */
    addCustomerSource(params: {  "body"?: CustomerSource; }, options?: any) {
        return CustomerSourceApiFp.addCustomerSource(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     * @param activeOnly 
     */
    getCustomerSources(params: {  "activeOnly": boolean; }, options?: any) {
        return CustomerSourceApiFp.getCustomerSources(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     * @param body 
     */
    updateCustomerSource(params: {  "body"?: CustomerSource; }, options?: any) {
        return CustomerSourceApiFp.updateCustomerSource(params, options)(this.fetch, this.basePath);
    }
};

/**
 * CustomerSourceApi - factory interface
 */
export const CustomerSourceApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * 
         * @param body 
         */
        addCustomerSource(params: {  "body"?: CustomerSource; }, options?: any) {
            return CustomerSourceApiFp.addCustomerSource(params, options)(fetch, basePath);
        },
        /** 
         * 
         * @param activeOnly 
         */
        getCustomerSources(params: {  "activeOnly": boolean; }, options?: any) {
            return CustomerSourceApiFp.getCustomerSources(params, options)(fetch, basePath);
        },
        /** 
         * 
         * @param body 
         */
        updateCustomerSource(params: {  "body"?: CustomerSource; }, options?: any) {
            return CustomerSourceApiFp.updateCustomerSource(params, options)(fetch, basePath);
        },
    };
};


/**
 * DatAuditApi - fetch parameter creator
 */
export const DatAuditApiFetchParamCreator = {
    /** 
     * 
     * @param body 
     */
    getDatAuditReport(params: {  "body"?: DatAuditSearchCriteria; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/datAudit/getDatAuditReport`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * DatAuditApi - functional programming interface
 */
export const DatAuditApiFp = {
    /** 
     * 
     * @param body 
     */
    getDatAuditReport(params: { "body"?: DatAuditSearchCriteria;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<DatAuditReport>> {
        const fetchArgs = DatAuditApiFetchParamCreator.getDatAuditReport(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * DatAuditApi - object-oriented interface
 */
export class DatAuditApi extends BaseAPI {
    /** 
     * 
     * @param body 
     */
    getDatAuditReport(params: {  "body"?: DatAuditSearchCriteria; }, options?: any) {
        return DatAuditApiFp.getDatAuditReport(params, options)(this.fetch, this.basePath);
    }
};

/**
 * DatAuditApi - factory interface
 */
export const DatAuditApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * 
         * @param body 
         */
        getDatAuditReport(params: {  "body"?: DatAuditSearchCriteria; }, options?: any) {
            return DatAuditApiFp.getDatAuditReport(params, options)(fetch, basePath);
        },
    };
};


/**
 * DeclaredValueApi - fetch parameter creator
 */
export const DeclaredValueApiFetchParamCreator = {
    /** 
     * 
     * @param body 
     */
    apiV1DeclaredValueAddPut(params: {  "body"?: DeclaredValueApproval; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/declared_value/add`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     */
    apiV1DeclaredValueApprovalsGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/declared_value/approvals`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * DeclaredValueApi - functional programming interface
 */
export const DeclaredValueApiFp = {
    /** 
     * 
     * @param body 
     */
    apiV1DeclaredValueAddPut(params: { "body"?: DeclaredValueApproval;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = DeclaredValueApiFetchParamCreator.apiV1DeclaredValueAddPut(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     */
    apiV1DeclaredValueApprovalsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<DeclaredValueApproval>> {
        const fetchArgs = DeclaredValueApiFetchParamCreator.apiV1DeclaredValueApprovalsGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * DeclaredValueApi - object-oriented interface
 */
export class DeclaredValueApi extends BaseAPI {
    /** 
     * 
     * @param body 
     */
    apiV1DeclaredValueAddPut(params: {  "body"?: DeclaredValueApproval; }, options?: any) {
        return DeclaredValueApiFp.apiV1DeclaredValueAddPut(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     */
    apiV1DeclaredValueApprovalsGet(options?: any) {
        return DeclaredValueApiFp.apiV1DeclaredValueApprovalsGet(options)(this.fetch, this.basePath);
    }
};

/**
 * DeclaredValueApi - factory interface
 */
export const DeclaredValueApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * 
         * @param body 
         */
        apiV1DeclaredValueAddPut(params: {  "body"?: DeclaredValueApproval; }, options?: any) {
            return DeclaredValueApiFp.apiV1DeclaredValueAddPut(params, options)(fetch, basePath);
        },
        /** 
         * 
         */
        apiV1DeclaredValueApprovalsGet(options?: any) {
            return DeclaredValueApiFp.apiV1DeclaredValueApprovalsGet(options)(fetch, basePath);
        },
    };
};


/**
 * EmployeeApi - fetch parameter creator
 */
export const EmployeeApiFetchParamCreator = {
    /** 
     * Get all employees
     * @param body 
     */
    searchEmployees(params: {  "body"?: EmployeeSearchCriteria; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/employees`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * EmployeeApi - functional programming interface
 */
export const EmployeeApiFp = {
    /** 
     * Get all employees
     * @param body 
     */
    searchEmployees(params: { "body"?: EmployeeSearchCriteria;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Employee>> {
        const fetchArgs = EmployeeApiFetchParamCreator.searchEmployees(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * EmployeeApi - object-oriented interface
 */
export class EmployeeApi extends BaseAPI {
    /** 
     * Get all employees
     * @param body 
     */
    searchEmployees(params: {  "body"?: EmployeeSearchCriteria; }, options?: any) {
        return EmployeeApiFp.searchEmployees(params, options)(this.fetch, this.basePath);
    }
};

/**
 * EmployeeApi - factory interface
 */
export const EmployeeApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Get all employees
         * @param body 
         */
        searchEmployees(params: {  "body"?: EmployeeSearchCriteria; }, options?: any) {
            return EmployeeApiFp.searchEmployees(params, options)(fetch, basePath);
        },
    };
};


/**
 * EquipmentApi - fetch parameter creator
 */
export const EquipmentApiFetchParamCreator = {
    /** 
     * Create a new equipment type
     * @param body 
     */
    addEquipmentType(params: {  "body"?: EquipmentType; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/equipment/addEquipmentType`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get equipment type rates
     * @param companyId 
     * @param activeOnly 
     */
    getEquipmentRates(params: {  "companyId": number; "activeOnly": boolean; }, options?: any): FetchArgs {
        // verify required parameter "companyId" is set
        if (params["companyId"] == null) {
            throw new Error("Missing required parameter companyId when calling getEquipmentRates");
        }
        // verify required parameter "activeOnly" is set
        if (params["activeOnly"] == null) {
            throw new Error("Missing required parameter activeOnly when calling getEquipmentRates");
        }
        const baseUrl = `/api/v1/equipment/equipmentRates/{companyId}/{activeOnly}`
            .replace(`{${"companyId"}}`, `${ params["companyId"] }`)
            .replace(`{${"activeOnly"}}`, `${ params["activeOnly"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get equipment types
     * @param activeOnly 
     */
    getEquipmentTypes(params: {  "activeOnly": boolean; }, options?: any): FetchArgs {
        // verify required parameter "activeOnly" is set
        if (params["activeOnly"] == null) {
            throw new Error("Missing required parameter activeOnly when calling getEquipmentTypes");
        }
        const baseUrl = `/api/v1/equipment/equipmentTypes/{activeOnly}`
            .replace(`{${"activeOnly"}}`, `${ params["activeOnly"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get overdimensional rulesets
     */
    getOverdimensionalRulesets(options?: any): FetchArgs {
        const baseUrl = `/api/v1/equipment/getOverdimensionalRulesets`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Update an existing equipmeent type rate
     * @param body 
     */
    saveEquipmentRate(params: {  "body"?: EquipmentTypeValue; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/equipment/saveEquipmentRate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Update an existing equipment type
     * @param body 
     */
    updateEquipmentType(params: {  "body"?: EquipmentType; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/equipment/updateEquipmentType`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * EquipmentApi - functional programming interface
 */
export const EquipmentApiFp = {
    /** 
     * Create a new equipment type
     * @param body 
     */
    addEquipmentType(params: { "body"?: EquipmentType;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EquipmentType> {
        const fetchArgs = EquipmentApiFetchParamCreator.addEquipmentType(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get equipment type rates
     * @param companyId 
     * @param activeOnly 
     */
    getEquipmentRates(params: { "companyId": number; "activeOnly": boolean;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<EquipmentType>> {
        const fetchArgs = EquipmentApiFetchParamCreator.getEquipmentRates(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get equipment types
     * @param activeOnly 
     */
    getEquipmentTypes(params: { "activeOnly": boolean;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<EquipmentType>> {
        const fetchArgs = EquipmentApiFetchParamCreator.getEquipmentTypes(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get overdimensional rulesets
     */
    getOverdimensionalRulesets(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<OverdimensionalRuleset>> {
        const fetchArgs = EquipmentApiFetchParamCreator.getOverdimensionalRulesets(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Update an existing equipmeent type rate
     * @param body 
     */
    saveEquipmentRate(params: { "body"?: EquipmentTypeValue;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EquipmentTypeValue> {
        const fetchArgs = EquipmentApiFetchParamCreator.saveEquipmentRate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Update an existing equipment type
     * @param body 
     */
    updateEquipmentType(params: { "body"?: EquipmentType;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EquipmentType> {
        const fetchArgs = EquipmentApiFetchParamCreator.updateEquipmentType(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * EquipmentApi - object-oriented interface
 */
export class EquipmentApi extends BaseAPI {
    /** 
     * Create a new equipment type
     * @param body 
     */
    addEquipmentType(params: {  "body"?: EquipmentType; }, options?: any) {
        return EquipmentApiFp.addEquipmentType(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get equipment type rates
     * @param companyId 
     * @param activeOnly 
     */
    getEquipmentRates(params: {  "companyId": number; "activeOnly": boolean; }, options?: any) {
        return EquipmentApiFp.getEquipmentRates(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get equipment types
     * @param activeOnly 
     */
    getEquipmentTypes(params: {  "activeOnly": boolean; }, options?: any) {
        return EquipmentApiFp.getEquipmentTypes(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get overdimensional rulesets
     */
    getOverdimensionalRulesets(options?: any) {
        return EquipmentApiFp.getOverdimensionalRulesets(options)(this.fetch, this.basePath);
    }
    /** 
     * Update an existing equipmeent type rate
     * @param body 
     */
    saveEquipmentRate(params: {  "body"?: EquipmentTypeValue; }, options?: any) {
        return EquipmentApiFp.saveEquipmentRate(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Update an existing equipment type
     * @param body 
     */
    updateEquipmentType(params: {  "body"?: EquipmentType; }, options?: any) {
        return EquipmentApiFp.updateEquipmentType(params, options)(this.fetch, this.basePath);
    }
};

/**
 * EquipmentApi - factory interface
 */
export const EquipmentApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Create a new equipment type
         * @param body 
         */
        addEquipmentType(params: {  "body"?: EquipmentType; }, options?: any) {
            return EquipmentApiFp.addEquipmentType(params, options)(fetch, basePath);
        },
        /** 
         * Get equipment type rates
         * @param companyId 
         * @param activeOnly 
         */
        getEquipmentRates(params: {  "companyId": number; "activeOnly": boolean; }, options?: any) {
            return EquipmentApiFp.getEquipmentRates(params, options)(fetch, basePath);
        },
        /** 
         * Get equipment types
         * @param activeOnly 
         */
        getEquipmentTypes(params: {  "activeOnly": boolean; }, options?: any) {
            return EquipmentApiFp.getEquipmentTypes(params, options)(fetch, basePath);
        },
        /** 
         * Get overdimensional rulesets
         */
        getOverdimensionalRulesets(options?: any) {
            return EquipmentApiFp.getOverdimensionalRulesets(options)(fetch, basePath);
        },
        /** 
         * Update an existing equipmeent type rate
         * @param body 
         */
        saveEquipmentRate(params: {  "body"?: EquipmentTypeValue; }, options?: any) {
            return EquipmentApiFp.saveEquipmentRate(params, options)(fetch, basePath);
        },
        /** 
         * Update an existing equipment type
         * @param body 
         */
        updateEquipmentType(params: {  "body"?: EquipmentType; }, options?: any) {
            return EquipmentApiFp.updateEquipmentType(params, options)(fetch, basePath);
        },
    };
};


/**
 * FuelSurchargeApi - fetch parameter creator
 */
export const FuelSurchargeApiFetchParamCreator = {
    /** 
     * Gets a list of fuel surcharges
     */
    apiV1FuelSurchargeGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/fuelSurcharge`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Adds a new fuel surcharge
     * @param body 
     */
    apiV1FuelSurchargePost(params: {  "body"?: FuelSurcharge; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/fuelSurcharge`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * FuelSurchargeApi - functional programming interface
 */
export const FuelSurchargeApiFp = {
    /** 
     * Gets a list of fuel surcharges
     */
    apiV1FuelSurchargeGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<FuelSurcharge>> {
        const fetchArgs = FuelSurchargeApiFetchParamCreator.apiV1FuelSurchargeGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Adds a new fuel surcharge
     * @param body 
     */
    apiV1FuelSurchargePost(params: { "body"?: FuelSurcharge;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FuelSurcharge> {
        const fetchArgs = FuelSurchargeApiFetchParamCreator.apiV1FuelSurchargePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * FuelSurchargeApi - object-oriented interface
 */
export class FuelSurchargeApi extends BaseAPI {
    /** 
     * Gets a list of fuel surcharges
     */
    apiV1FuelSurchargeGet(options?: any) {
        return FuelSurchargeApiFp.apiV1FuelSurchargeGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Adds a new fuel surcharge
     * @param body 
     */
    apiV1FuelSurchargePost(params: {  "body"?: FuelSurcharge; }, options?: any) {
        return FuelSurchargeApiFp.apiV1FuelSurchargePost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * FuelSurchargeApi - factory interface
 */
export const FuelSurchargeApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Gets a list of fuel surcharges
         */
        apiV1FuelSurchargeGet(options?: any) {
            return FuelSurchargeApiFp.apiV1FuelSurchargeGet(options)(fetch, basePath);
        },
        /** 
         * Adds a new fuel surcharge
         * @param body 
         */
        apiV1FuelSurchargePost(params: {  "body"?: FuelSurcharge; }, options?: any) {
            return FuelSurchargeApiFp.apiV1FuelSurchargePost(params, options)(fetch, basePath);
        },
    };
};


/**
 * LeadSourceApi - fetch parameter creator
 */
export const LeadSourceApiFetchParamCreator = {
    /** 
     * 
     * @param body 
     */
    createLeadSource(params: {  "body"?: LeadSource; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/leadSource`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get all lead sources
     * @param includeInactive 
     */
    getLeadSources(params: {  "includeInactive"?: boolean; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/leadSource`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "includeInactive": params["includeInactive"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     * @param id 
     * @param body 
     */
    updateLeadSource(params: {  "id": number; "body"?: LeadSource; }, options?: any): FetchArgs {
        // verify required parameter "id" is set
        if (params["id"] == null) {
            throw new Error("Missing required parameter id when calling updateLeadSource");
        }
        const baseUrl = `/api/v1/leadSource/{id}`
            .replace(`{${"id"}}`, `${ params["id"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * LeadSourceApi - functional programming interface
 */
export const LeadSourceApiFp = {
    /** 
     * 
     * @param body 
     */
    createLeadSource(params: { "body"?: LeadSource;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadSource> {
        const fetchArgs = LeadSourceApiFetchParamCreator.createLeadSource(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get all lead sources
     * @param includeInactive 
     */
    getLeadSources(params: { "includeInactive"?: boolean;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<LeadSource>> {
        const fetchArgs = LeadSourceApiFetchParamCreator.getLeadSources(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     * @param id 
     * @param body 
     */
    updateLeadSource(params: { "id": number; "body"?: LeadSource;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadSource> {
        const fetchArgs = LeadSourceApiFetchParamCreator.updateLeadSource(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * LeadSourceApi - object-oriented interface
 */
export class LeadSourceApi extends BaseAPI {
    /** 
     * 
     * @param body 
     */
    createLeadSource(params: {  "body"?: LeadSource; }, options?: any) {
        return LeadSourceApiFp.createLeadSource(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get all lead sources
     * @param includeInactive 
     */
    getLeadSources(params: {  "includeInactive"?: boolean; }, options?: any) {
        return LeadSourceApiFp.getLeadSources(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     * @param id 
     * @param body 
     */
    updateLeadSource(params: {  "id": number; "body"?: LeadSource; }, options?: any) {
        return LeadSourceApiFp.updateLeadSource(params, options)(this.fetch, this.basePath);
    }
};

/**
 * LeadSourceApi - factory interface
 */
export const LeadSourceApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * 
         * @param body 
         */
        createLeadSource(params: {  "body"?: LeadSource; }, options?: any) {
            return LeadSourceApiFp.createLeadSource(params, options)(fetch, basePath);
        },
        /** 
         * Get all lead sources
         * @param includeInactive 
         */
        getLeadSources(params: {  "includeInactive"?: boolean; }, options?: any) {
            return LeadSourceApiFp.getLeadSources(params, options)(fetch, basePath);
        },
        /** 
         * 
         * @param id 
         * @param body 
         */
        updateLeadSource(params: {  "id": number; "body"?: LeadSource; }, options?: any) {
            return LeadSourceApiFp.updateLeadSource(params, options)(fetch, basePath);
        },
    };
};


/**
 * LoadingInstructionApi - fetch parameter creator
 */
export const LoadingInstructionApiFetchParamCreator = {
    /** 
     * 
     * @param body 
     */
    addLoadingInstruction(params: {  "body"?: LoadingInstruction; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/loadingInstruction/addLoadingInstruction`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     * @param activeOnly 
     */
    getLoadingInstructions(params: {  "activeOnly": boolean; }, options?: any): FetchArgs {
        // verify required parameter "activeOnly" is set
        if (params["activeOnly"] == null) {
            throw new Error("Missing required parameter activeOnly when calling getLoadingInstructions");
        }
        const baseUrl = `/api/v1/loadingInstruction/loadingInstructions/{activeOnly}`
            .replace(`{${"activeOnly"}}`, `${ params["activeOnly"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     * @param body 
     */
    updateLoadingInstruction(params: {  "body"?: LoadingInstruction; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/loadingInstruction/updateLoadingInstruction`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * LoadingInstructionApi - functional programming interface
 */
export const LoadingInstructionApiFp = {
    /** 
     * 
     * @param body 
     */
    addLoadingInstruction(params: { "body"?: LoadingInstruction;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LoadingInstruction> {
        const fetchArgs = LoadingInstructionApiFetchParamCreator.addLoadingInstruction(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     * @param activeOnly 
     */
    getLoadingInstructions(params: { "activeOnly": boolean;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<LoadingInstruction>> {
        const fetchArgs = LoadingInstructionApiFetchParamCreator.getLoadingInstructions(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     * @param body 
     */
    updateLoadingInstruction(params: { "body"?: LoadingInstruction;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LoadingInstruction> {
        const fetchArgs = LoadingInstructionApiFetchParamCreator.updateLoadingInstruction(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * LoadingInstructionApi - object-oriented interface
 */
export class LoadingInstructionApi extends BaseAPI {
    /** 
     * 
     * @param body 
     */
    addLoadingInstruction(params: {  "body"?: LoadingInstruction; }, options?: any) {
        return LoadingInstructionApiFp.addLoadingInstruction(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     * @param activeOnly 
     */
    getLoadingInstructions(params: {  "activeOnly": boolean; }, options?: any) {
        return LoadingInstructionApiFp.getLoadingInstructions(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     * @param body 
     */
    updateLoadingInstruction(params: {  "body"?: LoadingInstruction; }, options?: any) {
        return LoadingInstructionApiFp.updateLoadingInstruction(params, options)(this.fetch, this.basePath);
    }
};

/**
 * LoadingInstructionApi - factory interface
 */
export const LoadingInstructionApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * 
         * @param body 
         */
        addLoadingInstruction(params: {  "body"?: LoadingInstruction; }, options?: any) {
            return LoadingInstructionApiFp.addLoadingInstruction(params, options)(fetch, basePath);
        },
        /** 
         * 
         * @param activeOnly 
         */
        getLoadingInstructions(params: {  "activeOnly": boolean; }, options?: any) {
            return LoadingInstructionApiFp.getLoadingInstructions(params, options)(fetch, basePath);
        },
        /** 
         * 
         * @param body 
         */
        updateLoadingInstruction(params: {  "body"?: LoadingInstruction; }, options?: any) {
            return LoadingInstructionApiFp.updateLoadingInstruction(params, options)(fetch, basePath);
        },
    };
};


/**
 * LogisticsApi - fetch parameter creator
 */
export const LogisticsApiFetchParamCreator = {
    /** 
     * 
     * @param body 
     */
    apiV1LogisticsAddPut(params: {  "body"?: LogisticsMarkup; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/logistics/add`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     */
    apiV1LogisticsMarkupsGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/logistics/markups`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * LogisticsApi - functional programming interface
 */
export const LogisticsApiFp = {
    /** 
     * 
     * @param body 
     */
    apiV1LogisticsAddPut(params: { "body"?: LogisticsMarkup;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = LogisticsApiFetchParamCreator.apiV1LogisticsAddPut(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     */
    apiV1LogisticsMarkupsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<LogisticsMarkup>> {
        const fetchArgs = LogisticsApiFetchParamCreator.apiV1LogisticsMarkupsGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * LogisticsApi - object-oriented interface
 */
export class LogisticsApi extends BaseAPI {
    /** 
     * 
     * @param body 
     */
    apiV1LogisticsAddPut(params: {  "body"?: LogisticsMarkup; }, options?: any) {
        return LogisticsApiFp.apiV1LogisticsAddPut(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     */
    apiV1LogisticsMarkupsGet(options?: any) {
        return LogisticsApiFp.apiV1LogisticsMarkupsGet(options)(this.fetch, this.basePath);
    }
};

/**
 * LogisticsApi - factory interface
 */
export const LogisticsApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * 
         * @param body 
         */
        apiV1LogisticsAddPut(params: {  "body"?: LogisticsMarkup; }, options?: any) {
            return LogisticsApiFp.apiV1LogisticsAddPut(params, options)(fetch, basePath);
        },
        /** 
         * 
         */
        apiV1LogisticsMarkupsGet(options?: any) {
            return LogisticsApiFp.apiV1LogisticsMarkupsGet(options)(fetch, basePath);
        },
    };
};


/**
 * OpportunityApi - fetch parameter creator
 */
export const OpportunityApiFetchParamCreator = {
    /** 
     * Create a new sales opportunity
     * @param body 
     */
    createOpportunity(params: {  "body"?: Opportunity; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/opportunity`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get a sales opportunity by (primary key) ID
     * @param id 
     */
    getOpportunityById(params: {  "id": number; }, options?: any): FetchArgs {
        // verify required parameter "id" is set
        if (params["id"] == null) {
            throw new Error("Missing required parameter id when calling getOpportunityById");
        }
        const baseUrl = `/api/v1/opportunity/{id}`
            .replace(`{${"id"}}`, `${ params["id"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get all sales opportunities matching the given criteria
     * @param body 
     */
    searchOpportunities(params: {  "body"?: OpportunitySearchCriteria; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/opportunity/search`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Update an existing sales opportunity
     * @param id 
     * @param body 
     */
    updateOpportunity(params: {  "id": number; "body"?: Opportunity; }, options?: any): FetchArgs {
        // verify required parameter "id" is set
        if (params["id"] == null) {
            throw new Error("Missing required parameter id when calling updateOpportunity");
        }
        const baseUrl = `/api/v1/opportunity/{id}`
            .replace(`{${"id"}}`, `${ params["id"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * OpportunityApi - functional programming interface
 */
export const OpportunityApiFp = {
    /** 
     * Create a new sales opportunity
     * @param body 
     */
    createOpportunity(params: { "body"?: Opportunity;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Opportunity> {
        const fetchArgs = OpportunityApiFetchParamCreator.createOpportunity(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get a sales opportunity by (primary key) ID
     * @param id 
     */
    getOpportunityById(params: { "id": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Opportunity> {
        const fetchArgs = OpportunityApiFetchParamCreator.getOpportunityById(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get all sales opportunities matching the given criteria
     * @param body 
     */
    searchOpportunities(params: { "body"?: OpportunitySearchCriteria;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Opportunity>> {
        const fetchArgs = OpportunityApiFetchParamCreator.searchOpportunities(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Update an existing sales opportunity
     * @param id 
     * @param body 
     */
    updateOpportunity(params: { "id": number; "body"?: Opportunity;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Opportunity> {
        const fetchArgs = OpportunityApiFetchParamCreator.updateOpportunity(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * OpportunityApi - object-oriented interface
 */
export class OpportunityApi extends BaseAPI {
    /** 
     * Create a new sales opportunity
     * @param body 
     */
    createOpportunity(params: {  "body"?: Opportunity; }, options?: any) {
        return OpportunityApiFp.createOpportunity(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get a sales opportunity by (primary key) ID
     * @param id 
     */
    getOpportunityById(params: {  "id": number; }, options?: any) {
        return OpportunityApiFp.getOpportunityById(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get all sales opportunities matching the given criteria
     * @param body 
     */
    searchOpportunities(params: {  "body"?: OpportunitySearchCriteria; }, options?: any) {
        return OpportunityApiFp.searchOpportunities(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Update an existing sales opportunity
     * @param id 
     * @param body 
     */
    updateOpportunity(params: {  "id": number; "body"?: Opportunity; }, options?: any) {
        return OpportunityApiFp.updateOpportunity(params, options)(this.fetch, this.basePath);
    }
};

/**
 * OpportunityApi - factory interface
 */
export const OpportunityApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Create a new sales opportunity
         * @param body 
         */
        createOpportunity(params: {  "body"?: Opportunity; }, options?: any) {
            return OpportunityApiFp.createOpportunity(params, options)(fetch, basePath);
        },
        /** 
         * Get a sales opportunity by (primary key) ID
         * @param id 
         */
        getOpportunityById(params: {  "id": number; }, options?: any) {
            return OpportunityApiFp.getOpportunityById(params, options)(fetch, basePath);
        },
        /** 
         * Get all sales opportunities matching the given criteria
         * @param body 
         */
        searchOpportunities(params: {  "body"?: OpportunitySearchCriteria; }, options?: any) {
            return OpportunityApiFp.searchOpportunities(params, options)(fetch, basePath);
        },
        /** 
         * Update an existing sales opportunity
         * @param id 
         * @param body 
         */
        updateOpportunity(params: {  "id": number; "body"?: Opportunity; }, options?: any) {
            return OpportunityApiFp.updateOpportunity(params, options)(fetch, basePath);
        },
    };
};


/**
 * OtherFreightInfoApi - fetch parameter creator
 */
export const OtherFreightInfoApiFetchParamCreator = {
    /** 
     * @param tmCompanyId 
     */
    apiV1OtherFreightInfoOpsCodesPost(params: {  "tmCompanyId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/otherFreightInfo/opsCodes`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "tmCompanyId": params["tmCompanyId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param tmCompanyId 
     */
    apiV1OtherFreightInfoSitesPost(params: {  "tmCompanyId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/otherFreightInfo/sites`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "tmCompanyId": params["tmCompanyId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * OtherFreightInfoApi - functional programming interface
 */
export const OtherFreightInfoApiFp = {
    /** 
     * @param tmCompanyId 
     */
    apiV1OtherFreightInfoOpsCodesPost(params: { "tmCompanyId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<OpsCodeView>> {
        const fetchArgs = OtherFreightInfoApiFetchParamCreator.apiV1OtherFreightInfoOpsCodesPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param tmCompanyId 
     */
    apiV1OtherFreightInfoSitesPost(params: { "tmCompanyId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SiteView>> {
        const fetchArgs = OtherFreightInfoApiFetchParamCreator.apiV1OtherFreightInfoSitesPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * OtherFreightInfoApi - object-oriented interface
 */
export class OtherFreightInfoApi extends BaseAPI {
    /** 
     * @param tmCompanyId 
     */
    apiV1OtherFreightInfoOpsCodesPost(params: {  "tmCompanyId"?: number; }, options?: any) {
        return OtherFreightInfoApiFp.apiV1OtherFreightInfoOpsCodesPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param tmCompanyId 
     */
    apiV1OtherFreightInfoSitesPost(params: {  "tmCompanyId"?: number; }, options?: any) {
        return OtherFreightInfoApiFp.apiV1OtherFreightInfoSitesPost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * OtherFreightInfoApi - factory interface
 */
export const OtherFreightInfoApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * @param tmCompanyId 
         */
        apiV1OtherFreightInfoOpsCodesPost(params: {  "tmCompanyId"?: number; }, options?: any) {
            return OtherFreightInfoApiFp.apiV1OtherFreightInfoOpsCodesPost(params, options)(fetch, basePath);
        },
        /** 
         * @param tmCompanyId 
         */
        apiV1OtherFreightInfoSitesPost(params: {  "tmCompanyId"?: number; }, options?: any) {
            return OtherFreightInfoApiFp.apiV1OtherFreightInfoSitesPost(params, options)(fetch, basePath);
        },
    };
};


/**
 * PCMilerApi - fetch parameter creator
 */
export const PCMilerApiFetchParamCreator = {
    /** 
     * Get mileage for entire quote trip
     * @param body 
     */
    apiV1PCMilerGetTripMileagePost(params: {  "body"?: Array<ZipCodePair>; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/PCMiler/getTripMileage`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Lookup Places by City/State
     * @param body 
     */
    apiV1PCMilerGetZipCodesByCityStatePost(params: {  "body"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/PCMiler/getZipCodesByCityState`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get mileage between them
     * @param origin 
     * @param destination 
     */
    apiV1PCMilerMileageOriginDestinationGet(params: {  "origin": string; "destination": string; }, options?: any): FetchArgs {
        // verify required parameter "origin" is set
        if (params["origin"] == null) {
            throw new Error("Missing required parameter origin when calling apiV1PCMilerMileageOriginDestinationGet");
        }
        // verify required parameter "destination" is set
        if (params["destination"] == null) {
            throw new Error("Missing required parameter destination when calling apiV1PCMilerMileageOriginDestinationGet");
        }
        const baseUrl = `/api/v1/PCMiler/mileage/{origin}/{destination}`
            .replace(`{${"origin"}}`, `${ params["origin"] }`)
            .replace(`{${"destination"}}`, `${ params["destination"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Validate a list of zip codes
     * @param body 
     */
    apiV1PCMilerValidateZipCodesPost(params: {  "body"?: Array<string>; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/PCMiler/validateZipCodes`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * PCMilerApi - functional programming interface
 */
export const PCMilerApiFp = {
    /** 
     * Get mileage for entire quote trip
     * @param body 
     */
    apiV1PCMilerGetTripMileagePost(params: { "body"?: Array<ZipCodePair>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TripResult> {
        const fetchArgs = PCMilerApiFetchParamCreator.apiV1PCMilerGetTripMileagePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Lookup Places by City/State
     * @param body 
     */
    apiV1PCMilerGetZipCodesByCityStatePost(params: { "body"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Place>> {
        const fetchArgs = PCMilerApiFetchParamCreator.apiV1PCMilerGetZipCodesByCityStatePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get mileage between them
     * @param origin 
     * @param destination 
     */
    apiV1PCMilerMileageOriginDestinationGet(params: { "origin": string; "destination": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ZipCodePairMileage> {
        const fetchArgs = PCMilerApiFetchParamCreator.apiV1PCMilerMileageOriginDestinationGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Validate a list of zip codes
     * @param body 
     */
    apiV1PCMilerValidateZipCodesPost(params: { "body"?: Array<string>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ZipCodeValidationResult>> {
        const fetchArgs = PCMilerApiFetchParamCreator.apiV1PCMilerValidateZipCodesPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * PCMilerApi - object-oriented interface
 */
export class PCMilerApi extends BaseAPI {
    /** 
     * Get mileage for entire quote trip
     * @param body 
     */
    apiV1PCMilerGetTripMileagePost(params: {  "body"?: Array<ZipCodePair>; }, options?: any) {
        return PCMilerApiFp.apiV1PCMilerGetTripMileagePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Lookup Places by City/State
     * @param body 
     */
    apiV1PCMilerGetZipCodesByCityStatePost(params: {  "body"?: string; }, options?: any) {
        return PCMilerApiFp.apiV1PCMilerGetZipCodesByCityStatePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get mileage between them
     * @param origin 
     * @param destination 
     */
    apiV1PCMilerMileageOriginDestinationGet(params: {  "origin": string; "destination": string; }, options?: any) {
        return PCMilerApiFp.apiV1PCMilerMileageOriginDestinationGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Validate a list of zip codes
     * @param body 
     */
    apiV1PCMilerValidateZipCodesPost(params: {  "body"?: Array<string>; }, options?: any) {
        return PCMilerApiFp.apiV1PCMilerValidateZipCodesPost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * PCMilerApi - factory interface
 */
export const PCMilerApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Get mileage for entire quote trip
         * @param body 
         */
        apiV1PCMilerGetTripMileagePost(params: {  "body"?: Array<ZipCodePair>; }, options?: any) {
            return PCMilerApiFp.apiV1PCMilerGetTripMileagePost(params, options)(fetch, basePath);
        },
        /** 
         * Lookup Places by City/State
         * @param body 
         */
        apiV1PCMilerGetZipCodesByCityStatePost(params: {  "body"?: string; }, options?: any) {
            return PCMilerApiFp.apiV1PCMilerGetZipCodesByCityStatePost(params, options)(fetch, basePath);
        },
        /** 
         * Get mileage between them
         * @param origin 
         * @param destination 
         */
        apiV1PCMilerMileageOriginDestinationGet(params: {  "origin": string; "destination": string; }, options?: any) {
            return PCMilerApiFp.apiV1PCMilerMileageOriginDestinationGet(params, options)(fetch, basePath);
        },
        /** 
         * Validate a list of zip codes
         * @param body 
         */
        apiV1PCMilerValidateZipCodesPost(params: {  "body"?: Array<string>; }, options?: any) {
            return PCMilerApiFp.apiV1PCMilerValidateZipCodesPost(params, options)(fetch, basePath);
        },
    };
};


/**
 * PerStopFeeApi - fetch parameter creator
 */
export const PerStopFeeApiFetchParamCreator = {
    /** 
     * 
     * @param body 
     */
    apiV1PerStopFeeAddPut(params: {  "body"?: PerStopFee; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/per_stop_fee/add`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     */
    apiV1PerStopFeeFeesGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/per_stop_fee/fees`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * PerStopFeeApi - functional programming interface
 */
export const PerStopFeeApiFp = {
    /** 
     * 
     * @param body 
     */
    apiV1PerStopFeeAddPut(params: { "body"?: PerStopFee;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PerStopFeeApiFetchParamCreator.apiV1PerStopFeeAddPut(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     */
    apiV1PerStopFeeFeesGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<PerStopFee>> {
        const fetchArgs = PerStopFeeApiFetchParamCreator.apiV1PerStopFeeFeesGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * PerStopFeeApi - object-oriented interface
 */
export class PerStopFeeApi extends BaseAPI {
    /** 
     * 
     * @param body 
     */
    apiV1PerStopFeeAddPut(params: {  "body"?: PerStopFee; }, options?: any) {
        return PerStopFeeApiFp.apiV1PerStopFeeAddPut(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     */
    apiV1PerStopFeeFeesGet(options?: any) {
        return PerStopFeeApiFp.apiV1PerStopFeeFeesGet(options)(this.fetch, this.basePath);
    }
};

/**
 * PerStopFeeApi - factory interface
 */
export const PerStopFeeApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * 
         * @param body 
         */
        apiV1PerStopFeeAddPut(params: {  "body"?: PerStopFee; }, options?: any) {
            return PerStopFeeApiFp.apiV1PerStopFeeAddPut(params, options)(fetch, basePath);
        },
        /** 
         * 
         */
        apiV1PerStopFeeFeesGet(options?: any) {
            return PerStopFeeApiFp.apiV1PerStopFeeFeesGet(options)(fetch, basePath);
        },
    };
};


/**
 * QuestionApi - fetch parameter creator
 */
export const QuestionApiFetchParamCreator = {
    /** 
     * Get commodity questions active at date
     */
    apiV1QuestionsActiveCommodityGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/questions/activeCommodity`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get upcharge questions active at date
     */
    apiV1QuestionsActiveUpchargeGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/questions/activeUpcharge`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Add a new question
     * @param body 
     */
    apiV1QuestionsAddPut(params: {  "body"?: Question; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/questions/add`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets questions
     * @param companyId 
     * @param showHistory 
     */
    apiV1QuestionsCompanyIdShowHistoryGet(params: {  "companyId": number; "showHistory": boolean; }, options?: any): FetchArgs {
        // verify required parameter "companyId" is set
        if (params["companyId"] == null) {
            throw new Error("Missing required parameter companyId when calling apiV1QuestionsCompanyIdShowHistoryGet");
        }
        // verify required parameter "showHistory" is set
        if (params["showHistory"] == null) {
            throw new Error("Missing required parameter showHistory when calling apiV1QuestionsCompanyIdShowHistoryGet");
        }
        const baseUrl = `/api/v1/questions/{companyId}/{showHistory}`
            .replace(`{${"companyId"}}`, `${ params["companyId"] }`)
            .replace(`{${"showHistory"}}`, `${ params["showHistory"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Update a question
     * @param body question view model
     */
    apiV1QuestionsUpdatePost(params: {  "body"?: QuestionUpdateVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/questions/update`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * QuestionApi - functional programming interface
 */
export const QuestionApiFp = {
    /** 
     * Get commodity questions active at date
     */
    apiV1QuestionsActiveCommodityGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Question>> {
        const fetchArgs = QuestionApiFetchParamCreator.apiV1QuestionsActiveCommodityGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get upcharge questions active at date
     */
    apiV1QuestionsActiveUpchargeGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Question>> {
        const fetchArgs = QuestionApiFetchParamCreator.apiV1QuestionsActiveUpchargeGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Add a new question
     * @param body 
     */
    apiV1QuestionsAddPut(params: { "body"?: Question;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Question> {
        const fetchArgs = QuestionApiFetchParamCreator.apiV1QuestionsAddPut(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets questions
     * @param companyId 
     * @param showHistory 
     */
    apiV1QuestionsCompanyIdShowHistoryGet(params: { "companyId": number; "showHistory": boolean;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Question>> {
        const fetchArgs = QuestionApiFetchParamCreator.apiV1QuestionsCompanyIdShowHistoryGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Update a question
     * @param body question view model
     */
    apiV1QuestionsUpdatePost(params: { "body"?: QuestionUpdateVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Question> {
        const fetchArgs = QuestionApiFetchParamCreator.apiV1QuestionsUpdatePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * QuestionApi - object-oriented interface
 */
export class QuestionApi extends BaseAPI {
    /** 
     * Get commodity questions active at date
     */
    apiV1QuestionsActiveCommodityGet(options?: any) {
        return QuestionApiFp.apiV1QuestionsActiveCommodityGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Get upcharge questions active at date
     */
    apiV1QuestionsActiveUpchargeGet(options?: any) {
        return QuestionApiFp.apiV1QuestionsActiveUpchargeGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Add a new question
     * @param body 
     */
    apiV1QuestionsAddPut(params: {  "body"?: Question; }, options?: any) {
        return QuestionApiFp.apiV1QuestionsAddPut(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets questions
     * @param companyId 
     * @param showHistory 
     */
    apiV1QuestionsCompanyIdShowHistoryGet(params: {  "companyId": number; "showHistory": boolean; }, options?: any) {
        return QuestionApiFp.apiV1QuestionsCompanyIdShowHistoryGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Update a question
     * @param body question view model
     */
    apiV1QuestionsUpdatePost(params: {  "body"?: QuestionUpdateVM; }, options?: any) {
        return QuestionApiFp.apiV1QuestionsUpdatePost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * QuestionApi - factory interface
 */
export const QuestionApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Get commodity questions active at date
         */
        apiV1QuestionsActiveCommodityGet(options?: any) {
            return QuestionApiFp.apiV1QuestionsActiveCommodityGet(options)(fetch, basePath);
        },
        /** 
         * Get upcharge questions active at date
         */
        apiV1QuestionsActiveUpchargeGet(options?: any) {
            return QuestionApiFp.apiV1QuestionsActiveUpchargeGet(options)(fetch, basePath);
        },
        /** 
         * Add a new question
         * @param body 
         */
        apiV1QuestionsAddPut(params: {  "body"?: Question; }, options?: any) {
            return QuestionApiFp.apiV1QuestionsAddPut(params, options)(fetch, basePath);
        },
        /** 
         * Gets questions
         * @param companyId 
         * @param showHistory 
         */
        apiV1QuestionsCompanyIdShowHistoryGet(params: {  "companyId": number; "showHistory": boolean; }, options?: any) {
            return QuestionApiFp.apiV1QuestionsCompanyIdShowHistoryGet(params, options)(fetch, basePath);
        },
        /** 
         * Update a question
         * @param body question view model
         */
        apiV1QuestionsUpdatePost(params: {  "body"?: QuestionUpdateVM; }, options?: any) {
            return QuestionApiFp.apiV1QuestionsUpdatePost(params, options)(fetch, basePath);
        },
    };
};


/**
 * QuickQuoteApi - fetch parameter creator
 */
export const QuickQuoteApiFetchParamCreator = {
    /** 
     * Create a quick quote through the quick quote workflow
     * @param body 
     */
    saveQuickQuote(params: {  "body"?: QuickQuoteChangeStatusVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/quickquotes/saveQuickQuote`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * QuickQuoteApi - functional programming interface
 */
export const QuickQuoteApiFp = {
    /** 
     * Create a quick quote through the quick quote workflow
     * @param body 
     */
    saveQuickQuote(params: { "body"?: QuickQuoteChangeStatusVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<QuoteResultVM> {
        const fetchArgs = QuickQuoteApiFetchParamCreator.saveQuickQuote(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * QuickQuoteApi - object-oriented interface
 */
export class QuickQuoteApi extends BaseAPI {
    /** 
     * Create a quick quote through the quick quote workflow
     * @param body 
     */
    saveQuickQuote(params: {  "body"?: QuickQuoteChangeStatusVM; }, options?: any) {
        return QuickQuoteApiFp.saveQuickQuote(params, options)(this.fetch, this.basePath);
    }
};

/**
 * QuickQuoteApi - factory interface
 */
export const QuickQuoteApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Create a quick quote through the quick quote workflow
         * @param body 
         */
        saveQuickQuote(params: {  "body"?: QuickQuoteChangeStatusVM; }, options?: any) {
            return QuickQuoteApiFp.saveQuickQuote(params, options)(fetch, basePath);
        },
    };
};


/**
 * QuoteApi - fetch parameter creator
 */
export const QuoteApiFetchParamCreator = {
    /** 
     * 
     * @param body 
     */
    addAccessorialChargeValue(params: {  "body"?: AccessorialChargeValue; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/quotes/addAccessorialChargeValue`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Applies the layover expedited flags.
     * @param body The quote stops.
     */
    apiV1QuotesApplyLayoverExpeditedFlagsPost(params: {  "body"?: Array<QuoteStop>; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/quotes/applyLayoverExpeditedFlags`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     * @param body 
     */
    apiV1QuotesQuotesPost(params: {  "body"?: QuoteSearchCriteria; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/quotes/quotes`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     * @param body 
     */
    apiV1QuotesSaveOtherFreightInfoPost(params: {  "body"?: OtherFreightInfosParams; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/quotes/saveOtherFreightInfo`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Approve an accessorial charge value
     * @param id Id of the accessorial charge value to approve
     */
    approveAccessorialChargeValue(params: {  "id": number; }, options?: any): FetchArgs {
        // verify required parameter "id" is set
        if (params["id"] == null) {
            throw new Error("Missing required parameter id when calling approveAccessorialChargeValue");
        }
        const baseUrl = `/api/v1/quotes/accessorialChargeValues/{id}/approve`
            .replace(`{${"id"}}`, `${ params["id"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Calculate a quote&#39;s current rate without saving to database
     * @param body 
     */
    calculateRate(params: {  "body"?: QuoteEditModeVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/quotes/calculateRate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Cancel a quote
     * @param body 
     */
    cancelQuote(params: {  "body"?: CancelQuoteParams; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/quotes/cancel`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Delete an accessorial charge value
     * @param id Id of the accessorial charge value to delete
     */
    deleteAccessorialChargeValue(params: {  "id": number; }, options?: any): FetchArgs {
        // verify required parameter "id" is set
        if (params["id"] == null) {
            throw new Error("Missing required parameter id when calling deleteAccessorialChargeValue");
        }
        const baseUrl = `/api/v1/quotes/accessorialChargeValues/{id}`
            .replace(`{${"id"}}`, `${ params["id"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "DELETE" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Email a quote PDF
     * @param body 
     */
    emailQuotePDF(params: {  "body"?: QuoteEmailRequestVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/quotes/emailQuotePDF`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     * @param quoteId 
     */
    getAccessorialChargeValues(params: {  "quoteId": number; }, options?: any): FetchArgs {
        // verify required parameter "quoteId" is set
        if (params["quoteId"] == null) {
            throw new Error("Missing required parameter quoteId when calling getAccessorialChargeValues");
        }
        const baseUrl = `/api/v1/quotes/accessorialChargeValues/{quoteId}`
            .replace(`{${"quoteId"}}`, `${ params["quoteId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Returns a list of accepted quotes with a billing status of cod only and a payment collected value of false
     * @param body 
     */
    getBillingStatusQuotes(params: {  "body"?: QuoteSearchCriteria; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/quotes/billingstatusquotes`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Calculate rating variable from given freight and override
     * @param body 
     */
    getCalculatedRatingVariable(params: {  "body"?: QuoteCalculateRatingVariableVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/quotes/getCalculatedRatingVariable`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     * @param quoteId 
     */
    getQuote(params: {  "quoteId": number; }, options?: any): FetchArgs {
        // verify required parameter "quoteId" is set
        if (params["quoteId"] == null) {
            throw new Error("Missing required parameter quoteId when calling getQuote");
        }
        const baseUrl = `/api/v1/quotes/quote/{quoteId}`
            .replace(`{${"quoteId"}}`, `${ params["quoteId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     * @param body 
     */
    saveBillToCustomerForQuote(params: {  "body"?: QuoteBillToVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/quotes/saveBillToCustomerForQuote`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     * @param body 
     */
    saveContactForQuote(params: {  "body"?: QuoteContactVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/quotes/saveContactForQuote`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * This is meant to be a temporary method
     * @param body 
     */
    saveFreightSerialNumbers(params: {  "body"?: Array<QuoteFreight>; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/quotes/serialNumbers`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Create a quote or change an existing quote&#39;s status through the quote workflow
     * @param body 
     */
    saveQuote(params: {  "body"?: QuoteChangeStatusVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/quotes/save`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Updates the quote;s payment collected to true.
     * @param quoteId 
     */
    updateQuotePaymentCollected(params: {  "quoteId": number; }, options?: any): FetchArgs {
        // verify required parameter "quoteId" is set
        if (params["quoteId"] == null) {
            throw new Error("Missing required parameter quoteId when calling updateQuotePaymentCollected");
        }
        const baseUrl = `/api/v1/quotes/quotePaymentCollected{quoteId}`
            .replace(`{${"quoteId"}}`, `${ params["quoteId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * QuoteApi - functional programming interface
 */
export const QuoteApiFp = {
    /** 
     * 
     * @param body 
     */
    addAccessorialChargeValue(params: { "body"?: AccessorialChargeValue;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccessorialChargeValue> {
        const fetchArgs = QuoteApiFetchParamCreator.addAccessorialChargeValue(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Applies the layover expedited flags.
     * @param body The quote stops.
     */
    apiV1QuotesApplyLayoverExpeditedFlagsPost(params: { "body"?: Array<QuoteStop>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<QuoteStopRouteResults> {
        const fetchArgs = QuoteApiFetchParamCreator.apiV1QuotesApplyLayoverExpeditedFlagsPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     * @param body 
     */
    apiV1QuotesQuotesPost(params: { "body"?: QuoteSearchCriteria;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SimplifiedQuoteSearchResult> {
        const fetchArgs = QuoteApiFetchParamCreator.apiV1QuotesQuotesPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     * @param body 
     */
    apiV1QuotesSaveOtherFreightInfoPost(params: { "body"?: OtherFreightInfosParams;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = QuoteApiFetchParamCreator.apiV1QuotesSaveOtherFreightInfoPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Approve an accessorial charge value
     * @param id Id of the accessorial charge value to approve
     */
    approveAccessorialChargeValue(params: { "id": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = QuoteApiFetchParamCreator.approveAccessorialChargeValue(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Calculate a quote&#39;s current rate without saving to database
     * @param body 
     */
    calculateRate(params: { "body"?: QuoteEditModeVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<QuoteCalculateRatingResult> {
        const fetchArgs = QuoteApiFetchParamCreator.calculateRate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Cancel a quote
     * @param body 
     */
    cancelQuote(params: { "body"?: CancelQuoteParams;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Quote> {
        const fetchArgs = QuoteApiFetchParamCreator.cancelQuote(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Delete an accessorial charge value
     * @param id Id of the accessorial charge value to delete
     */
    deleteAccessorialChargeValue(params: { "id": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = QuoteApiFetchParamCreator.deleteAccessorialChargeValue(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Email a quote PDF
     * @param body 
     */
    emailQuotePDF(params: { "body"?: QuoteEmailRequestVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<QuoteEmailResult> {
        const fetchArgs = QuoteApiFetchParamCreator.emailQuotePDF(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     * @param quoteId 
     */
    getAccessorialChargeValues(params: { "quoteId": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<AccessorialChargeValue>> {
        const fetchArgs = QuoteApiFetchParamCreator.getAccessorialChargeValues(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Returns a list of accepted quotes with a billing status of cod only and a payment collected value of false
     * @param body 
     */
    getBillingStatusQuotes(params: { "body"?: QuoteSearchCriteria;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BillingStatusQuoteSearchResult> {
        const fetchArgs = QuoteApiFetchParamCreator.getBillingStatusQuotes(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Calculate rating variable from given freight and override
     * @param body 
     */
    getCalculatedRatingVariable(params: { "body"?: QuoteCalculateRatingVariableVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CalculateRatingVariableResult> {
        const fetchArgs = QuoteApiFetchParamCreator.getCalculatedRatingVariable(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     * @param quoteId 
     */
    getQuote(params: { "quoteId": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<QuoteResultVM> {
        const fetchArgs = QuoteApiFetchParamCreator.getQuote(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     * @param body 
     */
    saveBillToCustomerForQuote(params: { "body"?: QuoteBillToVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = QuoteApiFetchParamCreator.saveBillToCustomerForQuote(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     * @param body 
     */
    saveContactForQuote(params: { "body"?: QuoteContactVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Quote> {
        const fetchArgs = QuoteApiFetchParamCreator.saveContactForQuote(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * This is meant to be a temporary method
     * @param body 
     */
    saveFreightSerialNumbers(params: { "body"?: Array<QuoteFreight>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<QuoteFreight>> {
        const fetchArgs = QuoteApiFetchParamCreator.saveFreightSerialNumbers(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Create a quote or change an existing quote&#39;s status through the quote workflow
     * @param body 
     */
    saveQuote(params: { "body"?: QuoteChangeStatusVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<QuoteResultVM> {
        const fetchArgs = QuoteApiFetchParamCreator.saveQuote(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Updates the quote;s payment collected to true.
     * @param quoteId 
     */
    updateQuotePaymentCollected(params: { "quoteId": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = QuoteApiFetchParamCreator.updateQuotePaymentCollected(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * QuoteApi - object-oriented interface
 */
export class QuoteApi extends BaseAPI {
    /** 
     * 
     * @param body 
     */
    addAccessorialChargeValue(params: {  "body"?: AccessorialChargeValue; }, options?: any) {
        return QuoteApiFp.addAccessorialChargeValue(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Applies the layover expedited flags.
     * @param body The quote stops.
     */
    apiV1QuotesApplyLayoverExpeditedFlagsPost(params: {  "body"?: Array<QuoteStop>; }, options?: any) {
        return QuoteApiFp.apiV1QuotesApplyLayoverExpeditedFlagsPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     * @param body 
     */
    apiV1QuotesQuotesPost(params: {  "body"?: QuoteSearchCriteria; }, options?: any) {
        return QuoteApiFp.apiV1QuotesQuotesPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     * @param body 
     */
    apiV1QuotesSaveOtherFreightInfoPost(params: {  "body"?: OtherFreightInfosParams; }, options?: any) {
        return QuoteApiFp.apiV1QuotesSaveOtherFreightInfoPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Approve an accessorial charge value
     * @param id Id of the accessorial charge value to approve
     */
    approveAccessorialChargeValue(params: {  "id": number; }, options?: any) {
        return QuoteApiFp.approveAccessorialChargeValue(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Calculate a quote&#39;s current rate without saving to database
     * @param body 
     */
    calculateRate(params: {  "body"?: QuoteEditModeVM; }, options?: any) {
        return QuoteApiFp.calculateRate(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Cancel a quote
     * @param body 
     */
    cancelQuote(params: {  "body"?: CancelQuoteParams; }, options?: any) {
        return QuoteApiFp.cancelQuote(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Delete an accessorial charge value
     * @param id Id of the accessorial charge value to delete
     */
    deleteAccessorialChargeValue(params: {  "id": number; }, options?: any) {
        return QuoteApiFp.deleteAccessorialChargeValue(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Email a quote PDF
     * @param body 
     */
    emailQuotePDF(params: {  "body"?: QuoteEmailRequestVM; }, options?: any) {
        return QuoteApiFp.emailQuotePDF(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     * @param quoteId 
     */
    getAccessorialChargeValues(params: {  "quoteId": number; }, options?: any) {
        return QuoteApiFp.getAccessorialChargeValues(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Returns a list of accepted quotes with a billing status of cod only and a payment collected value of false
     * @param body 
     */
    getBillingStatusQuotes(params: {  "body"?: QuoteSearchCriteria; }, options?: any) {
        return QuoteApiFp.getBillingStatusQuotes(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Calculate rating variable from given freight and override
     * @param body 
     */
    getCalculatedRatingVariable(params: {  "body"?: QuoteCalculateRatingVariableVM; }, options?: any) {
        return QuoteApiFp.getCalculatedRatingVariable(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     * @param quoteId 
     */
    getQuote(params: {  "quoteId": number; }, options?: any) {
        return QuoteApiFp.getQuote(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     * @param body 
     */
    saveBillToCustomerForQuote(params: {  "body"?: QuoteBillToVM; }, options?: any) {
        return QuoteApiFp.saveBillToCustomerForQuote(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     * @param body 
     */
    saveContactForQuote(params: {  "body"?: QuoteContactVM; }, options?: any) {
        return QuoteApiFp.saveContactForQuote(params, options)(this.fetch, this.basePath);
    }
    /** 
     * This is meant to be a temporary method
     * @param body 
     */
    saveFreightSerialNumbers(params: {  "body"?: Array<QuoteFreight>; }, options?: any) {
        return QuoteApiFp.saveFreightSerialNumbers(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Create a quote or change an existing quote&#39;s status through the quote workflow
     * @param body 
     */
    saveQuote(params: {  "body"?: QuoteChangeStatusVM; }, options?: any) {
        return QuoteApiFp.saveQuote(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Updates the quote;s payment collected to true.
     * @param quoteId 
     */
    updateQuotePaymentCollected(params: {  "quoteId": number; }, options?: any) {
        return QuoteApiFp.updateQuotePaymentCollected(params, options)(this.fetch, this.basePath);
    }
};

/**
 * QuoteApi - factory interface
 */
export const QuoteApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * 
         * @param body 
         */
        addAccessorialChargeValue(params: {  "body"?: AccessorialChargeValue; }, options?: any) {
            return QuoteApiFp.addAccessorialChargeValue(params, options)(fetch, basePath);
        },
        /** 
         * Applies the layover expedited flags.
         * @param body The quote stops.
         */
        apiV1QuotesApplyLayoverExpeditedFlagsPost(params: {  "body"?: Array<QuoteStop>; }, options?: any) {
            return QuoteApiFp.apiV1QuotesApplyLayoverExpeditedFlagsPost(params, options)(fetch, basePath);
        },
        /** 
         * 
         * @param body 
         */
        apiV1QuotesQuotesPost(params: {  "body"?: QuoteSearchCriteria; }, options?: any) {
            return QuoteApiFp.apiV1QuotesQuotesPost(params, options)(fetch, basePath);
        },
        /** 
         * 
         * @param body 
         */
        apiV1QuotesSaveOtherFreightInfoPost(params: {  "body"?: OtherFreightInfosParams; }, options?: any) {
            return QuoteApiFp.apiV1QuotesSaveOtherFreightInfoPost(params, options)(fetch, basePath);
        },
        /** 
         * Approve an accessorial charge value
         * @param id Id of the accessorial charge value to approve
         */
        approveAccessorialChargeValue(params: {  "id": number; }, options?: any) {
            return QuoteApiFp.approveAccessorialChargeValue(params, options)(fetch, basePath);
        },
        /** 
         * Calculate a quote&#39;s current rate without saving to database
         * @param body 
         */
        calculateRate(params: {  "body"?: QuoteEditModeVM; }, options?: any) {
            return QuoteApiFp.calculateRate(params, options)(fetch, basePath);
        },
        /** 
         * Cancel a quote
         * @param body 
         */
        cancelQuote(params: {  "body"?: CancelQuoteParams; }, options?: any) {
            return QuoteApiFp.cancelQuote(params, options)(fetch, basePath);
        },
        /** 
         * Delete an accessorial charge value
         * @param id Id of the accessorial charge value to delete
         */
        deleteAccessorialChargeValue(params: {  "id": number; }, options?: any) {
            return QuoteApiFp.deleteAccessorialChargeValue(params, options)(fetch, basePath);
        },
        /** 
         * Email a quote PDF
         * @param body 
         */
        emailQuotePDF(params: {  "body"?: QuoteEmailRequestVM; }, options?: any) {
            return QuoteApiFp.emailQuotePDF(params, options)(fetch, basePath);
        },
        /** 
         * 
         * @param quoteId 
         */
        getAccessorialChargeValues(params: {  "quoteId": number; }, options?: any) {
            return QuoteApiFp.getAccessorialChargeValues(params, options)(fetch, basePath);
        },
        /** 
         * Returns a list of accepted quotes with a billing status of cod only and a payment collected value of false
         * @param body 
         */
        getBillingStatusQuotes(params: {  "body"?: QuoteSearchCriteria; }, options?: any) {
            return QuoteApiFp.getBillingStatusQuotes(params, options)(fetch, basePath);
        },
        /** 
         * Calculate rating variable from given freight and override
         * @param body 
         */
        getCalculatedRatingVariable(params: {  "body"?: QuoteCalculateRatingVariableVM; }, options?: any) {
            return QuoteApiFp.getCalculatedRatingVariable(params, options)(fetch, basePath);
        },
        /** 
         * 
         * @param quoteId 
         */
        getQuote(params: {  "quoteId": number; }, options?: any) {
            return QuoteApiFp.getQuote(params, options)(fetch, basePath);
        },
        /** 
         * 
         * @param body 
         */
        saveBillToCustomerForQuote(params: {  "body"?: QuoteBillToVM; }, options?: any) {
            return QuoteApiFp.saveBillToCustomerForQuote(params, options)(fetch, basePath);
        },
        /** 
         * 
         * @param body 
         */
        saveContactForQuote(params: {  "body"?: QuoteContactVM; }, options?: any) {
            return QuoteApiFp.saveContactForQuote(params, options)(fetch, basePath);
        },
        /** 
         * This is meant to be a temporary method
         * @param body 
         */
        saveFreightSerialNumbers(params: {  "body"?: Array<QuoteFreight>; }, options?: any) {
            return QuoteApiFp.saveFreightSerialNumbers(params, options)(fetch, basePath);
        },
        /** 
         * Create a quote or change an existing quote&#39;s status through the quote workflow
         * @param body 
         */
        saveQuote(params: {  "body"?: QuoteChangeStatusVM; }, options?: any) {
            return QuoteApiFp.saveQuote(params, options)(fetch, basePath);
        },
        /** 
         * Updates the quote;s payment collected to true.
         * @param quoteId 
         */
        updateQuotePaymentCollected(params: {  "quoteId": number; }, options?: any) {
            return QuoteApiFp.updateQuotePaymentCollected(params, options)(fetch, basePath);
        },
    };
};


/**
 * RateModelApi - fetch parameter creator
 */
export const RateModelApiFetchParamCreator = {
    /** 
     * Apply sandbox rates
     * @param body 
     */
    apiV1RateModelApplySandboxRatesPost(params: {  "body"?: Array<RateModel>; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/rateModel/applySandboxRates`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets a list of RateModel
     * @param companyId 
     */
    apiV1RateModelCompanyIdGet(params: {  "companyId": number; }, options?: any): FetchArgs {
        // verify required parameter "companyId" is set
        if (params["companyId"] == null) {
            throw new Error("Missing required parameter companyId when calling apiV1RateModelCompanyIdGet");
        }
        const baseUrl = `/api/v1/rateModel/{companyId}`
            .replace(`{${"companyId"}}`, `${ params["companyId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     * @param body 
     */
    apiV1RateModelGetLinearFootGridDataPost(params: {  "body"?: LinearFootRatesCalculationParams; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/rateModel/getLinearFootGridData`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * reset to default rates value
     * @param body 
     */
    apiV1RateModelResetToDefaultsPost(params: {  "body"?: Array<RateModel>; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/rateModel/resetToDefaults`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Updates a list of rate models&#39; default rates
     * @param body 
     */
    apiV1RateModelUpdateRateModelDefaultRatesPost(params: {  "body"?: Array<RateModel>; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/rateModel/updateRateModelDefaultRates`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * RateModelApi - functional programming interface
 */
export const RateModelApiFp = {
    /** 
     * Apply sandbox rates
     * @param body 
     */
    apiV1RateModelApplySandboxRatesPost(params: { "body"?: Array<RateModel>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<RateModel>> {
        const fetchArgs = RateModelApiFetchParamCreator.apiV1RateModelApplySandboxRatesPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets a list of RateModel
     * @param companyId 
     */
    apiV1RateModelCompanyIdGet(params: { "companyId": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<RateModel>> {
        const fetchArgs = RateModelApiFetchParamCreator.apiV1RateModelCompanyIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     * @param body 
     */
    apiV1RateModelGetLinearFootGridDataPost(params: { "body"?: LinearFootRatesCalculationParams;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<LinearFootCalculation>> {
        const fetchArgs = RateModelApiFetchParamCreator.apiV1RateModelGetLinearFootGridDataPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * reset to default rates value
     * @param body 
     */
    apiV1RateModelResetToDefaultsPost(params: { "body"?: Array<RateModel>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<RateModel>> {
        const fetchArgs = RateModelApiFetchParamCreator.apiV1RateModelResetToDefaultsPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Updates a list of rate models&#39; default rates
     * @param body 
     */
    apiV1RateModelUpdateRateModelDefaultRatesPost(params: { "body"?: Array<RateModel>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<RateModel>> {
        const fetchArgs = RateModelApiFetchParamCreator.apiV1RateModelUpdateRateModelDefaultRatesPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * RateModelApi - object-oriented interface
 */
export class RateModelApi extends BaseAPI {
    /** 
     * Apply sandbox rates
     * @param body 
     */
    apiV1RateModelApplySandboxRatesPost(params: {  "body"?: Array<RateModel>; }, options?: any) {
        return RateModelApiFp.apiV1RateModelApplySandboxRatesPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets a list of RateModel
     * @param companyId 
     */
    apiV1RateModelCompanyIdGet(params: {  "companyId": number; }, options?: any) {
        return RateModelApiFp.apiV1RateModelCompanyIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     * @param body 
     */
    apiV1RateModelGetLinearFootGridDataPost(params: {  "body"?: LinearFootRatesCalculationParams; }, options?: any) {
        return RateModelApiFp.apiV1RateModelGetLinearFootGridDataPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * reset to default rates value
     * @param body 
     */
    apiV1RateModelResetToDefaultsPost(params: {  "body"?: Array<RateModel>; }, options?: any) {
        return RateModelApiFp.apiV1RateModelResetToDefaultsPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Updates a list of rate models&#39; default rates
     * @param body 
     */
    apiV1RateModelUpdateRateModelDefaultRatesPost(params: {  "body"?: Array<RateModel>; }, options?: any) {
        return RateModelApiFp.apiV1RateModelUpdateRateModelDefaultRatesPost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * RateModelApi - factory interface
 */
export const RateModelApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Apply sandbox rates
         * @param body 
         */
        apiV1RateModelApplySandboxRatesPost(params: {  "body"?: Array<RateModel>; }, options?: any) {
            return RateModelApiFp.apiV1RateModelApplySandboxRatesPost(params, options)(fetch, basePath);
        },
        /** 
         * Gets a list of RateModel
         * @param companyId 
         */
        apiV1RateModelCompanyIdGet(params: {  "companyId": number; }, options?: any) {
            return RateModelApiFp.apiV1RateModelCompanyIdGet(params, options)(fetch, basePath);
        },
        /** 
         * 
         * @param body 
         */
        apiV1RateModelGetLinearFootGridDataPost(params: {  "body"?: LinearFootRatesCalculationParams; }, options?: any) {
            return RateModelApiFp.apiV1RateModelGetLinearFootGridDataPost(params, options)(fetch, basePath);
        },
        /** 
         * reset to default rates value
         * @param body 
         */
        apiV1RateModelResetToDefaultsPost(params: {  "body"?: Array<RateModel>; }, options?: any) {
            return RateModelApiFp.apiV1RateModelResetToDefaultsPost(params, options)(fetch, basePath);
        },
        /** 
         * Updates a list of rate models&#39; default rates
         * @param body 
         */
        apiV1RateModelUpdateRateModelDefaultRatesPost(params: {  "body"?: Array<RateModel>; }, options?: any) {
            return RateModelApiFp.apiV1RateModelUpdateRateModelDefaultRatesPost(params, options)(fetch, basePath);
        },
    };
};


/**
 * RateModelTestApi - fetch parameter creator
 */
export const RateModelTestApiFetchParamCreator = {
    /** 
     * 
     * @param origin 
     * @param destination 
     * @param companyId 
     */
    apiV1RateModelTestDatRateOriginDestinationCompanyIdGet(params: {  "origin": string; "destination": string; "companyId": number; }, options?: any): FetchArgs {
        // verify required parameter "origin" is set
        if (params["origin"] == null) {
            throw new Error("Missing required parameter origin when calling apiV1RateModelTestDatRateOriginDestinationCompanyIdGet");
        }
        // verify required parameter "destination" is set
        if (params["destination"] == null) {
            throw new Error("Missing required parameter destination when calling apiV1RateModelTestDatRateOriginDestinationCompanyIdGet");
        }
        // verify required parameter "companyId" is set
        if (params["companyId"] == null) {
            throw new Error("Missing required parameter companyId when calling apiV1RateModelTestDatRateOriginDestinationCompanyIdGet");
        }
        const baseUrl = `/api/v1/RateModelTest/datRate/{origin}/{destination}/{companyId}`
            .replace(`{${"origin"}}`, `${ params["origin"] }`)
            .replace(`{${"destination"}}`, `${ params["destination"] }`)
            .replace(`{${"companyId"}}`, `${ params["companyId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Delete a specific rateModelTest.
     * @param body The delete rateModelTest.
     */
    apiV1RateModelTestDelete(params: {  "body"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/RateModelTest`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "DELETE" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets a list of rateModelTest
     * @param body 
     */
    apiV1RateModelTestGetAllRateModelTestsPost(params: {  "body"?: RateModelTestRequestVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/RateModelTest/getAllRateModelTests`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Creates a new rateModelTest
     * @param body The rateModelTest.
     */
    apiV1RateModelTestPost(params: {  "body"?: RateModelTest; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/RateModelTest`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Updates a specific rateModelTest.
     * @param body The update rateModelTest.
     */
    apiV1RateModelTestPut(params: {  "body"?: RateModelTest; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/RateModelTest`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     * @param body 
     */
    apiV1RateModelTestQuickRateModelTestPost(params: {  "body"?: QuickRateModelTestRequest; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/RateModelTest/QuickRateModelTest`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * update all rate model tests&#39; miles
     */
    apiV1RateModelTestUpdateAllTestCasesMilesGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/RateModelTest/updateAllTestCasesMiles`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * RateModelTestApi - functional programming interface
 */
export const RateModelTestApiFp = {
    /** 
     * 
     * @param origin 
     * @param destination 
     * @param companyId 
     */
    apiV1RateModelTestDatRateOriginDestinationCompanyIdGet(params: { "origin": string; "destination": string; "companyId": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DatRate> {
        const fetchArgs = RateModelTestApiFetchParamCreator.apiV1RateModelTestDatRateOriginDestinationCompanyIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Delete a specific rateModelTest.
     * @param body The delete rateModelTest.
     */
    apiV1RateModelTestDelete(params: { "body"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
        const fetchArgs = RateModelTestApiFetchParamCreator.apiV1RateModelTestDelete(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets a list of rateModelTest
     * @param body 
     */
    apiV1RateModelTestGetAllRateModelTestsPost(params: { "body"?: RateModelTestRequestVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<RateModelTest>> {
        const fetchArgs = RateModelTestApiFetchParamCreator.apiV1RateModelTestGetAllRateModelTestsPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Creates a new rateModelTest
     * @param body The rateModelTest.
     */
    apiV1RateModelTestPost(params: { "body"?: RateModelTest;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RateModelTest> {
        const fetchArgs = RateModelTestApiFetchParamCreator.apiV1RateModelTestPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Updates a specific rateModelTest.
     * @param body The update rateModelTest.
     */
    apiV1RateModelTestPut(params: { "body"?: RateModelTest;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RateModelTest> {
        const fetchArgs = RateModelTestApiFetchParamCreator.apiV1RateModelTestPut(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     * @param body 
     */
    apiV1RateModelTestQuickRateModelTestPost(params: { "body"?: QuickRateModelTestRequest;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<QuickRateModelTestResult> {
        const fetchArgs = RateModelTestApiFetchParamCreator.apiV1RateModelTestQuickRateModelTestPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * update all rate model tests&#39; miles
     */
    apiV1RateModelTestUpdateAllTestCasesMilesGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = RateModelTestApiFetchParamCreator.apiV1RateModelTestUpdateAllTestCasesMilesGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * RateModelTestApi - object-oriented interface
 */
export class RateModelTestApi extends BaseAPI {
    /** 
     * 
     * @param origin 
     * @param destination 
     * @param companyId 
     */
    apiV1RateModelTestDatRateOriginDestinationCompanyIdGet(params: {  "origin": string; "destination": string; "companyId": number; }, options?: any) {
        return RateModelTestApiFp.apiV1RateModelTestDatRateOriginDestinationCompanyIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Delete a specific rateModelTest.
     * @param body The delete rateModelTest.
     */
    apiV1RateModelTestDelete(params: {  "body"?: number; }, options?: any) {
        return RateModelTestApiFp.apiV1RateModelTestDelete(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets a list of rateModelTest
     * @param body 
     */
    apiV1RateModelTestGetAllRateModelTestsPost(params: {  "body"?: RateModelTestRequestVM; }, options?: any) {
        return RateModelTestApiFp.apiV1RateModelTestGetAllRateModelTestsPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Creates a new rateModelTest
     * @param body The rateModelTest.
     */
    apiV1RateModelTestPost(params: {  "body"?: RateModelTest; }, options?: any) {
        return RateModelTestApiFp.apiV1RateModelTestPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Updates a specific rateModelTest.
     * @param body The update rateModelTest.
     */
    apiV1RateModelTestPut(params: {  "body"?: RateModelTest; }, options?: any) {
        return RateModelTestApiFp.apiV1RateModelTestPut(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     * @param body 
     */
    apiV1RateModelTestQuickRateModelTestPost(params: {  "body"?: QuickRateModelTestRequest; }, options?: any) {
        return RateModelTestApiFp.apiV1RateModelTestQuickRateModelTestPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * update all rate model tests&#39; miles
     */
    apiV1RateModelTestUpdateAllTestCasesMilesGet(options?: any) {
        return RateModelTestApiFp.apiV1RateModelTestUpdateAllTestCasesMilesGet(options)(this.fetch, this.basePath);
    }
};

/**
 * RateModelTestApi - factory interface
 */
export const RateModelTestApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * 
         * @param origin 
         * @param destination 
         * @param companyId 
         */
        apiV1RateModelTestDatRateOriginDestinationCompanyIdGet(params: {  "origin": string; "destination": string; "companyId": number; }, options?: any) {
            return RateModelTestApiFp.apiV1RateModelTestDatRateOriginDestinationCompanyIdGet(params, options)(fetch, basePath);
        },
        /** 
         * Delete a specific rateModelTest.
         * @param body The delete rateModelTest.
         */
        apiV1RateModelTestDelete(params: {  "body"?: number; }, options?: any) {
            return RateModelTestApiFp.apiV1RateModelTestDelete(params, options)(fetch, basePath);
        },
        /** 
         * Gets a list of rateModelTest
         * @param body 
         */
        apiV1RateModelTestGetAllRateModelTestsPost(params: {  "body"?: RateModelTestRequestVM; }, options?: any) {
            return RateModelTestApiFp.apiV1RateModelTestGetAllRateModelTestsPost(params, options)(fetch, basePath);
        },
        /** 
         * Creates a new rateModelTest
         * @param body The rateModelTest.
         */
        apiV1RateModelTestPost(params: {  "body"?: RateModelTest; }, options?: any) {
            return RateModelTestApiFp.apiV1RateModelTestPost(params, options)(fetch, basePath);
        },
        /** 
         * Updates a specific rateModelTest.
         * @param body The update rateModelTest.
         */
        apiV1RateModelTestPut(params: {  "body"?: RateModelTest; }, options?: any) {
            return RateModelTestApiFp.apiV1RateModelTestPut(params, options)(fetch, basePath);
        },
        /** 
         * 
         * @param body 
         */
        apiV1RateModelTestQuickRateModelTestPost(params: {  "body"?: QuickRateModelTestRequest; }, options?: any) {
            return RateModelTestApiFp.apiV1RateModelTestQuickRateModelTestPost(params, options)(fetch, basePath);
        },
        /** 
         * update all rate model tests&#39; miles
         */
        apiV1RateModelTestUpdateAllTestCasesMilesGet(options?: any) {
            return RateModelTestApiFp.apiV1RateModelTestUpdateAllTestCasesMilesGet(options)(fetch, basePath);
        },
    };
};


/**
 * RegionApi - fetch parameter creator
 */
export const RegionApiFetchParamCreator = {
    /** 
     * Gets a list of Regions
     */
    apiV1RegionGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/region`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * RegionApi - functional programming interface
 */
export const RegionApiFp = {
    /** 
     * Gets a list of Regions
     */
    apiV1RegionGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Region>> {
        const fetchArgs = RegionApiFetchParamCreator.apiV1RegionGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * RegionApi - object-oriented interface
 */
export class RegionApi extends BaseAPI {
    /** 
     * Gets a list of Regions
     */
    apiV1RegionGet(options?: any) {
        return RegionApiFp.apiV1RegionGet(options)(this.fetch, this.basePath);
    }
};

/**
 * RegionApi - factory interface
 */
export const RegionApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Gets a list of Regions
         */
        apiV1RegionGet(options?: any) {
            return RegionApiFp.apiV1RegionGet(options)(fetch, basePath);
        },
    };
};


/**
 * ReminderApi - fetch parameter creator
 */
export const ReminderApiFetchParamCreator = {
    /** 
     * Mark a reminder as complete
     * @param id 
     */
    apiV1ReminderIdCompletePost(params: {  "id": number; }, options?: any): FetchArgs {
        // verify required parameter "id" is set
        if (params["id"] == null) {
            throw new Error("Missing required parameter id when calling apiV1ReminderIdCompletePost");
        }
        const baseUrl = `/api/v1/reminder/{id}/complete`
            .replace(`{${"id"}}`, `${ params["id"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Delete a reminder
     * @param id 
     */
    apiV1ReminderIdDelete(params: {  "id": number; }, options?: any): FetchArgs {
        // verify required parameter "id" is set
        if (params["id"] == null) {
            throw new Error("Missing required parameter id when calling apiV1ReminderIdDelete");
        }
        const baseUrl = `/api/v1/reminder/{id}`
            .replace(`{${"id"}}`, `${ params["id"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "DELETE" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get a reminder by its ID
     * @param id 
     */
    apiV1ReminderIdGet(params: {  "id": number; }, options?: any): FetchArgs {
        // verify required parameter "id" is set
        if (params["id"] == null) {
            throw new Error("Missing required parameter id when calling apiV1ReminderIdGet");
        }
        const baseUrl = `/api/v1/reminder/{id}`
            .replace(`{${"id"}}`, `${ params["id"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Update a reminder
     * @param id 
     * @param body 
     */
    apiV1ReminderIdPut(params: {  "id": number; "body"?: Reminder; }, options?: any): FetchArgs {
        // verify required parameter "id" is set
        if (params["id"] == null) {
            throw new Error("Missing required parameter id when calling apiV1ReminderIdPut");
        }
        const baseUrl = `/api/v1/reminder/{id}`
            .replace(`{${"id"}}`, `${ params["id"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Create a reminder
     * @param body 
     */
    apiV1ReminderPost(params: {  "body"?: Reminder; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/reminder`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get all reminders for the logged-in user matching the given criteria
     * @param body 
     */
    apiV1ReminderSearchPost(params: {  "body"?: ReminderSearchCriteria; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/reminder/search`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ReminderApi - functional programming interface
 */
export const ReminderApiFp = {
    /** 
     * Mark a reminder as complete
     * @param id 
     */
    apiV1ReminderIdCompletePost(params: { "id": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = ReminderApiFetchParamCreator.apiV1ReminderIdCompletePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Delete a reminder
     * @param id 
     */
    apiV1ReminderIdDelete(params: { "id": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = ReminderApiFetchParamCreator.apiV1ReminderIdDelete(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get a reminder by its ID
     * @param id 
     */
    apiV1ReminderIdGet(params: { "id": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Reminder> {
        const fetchArgs = ReminderApiFetchParamCreator.apiV1ReminderIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Update a reminder
     * @param id 
     * @param body 
     */
    apiV1ReminderIdPut(params: { "id": number; "body"?: Reminder;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Reminder> {
        const fetchArgs = ReminderApiFetchParamCreator.apiV1ReminderIdPut(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Create a reminder
     * @param body 
     */
    apiV1ReminderPost(params: { "body"?: Reminder;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Reminder> {
        const fetchArgs = ReminderApiFetchParamCreator.apiV1ReminderPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get all reminders for the logged-in user matching the given criteria
     * @param body 
     */
    apiV1ReminderSearchPost(params: { "body"?: ReminderSearchCriteria;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Reminder>> {
        const fetchArgs = ReminderApiFetchParamCreator.apiV1ReminderSearchPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ReminderApi - object-oriented interface
 */
export class ReminderApi extends BaseAPI {
    /** 
     * Mark a reminder as complete
     * @param id 
     */
    apiV1ReminderIdCompletePost(params: {  "id": number; }, options?: any) {
        return ReminderApiFp.apiV1ReminderIdCompletePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Delete a reminder
     * @param id 
     */
    apiV1ReminderIdDelete(params: {  "id": number; }, options?: any) {
        return ReminderApiFp.apiV1ReminderIdDelete(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get a reminder by its ID
     * @param id 
     */
    apiV1ReminderIdGet(params: {  "id": number; }, options?: any) {
        return ReminderApiFp.apiV1ReminderIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Update a reminder
     * @param id 
     * @param body 
     */
    apiV1ReminderIdPut(params: {  "id": number; "body"?: Reminder; }, options?: any) {
        return ReminderApiFp.apiV1ReminderIdPut(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Create a reminder
     * @param body 
     */
    apiV1ReminderPost(params: {  "body"?: Reminder; }, options?: any) {
        return ReminderApiFp.apiV1ReminderPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get all reminders for the logged-in user matching the given criteria
     * @param body 
     */
    apiV1ReminderSearchPost(params: {  "body"?: ReminderSearchCriteria; }, options?: any) {
        return ReminderApiFp.apiV1ReminderSearchPost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * ReminderApi - factory interface
 */
export const ReminderApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Mark a reminder as complete
         * @param id 
         */
        apiV1ReminderIdCompletePost(params: {  "id": number; }, options?: any) {
            return ReminderApiFp.apiV1ReminderIdCompletePost(params, options)(fetch, basePath);
        },
        /** 
         * Delete a reminder
         * @param id 
         */
        apiV1ReminderIdDelete(params: {  "id": number; }, options?: any) {
            return ReminderApiFp.apiV1ReminderIdDelete(params, options)(fetch, basePath);
        },
        /** 
         * Get a reminder by its ID
         * @param id 
         */
        apiV1ReminderIdGet(params: {  "id": number; }, options?: any) {
            return ReminderApiFp.apiV1ReminderIdGet(params, options)(fetch, basePath);
        },
        /** 
         * Update a reminder
         * @param id 
         * @param body 
         */
        apiV1ReminderIdPut(params: {  "id": number; "body"?: Reminder; }, options?: any) {
            return ReminderApiFp.apiV1ReminderIdPut(params, options)(fetch, basePath);
        },
        /** 
         * Create a reminder
         * @param body 
         */
        apiV1ReminderPost(params: {  "body"?: Reminder; }, options?: any) {
            return ReminderApiFp.apiV1ReminderPost(params, options)(fetch, basePath);
        },
        /** 
         * Get all reminders for the logged-in user matching the given criteria
         * @param body 
         */
        apiV1ReminderSearchPost(params: {  "body"?: ReminderSearchCriteria; }, options?: any) {
            return ReminderApiFp.apiV1ReminderSearchPost(params, options)(fetch, basePath);
        },
    };
};


/**
 * SalesRepHomeApi - fetch parameter creator
 */
export const SalesRepHomeApiFetchParamCreator = {
    /** 
     * Returns booked quotes data, by week, for year-to-date (and equivalent for previous year)
     */
    getMyBookedQuotes(options?: any): FetchArgs {
        const baseUrl = `/api/v1/salesRepHome/myBookedQuotes`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * The Quotes array on the returned customers will contain at most one quote,  which is the most recent quote, by QuoteDate, created by the current user  and with a status of Accepted
     * @param body 
     */
    getMyCustomers(params: {  "body"?: SalesRepCustomerSearchCriteria; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/salesRepHome/myCustomers`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Returns KPI metrics for currently logged -in user
     * @param body 
     */
    getMyKPIMetrics(params: {  "body"?: BookedSalesParametersVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/salesRepHome/myKPIMetrics`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get sales opportunities for the current user
     */
    getMyOpportunities(options?: any): FetchArgs {
        const baseUrl = `/api/v1/salesRepHome/myOpportunities`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     * @param body 
     */
    getMyQuotes(params: {  "body"?: QuoteSearchCriteria; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/salesRepHome/myQuotes`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Returns sales totals for current day
     */
    getSalesLeaderboard(options?: any): FetchArgs {
        const baseUrl = `/api/v1/salesRepHome/salesLeaderboard`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets Id/Name pairs of all customers that have at least one CustomerQuote
     * @param companyId 
     */
    getSimplifiedCustomers(params: {  "companyId": number; }, options?: any): FetchArgs {
        // verify required parameter "companyId" is set
        if (params["companyId"] == null) {
            throw new Error("Missing required parameter companyId when calling getSimplifiedCustomers");
        }
        const baseUrl = `/api/v1/salesRepHome/simplifiedCustomers/{companyId}`
            .replace(`{${"companyId"}}`, `${ params["companyId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * SalesRepHomeApi - functional programming interface
 */
export const SalesRepHomeApiFp = {
    /** 
     * Returns booked quotes data, by week, for year-to-date (and equivalent for previous year)
     */
    getMyBookedQuotes(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<UserMetricsBookedQuotesView>> {
        const fetchArgs = SalesRepHomeApiFetchParamCreator.getMyBookedQuotes(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * The Quotes array on the returned customers will contain at most one quote,  which is the most recent quote, by QuoteDate, created by the current user  and with a status of Accepted
     * @param body 
     */
    getMyCustomers(params: { "body"?: SalesRepCustomerSearchCriteria;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Customer>> {
        const fetchArgs = SalesRepHomeApiFetchParamCreator.getMyCustomers(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Returns KPI metrics for currently logged -in user
     * @param body 
     */
    getMyKPIMetrics(params: { "body"?: BookedSalesParametersVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserMetricsKPIView> {
        const fetchArgs = SalesRepHomeApiFetchParamCreator.getMyKPIMetrics(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get sales opportunities for the current user
     */
    getMyOpportunities(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Opportunity>> {
        const fetchArgs = SalesRepHomeApiFetchParamCreator.getMyOpportunities(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     * @param body 
     */
    getMyQuotes(params: { "body"?: QuoteSearchCriteria;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SimplifiedQuoteSearchResult> {
        const fetchArgs = SalesRepHomeApiFetchParamCreator.getMyQuotes(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Returns sales totals for current day
     */
    getSalesLeaderboard(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<UserMetricsSalesLeaderboardView>> {
        const fetchArgs = SalesRepHomeApiFetchParamCreator.getSalesLeaderboard(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets Id/Name pairs of all customers that have at least one CustomerQuote
     * @param companyId 
     */
    getSimplifiedCustomers(params: { "companyId": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SimplifiedCustomer>> {
        const fetchArgs = SalesRepHomeApiFetchParamCreator.getSimplifiedCustomers(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * SalesRepHomeApi - object-oriented interface
 */
export class SalesRepHomeApi extends BaseAPI {
    /** 
     * Returns booked quotes data, by week, for year-to-date (and equivalent for previous year)
     */
    getMyBookedQuotes(options?: any) {
        return SalesRepHomeApiFp.getMyBookedQuotes(options)(this.fetch, this.basePath);
    }
    /** 
     * The Quotes array on the returned customers will contain at most one quote,  which is the most recent quote, by QuoteDate, created by the current user  and with a status of Accepted
     * @param body 
     */
    getMyCustomers(params: {  "body"?: SalesRepCustomerSearchCriteria; }, options?: any) {
        return SalesRepHomeApiFp.getMyCustomers(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Returns KPI metrics for currently logged -in user
     * @param body 
     */
    getMyKPIMetrics(params: {  "body"?: BookedSalesParametersVM; }, options?: any) {
        return SalesRepHomeApiFp.getMyKPIMetrics(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get sales opportunities for the current user
     */
    getMyOpportunities(options?: any) {
        return SalesRepHomeApiFp.getMyOpportunities(options)(this.fetch, this.basePath);
    }
    /** 
     * 
     * @param body 
     */
    getMyQuotes(params: {  "body"?: QuoteSearchCriteria; }, options?: any) {
        return SalesRepHomeApiFp.getMyQuotes(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Returns sales totals for current day
     */
    getSalesLeaderboard(options?: any) {
        return SalesRepHomeApiFp.getSalesLeaderboard(options)(this.fetch, this.basePath);
    }
    /** 
     * Gets Id/Name pairs of all customers that have at least one CustomerQuote
     * @param companyId 
     */
    getSimplifiedCustomers(params: {  "companyId": number; }, options?: any) {
        return SalesRepHomeApiFp.getSimplifiedCustomers(params, options)(this.fetch, this.basePath);
    }
};

/**
 * SalesRepHomeApi - factory interface
 */
export const SalesRepHomeApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Returns booked quotes data, by week, for year-to-date (and equivalent for previous year)
         */
        getMyBookedQuotes(options?: any) {
            return SalesRepHomeApiFp.getMyBookedQuotes(options)(fetch, basePath);
        },
        /** 
         * The Quotes array on the returned customers will contain at most one quote,  which is the most recent quote, by QuoteDate, created by the current user  and with a status of Accepted
         * @param body 
         */
        getMyCustomers(params: {  "body"?: SalesRepCustomerSearchCriteria; }, options?: any) {
            return SalesRepHomeApiFp.getMyCustomers(params, options)(fetch, basePath);
        },
        /** 
         * Returns KPI metrics for currently logged -in user
         * @param body 
         */
        getMyKPIMetrics(params: {  "body"?: BookedSalesParametersVM; }, options?: any) {
            return SalesRepHomeApiFp.getMyKPIMetrics(params, options)(fetch, basePath);
        },
        /** 
         * Get sales opportunities for the current user
         */
        getMyOpportunities(options?: any) {
            return SalesRepHomeApiFp.getMyOpportunities(options)(fetch, basePath);
        },
        /** 
         * 
         * @param body 
         */
        getMyQuotes(params: {  "body"?: QuoteSearchCriteria; }, options?: any) {
            return SalesRepHomeApiFp.getMyQuotes(params, options)(fetch, basePath);
        },
        /** 
         * Returns sales totals for current day
         */
        getSalesLeaderboard(options?: any) {
            return SalesRepHomeApiFp.getSalesLeaderboard(options)(fetch, basePath);
        },
        /** 
         * Gets Id/Name pairs of all customers that have at least one CustomerQuote
         * @param companyId 
         */
        getSimplifiedCustomers(params: {  "companyId": number; }, options?: any) {
            return SalesRepHomeApiFp.getSimplifiedCustomers(params, options)(fetch, basePath);
        },
    };
};


/**
 * TarpApi - fetch parameter creator
 */
export const TarpApiFetchParamCreator = {
    /** 
     * Creates a new Tarp and Tarp Rate(s)
     * @param body The tarp with tarp rate value.
     */
    apiV1TarpAddTarpPost(params: {  "body"?: Tarp; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/tarp/AddTarp`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets a list of tarp rates
     * @param companyId 
     * @param showHistory 
     */
    apiV1TarpCompanyIdShowHistoryGet(params: {  "companyId": number; "showHistory": boolean; }, options?: any): FetchArgs {
        // verify required parameter "companyId" is set
        if (params["companyId"] == null) {
            throw new Error("Missing required parameter companyId when calling apiV1TarpCompanyIdShowHistoryGet");
        }
        // verify required parameter "showHistory" is set
        if (params["showHistory"] == null) {
            throw new Error("Missing required parameter showHistory when calling apiV1TarpCompanyIdShowHistoryGet");
        }
        const baseUrl = `/api/v1/tarp/{companyId}/{showHistory}`
            .replace(`{${"companyId"}}`, `${ params["companyId"] }`)
            .replace(`{${"showHistory"}}`, `${ params["showHistory"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets a list of active tarps
     * @param companyId 
     */
    apiV1TarpGetAllActiveTarpsGet(params: {  "companyId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/tarp/getAllActiveTarps`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "companyId": params["companyId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets a list of tarps
     * @param companyId 
     * @param activeOnly 
     */
    apiV1TarpGetAllTarpsCompanyIdActiveOnlyGet(params: {  "companyId": number; "activeOnly": boolean; }, options?: any): FetchArgs {
        // verify required parameter "companyId" is set
        if (params["companyId"] == null) {
            throw new Error("Missing required parameter companyId when calling apiV1TarpGetAllTarpsCompanyIdActiveOnlyGet");
        }
        // verify required parameter "activeOnly" is set
        if (params["activeOnly"] == null) {
            throw new Error("Missing required parameter activeOnly when calling apiV1TarpGetAllTarpsCompanyIdActiveOnlyGet");
        }
        const baseUrl = `/api/v1/tarp/getAllTarps/{companyId}/{activeOnly}`
            .replace(`{${"companyId"}}`, `${ params["companyId"] }`)
            .replace(`{${"activeOnly"}}`, `${ params["activeOnly"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Creates a new Tarp Rate
     * @param body The tarp rate value.
     */
    apiV1TarpPost(params: {  "body"?: TarpValue; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/tarp`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Updates a specific Tarp Rate
     * @param body The tarp rate value.
     */
    apiV1TarpPut(params: {  "body"?: TarpValue; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/tarp`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Revert a tarp rate to its previous value
     * @param body 
     */
    apiV1TarpUndoPost(params: {  "body"?: TarpValue; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/tarp/undo`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Updates a specific Tarp
     * @param body The tarp value.
     */
    apiV1TarpUpdateTarpPut(params: {  "body"?: Tarp; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/tarp/updateTarp`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Updates multiple Tarp Rates on a tarp
     * @param body The tarp value.
     */
    apiV1TarpUpdateTarpRatesPut(params: {  "body"?: Tarp; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/tarp/UpdateTarpRates`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * TarpApi - functional programming interface
 */
export const TarpApiFp = {
    /** 
     * Creates a new Tarp and Tarp Rate(s)
     * @param body The tarp with tarp rate value.
     */
    apiV1TarpAddTarpPost(params: { "body"?: Tarp;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Tarp> {
        const fetchArgs = TarpApiFetchParamCreator.apiV1TarpAddTarpPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets a list of tarp rates
     * @param companyId 
     * @param showHistory 
     */
    apiV1TarpCompanyIdShowHistoryGet(params: { "companyId": number; "showHistory": boolean;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<TarpValue>> {
        const fetchArgs = TarpApiFetchParamCreator.apiV1TarpCompanyIdShowHistoryGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets a list of active tarps
     * @param companyId 
     */
    apiV1TarpGetAllActiveTarpsGet(params: { "companyId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Tarp>> {
        const fetchArgs = TarpApiFetchParamCreator.apiV1TarpGetAllActiveTarpsGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets a list of tarps
     * @param companyId 
     * @param activeOnly 
     */
    apiV1TarpGetAllTarpsCompanyIdActiveOnlyGet(params: { "companyId": number; "activeOnly": boolean;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Tarp>> {
        const fetchArgs = TarpApiFetchParamCreator.apiV1TarpGetAllTarpsCompanyIdActiveOnlyGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Creates a new Tarp Rate
     * @param body The tarp rate value.
     */
    apiV1TarpPost(params: { "body"?: TarpValue;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TarpValue> {
        const fetchArgs = TarpApiFetchParamCreator.apiV1TarpPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Updates a specific Tarp Rate
     * @param body The tarp rate value.
     */
    apiV1TarpPut(params: { "body"?: TarpValue;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TarpValue> {
        const fetchArgs = TarpApiFetchParamCreator.apiV1TarpPut(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Revert a tarp rate to its previous value
     * @param body 
     */
    apiV1TarpUndoPost(params: { "body"?: TarpValue;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
        const fetchArgs = TarpApiFetchParamCreator.apiV1TarpUndoPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Updates a specific Tarp
     * @param body The tarp value.
     */
    apiV1TarpUpdateTarpPut(params: { "body"?: Tarp;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Tarp> {
        const fetchArgs = TarpApiFetchParamCreator.apiV1TarpUpdateTarpPut(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Updates multiple Tarp Rates on a tarp
     * @param body The tarp value.
     */
    apiV1TarpUpdateTarpRatesPut(params: { "body"?: Tarp;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Tarp> {
        const fetchArgs = TarpApiFetchParamCreator.apiV1TarpUpdateTarpRatesPut(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * TarpApi - object-oriented interface
 */
export class TarpApi extends BaseAPI {
    /** 
     * Creates a new Tarp and Tarp Rate(s)
     * @param body The tarp with tarp rate value.
     */
    apiV1TarpAddTarpPost(params: {  "body"?: Tarp; }, options?: any) {
        return TarpApiFp.apiV1TarpAddTarpPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets a list of tarp rates
     * @param companyId 
     * @param showHistory 
     */
    apiV1TarpCompanyIdShowHistoryGet(params: {  "companyId": number; "showHistory": boolean; }, options?: any) {
        return TarpApiFp.apiV1TarpCompanyIdShowHistoryGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets a list of active tarps
     * @param companyId 
     */
    apiV1TarpGetAllActiveTarpsGet(params: {  "companyId"?: number; }, options?: any) {
        return TarpApiFp.apiV1TarpGetAllActiveTarpsGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets a list of tarps
     * @param companyId 
     * @param activeOnly 
     */
    apiV1TarpGetAllTarpsCompanyIdActiveOnlyGet(params: {  "companyId": number; "activeOnly": boolean; }, options?: any) {
        return TarpApiFp.apiV1TarpGetAllTarpsCompanyIdActiveOnlyGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Creates a new Tarp Rate
     * @param body The tarp rate value.
     */
    apiV1TarpPost(params: {  "body"?: TarpValue; }, options?: any) {
        return TarpApiFp.apiV1TarpPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Updates a specific Tarp Rate
     * @param body The tarp rate value.
     */
    apiV1TarpPut(params: {  "body"?: TarpValue; }, options?: any) {
        return TarpApiFp.apiV1TarpPut(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Revert a tarp rate to its previous value
     * @param body 
     */
    apiV1TarpUndoPost(params: {  "body"?: TarpValue; }, options?: any) {
        return TarpApiFp.apiV1TarpUndoPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Updates a specific Tarp
     * @param body The tarp value.
     */
    apiV1TarpUpdateTarpPut(params: {  "body"?: Tarp; }, options?: any) {
        return TarpApiFp.apiV1TarpUpdateTarpPut(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Updates multiple Tarp Rates on a tarp
     * @param body The tarp value.
     */
    apiV1TarpUpdateTarpRatesPut(params: {  "body"?: Tarp; }, options?: any) {
        return TarpApiFp.apiV1TarpUpdateTarpRatesPut(params, options)(this.fetch, this.basePath);
    }
};

/**
 * TarpApi - factory interface
 */
export const TarpApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Creates a new Tarp and Tarp Rate(s)
         * @param body The tarp with tarp rate value.
         */
        apiV1TarpAddTarpPost(params: {  "body"?: Tarp; }, options?: any) {
            return TarpApiFp.apiV1TarpAddTarpPost(params, options)(fetch, basePath);
        },
        /** 
         * Gets a list of tarp rates
         * @param companyId 
         * @param showHistory 
         */
        apiV1TarpCompanyIdShowHistoryGet(params: {  "companyId": number; "showHistory": boolean; }, options?: any) {
            return TarpApiFp.apiV1TarpCompanyIdShowHistoryGet(params, options)(fetch, basePath);
        },
        /** 
         * Gets a list of active tarps
         * @param companyId 
         */
        apiV1TarpGetAllActiveTarpsGet(params: {  "companyId"?: number; }, options?: any) {
            return TarpApiFp.apiV1TarpGetAllActiveTarpsGet(params, options)(fetch, basePath);
        },
        /** 
         * Gets a list of tarps
         * @param companyId 
         * @param activeOnly 
         */
        apiV1TarpGetAllTarpsCompanyIdActiveOnlyGet(params: {  "companyId": number; "activeOnly": boolean; }, options?: any) {
            return TarpApiFp.apiV1TarpGetAllTarpsCompanyIdActiveOnlyGet(params, options)(fetch, basePath);
        },
        /** 
         * Creates a new Tarp Rate
         * @param body The tarp rate value.
         */
        apiV1TarpPost(params: {  "body"?: TarpValue; }, options?: any) {
            return TarpApiFp.apiV1TarpPost(params, options)(fetch, basePath);
        },
        /** 
         * Updates a specific Tarp Rate
         * @param body The tarp rate value.
         */
        apiV1TarpPut(params: {  "body"?: TarpValue; }, options?: any) {
            return TarpApiFp.apiV1TarpPut(params, options)(fetch, basePath);
        },
        /** 
         * Revert a tarp rate to its previous value
         * @param body 
         */
        apiV1TarpUndoPost(params: {  "body"?: TarpValue; }, options?: any) {
            return TarpApiFp.apiV1TarpUndoPost(params, options)(fetch, basePath);
        },
        /** 
         * Updates a specific Tarp
         * @param body The tarp value.
         */
        apiV1TarpUpdateTarpPut(params: {  "body"?: Tarp; }, options?: any) {
            return TarpApiFp.apiV1TarpUpdateTarpPut(params, options)(fetch, basePath);
        },
        /** 
         * Updates multiple Tarp Rates on a tarp
         * @param body The tarp value.
         */
        apiV1TarpUpdateTarpRatesPut(params: {  "body"?: Tarp; }, options?: any) {
            return TarpApiFp.apiV1TarpUpdateTarpRatesPut(params, options)(fetch, basePath);
        },
    };
};


/**
 * TerminalsApi - fetch parameter creator
 */
export const TerminalsApiFetchParamCreator = {
    /** 
     * Deletes a terminal
     * @param terminalId 
     */
    apiV1TerminalsDeleteTerminalDelete(params: {  "terminalId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/terminals/deleteTerminal`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "terminalId": params["terminalId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "DELETE" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets a list of terminals
     */
    apiV1TerminalsGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/terminals`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Adds a new terminal
     * @param body 
     */
    apiV1TerminalsNewTerminalPut(params: {  "body"?: Terminal; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/terminals/newTerminal`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets a list of terminal regions that has a matching region Id in the passed array
     * @param body 
     */
    apiV1TerminalsTerminalRegionsByRegionIdsPost(params: {  "body"?: Array<number>; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/terminals/terminalRegionsByRegionIds`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get the Unknown terminal
     */
    apiV1TerminalsUndefinedTerminalGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/terminals/undefinedTerminal`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Updates an existing terminal
     * @param body 
     */
    apiV1TerminalsUpdateTerminalPut(params: {  "body"?: Terminal; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/terminals/updateTerminal`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * TerminalsApi - functional programming interface
 */
export const TerminalsApiFp = {
    /** 
     * Deletes a terminal
     * @param terminalId 
     */
    apiV1TerminalsDeleteTerminalDelete(params: { "terminalId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BooleanResponseBase> {
        const fetchArgs = TerminalsApiFetchParamCreator.apiV1TerminalsDeleteTerminalDelete(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets a list of terminals
     */
    apiV1TerminalsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Terminal>> {
        const fetchArgs = TerminalsApiFetchParamCreator.apiV1TerminalsGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Adds a new terminal
     * @param body 
     */
    apiV1TerminalsNewTerminalPut(params: { "body"?: Terminal;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TerminalResponseBase> {
        const fetchArgs = TerminalsApiFetchParamCreator.apiV1TerminalsNewTerminalPut(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets a list of terminal regions that has a matching region Id in the passed array
     * @param body 
     */
    apiV1TerminalsTerminalRegionsByRegionIdsPost(params: { "body"?: Array<number>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<TerminalRegion>> {
        const fetchArgs = TerminalsApiFetchParamCreator.apiV1TerminalsTerminalRegionsByRegionIdsPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get the Unknown terminal
     */
    apiV1TerminalsUndefinedTerminalGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Terminal> {
        const fetchArgs = TerminalsApiFetchParamCreator.apiV1TerminalsUndefinedTerminalGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Updates an existing terminal
     * @param body 
     */
    apiV1TerminalsUpdateTerminalPut(params: { "body"?: Terminal;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TerminalResponseBase> {
        const fetchArgs = TerminalsApiFetchParamCreator.apiV1TerminalsUpdateTerminalPut(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * TerminalsApi - object-oriented interface
 */
export class TerminalsApi extends BaseAPI {
    /** 
     * Deletes a terminal
     * @param terminalId 
     */
    apiV1TerminalsDeleteTerminalDelete(params: {  "terminalId"?: number; }, options?: any) {
        return TerminalsApiFp.apiV1TerminalsDeleteTerminalDelete(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets a list of terminals
     */
    apiV1TerminalsGet(options?: any) {
        return TerminalsApiFp.apiV1TerminalsGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Adds a new terminal
     * @param body 
     */
    apiV1TerminalsNewTerminalPut(params: {  "body"?: Terminal; }, options?: any) {
        return TerminalsApiFp.apiV1TerminalsNewTerminalPut(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets a list of terminal regions that has a matching region Id in the passed array
     * @param body 
     */
    apiV1TerminalsTerminalRegionsByRegionIdsPost(params: {  "body"?: Array<number>; }, options?: any) {
        return TerminalsApiFp.apiV1TerminalsTerminalRegionsByRegionIdsPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get the Unknown terminal
     */
    apiV1TerminalsUndefinedTerminalGet(options?: any) {
        return TerminalsApiFp.apiV1TerminalsUndefinedTerminalGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Updates an existing terminal
     * @param body 
     */
    apiV1TerminalsUpdateTerminalPut(params: {  "body"?: Terminal; }, options?: any) {
        return TerminalsApiFp.apiV1TerminalsUpdateTerminalPut(params, options)(this.fetch, this.basePath);
    }
};

/**
 * TerminalsApi - factory interface
 */
export const TerminalsApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Deletes a terminal
         * @param terminalId 
         */
        apiV1TerminalsDeleteTerminalDelete(params: {  "terminalId"?: number; }, options?: any) {
            return TerminalsApiFp.apiV1TerminalsDeleteTerminalDelete(params, options)(fetch, basePath);
        },
        /** 
         * Gets a list of terminals
         */
        apiV1TerminalsGet(options?: any) {
            return TerminalsApiFp.apiV1TerminalsGet(options)(fetch, basePath);
        },
        /** 
         * Adds a new terminal
         * @param body 
         */
        apiV1TerminalsNewTerminalPut(params: {  "body"?: Terminal; }, options?: any) {
            return TerminalsApiFp.apiV1TerminalsNewTerminalPut(params, options)(fetch, basePath);
        },
        /** 
         * Gets a list of terminal regions that has a matching region Id in the passed array
         * @param body 
         */
        apiV1TerminalsTerminalRegionsByRegionIdsPost(params: {  "body"?: Array<number>; }, options?: any) {
            return TerminalsApiFp.apiV1TerminalsTerminalRegionsByRegionIdsPost(params, options)(fetch, basePath);
        },
        /** 
         * Get the Unknown terminal
         */
        apiV1TerminalsUndefinedTerminalGet(options?: any) {
            return TerminalsApiFp.apiV1TerminalsUndefinedTerminalGet(options)(fetch, basePath);
        },
        /** 
         * Updates an existing terminal
         * @param body 
         */
        apiV1TerminalsUpdateTerminalPut(params: {  "body"?: Terminal; }, options?: any) {
            return TerminalsApiFp.apiV1TerminalsUpdateTerminalPut(params, options)(fetch, basePath);
        },
    };
};


/**
 * ZoneApi - fetch parameter creator
 */
export const ZoneApiFetchParamCreator = {
    /** 
     * Gets a list of zone
     */
    apiV1ZoneGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/Zone`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets a list of zone regions with matching zip codes in passed array
     * @param body 
     */
    apiV1ZoneGetZoneRegionsByZipPostalCodePost(params: {  "body"?: Array<string>; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/Zone/getZoneRegionsByZipPostalCode`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Creates a new zone
     * @param body The zone.
     */
    apiV1ZonePost(params: {  "body"?: Zone; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/Zone`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Updates a specific zone.
     * @param body The update zone.
     */
    apiV1ZonePut(params: {  "body"?: Zone; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/Zone`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets a list of zone regions
     */
    apiV1ZoneZoneRegionsGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/Zone/zoneRegions`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ZoneApi - functional programming interface
 */
export const ZoneApiFp = {
    /** 
     * Gets a list of zone
     */
    apiV1ZoneGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Zone>> {
        const fetchArgs = ZoneApiFetchParamCreator.apiV1ZoneGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets a list of zone regions with matching zip codes in passed array
     * @param body 
     */
    apiV1ZoneGetZoneRegionsByZipPostalCodePost(params: { "body"?: Array<string>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ZoneRegion>> {
        const fetchArgs = ZoneApiFetchParamCreator.apiV1ZoneGetZoneRegionsByZipPostalCodePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Creates a new zone
     * @param body The zone.
     */
    apiV1ZonePost(params: { "body"?: Zone;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Zone> {
        const fetchArgs = ZoneApiFetchParamCreator.apiV1ZonePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Updates a specific zone.
     * @param body The update zone.
     */
    apiV1ZonePut(params: { "body"?: Zone;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Zone> {
        const fetchArgs = ZoneApiFetchParamCreator.apiV1ZonePut(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets a list of zone regions
     */
    apiV1ZoneZoneRegionsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ZoneRegion>> {
        const fetchArgs = ZoneApiFetchParamCreator.apiV1ZoneZoneRegionsGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ZoneApi - object-oriented interface
 */
export class ZoneApi extends BaseAPI {
    /** 
     * Gets a list of zone
     */
    apiV1ZoneGet(options?: any) {
        return ZoneApiFp.apiV1ZoneGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Gets a list of zone regions with matching zip codes in passed array
     * @param body 
     */
    apiV1ZoneGetZoneRegionsByZipPostalCodePost(params: {  "body"?: Array<string>; }, options?: any) {
        return ZoneApiFp.apiV1ZoneGetZoneRegionsByZipPostalCodePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Creates a new zone
     * @param body The zone.
     */
    apiV1ZonePost(params: {  "body"?: Zone; }, options?: any) {
        return ZoneApiFp.apiV1ZonePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Updates a specific zone.
     * @param body The update zone.
     */
    apiV1ZonePut(params: {  "body"?: Zone; }, options?: any) {
        return ZoneApiFp.apiV1ZonePut(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets a list of zone regions
     */
    apiV1ZoneZoneRegionsGet(options?: any) {
        return ZoneApiFp.apiV1ZoneZoneRegionsGet(options)(this.fetch, this.basePath);
    }
};

/**
 * ZoneApi - factory interface
 */
export const ZoneApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Gets a list of zone
         */
        apiV1ZoneGet(options?: any) {
            return ZoneApiFp.apiV1ZoneGet(options)(fetch, basePath);
        },
        /** 
         * Gets a list of zone regions with matching zip codes in passed array
         * @param body 
         */
        apiV1ZoneGetZoneRegionsByZipPostalCodePost(params: {  "body"?: Array<string>; }, options?: any) {
            return ZoneApiFp.apiV1ZoneGetZoneRegionsByZipPostalCodePost(params, options)(fetch, basePath);
        },
        /** 
         * Creates a new zone
         * @param body The zone.
         */
        apiV1ZonePost(params: {  "body"?: Zone; }, options?: any) {
            return ZoneApiFp.apiV1ZonePost(params, options)(fetch, basePath);
        },
        /** 
         * Updates a specific zone.
         * @param body The update zone.
         */
        apiV1ZonePut(params: {  "body"?: Zone; }, options?: any) {
            return ZoneApiFp.apiV1ZonePut(params, options)(fetch, basePath);
        },
        /** 
         * Gets a list of zone regions
         */
        apiV1ZoneZoneRegionsGet(options?: any) {
            return ZoneApiFp.apiV1ZoneZoneRegionsGet(options)(fetch, basePath);
        },
    };
};


/**
 * ZoneConfigurationApi - fetch parameter creator
 */
export const ZoneConfigurationApiFetchParamCreator = {
    /** 
     * Gets a list of zone config
     * @param companyId 
     */
    apiV1ZoneConfigurationGet(params: {  "companyId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/ZoneConfiguration`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "companyId": params["companyId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Updates zone configs.
     * @param body The update zones.
     */
    apiV1ZoneConfigurationUpdatezoneConfigurationsPost(params: {  "body"?: Array<ZoneConfiguration>; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/ZoneConfiguration/updatezoneConfigurations`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ZoneConfigurationApi - functional programming interface
 */
export const ZoneConfigurationApiFp = {
    /** 
     * Gets a list of zone config
     * @param companyId 
     */
    apiV1ZoneConfigurationGet(params: { "companyId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ZoneConfiguration>> {
        const fetchArgs = ZoneConfigurationApiFetchParamCreator.apiV1ZoneConfigurationGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Updates zone configs.
     * @param body The update zones.
     */
    apiV1ZoneConfigurationUpdatezoneConfigurationsPost(params: { "body"?: Array<ZoneConfiguration>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ZoneConfiguration>> {
        const fetchArgs = ZoneConfigurationApiFetchParamCreator.apiV1ZoneConfigurationUpdatezoneConfigurationsPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ZoneConfigurationApi - object-oriented interface
 */
export class ZoneConfigurationApi extends BaseAPI {
    /** 
     * Gets a list of zone config
     * @param companyId 
     */
    apiV1ZoneConfigurationGet(params: {  "companyId"?: number; }, options?: any) {
        return ZoneConfigurationApiFp.apiV1ZoneConfigurationGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Updates zone configs.
     * @param body The update zones.
     */
    apiV1ZoneConfigurationUpdatezoneConfigurationsPost(params: {  "body"?: Array<ZoneConfiguration>; }, options?: any) {
        return ZoneConfigurationApiFp.apiV1ZoneConfigurationUpdatezoneConfigurationsPost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * ZoneConfigurationApi - factory interface
 */
export const ZoneConfigurationApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Gets a list of zone config
         * @param companyId 
         */
        apiV1ZoneConfigurationGet(params: {  "companyId"?: number; }, options?: any) {
            return ZoneConfigurationApiFp.apiV1ZoneConfigurationGet(params, options)(fetch, basePath);
        },
        /** 
         * Updates zone configs.
         * @param body The update zones.
         */
        apiV1ZoneConfigurationUpdatezoneConfigurationsPost(params: {  "body"?: Array<ZoneConfiguration>; }, options?: any) {
            return ZoneConfigurationApiFp.apiV1ZoneConfigurationUpdatezoneConfigurationsPost(params, options)(fetch, basePath);
        },
    };
};
