import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  Customer
} from "$Generated/api";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from "$Imports/MaterialUIComponents";

import {
  CustomerSearchResults
} from "$Imports/CommonComponents"

import {
  CustomerService,
  ICustomerServiceInjectedProps
} from "$State/CustomerFreezerService";

import {
  CustomerSearchCustomerType
} from "$State/QuoteEntryFreezerService";

interface ICustomerSearchModalBaseProps {
  isOpen?: boolean;
  customerType?: CustomerSearchCustomerType;
  selectedRow?: Customer | null;
  canAdd?: boolean;
  addSeed?: Customer;
  canExpandSearch?: boolean;
  onCustomerSelect?: (customerType: CustomerSearchCustomerType | undefined) => void;
  onCustomerModalClose?: () => void;
  hiddenColumns?: string[]; // additional hidden columns
  searchAll?: boolean;
  callerId?: number;
}

type ICustomerSearchModalProps = ICustomerSearchModalBaseProps
  & ICustomerServiceInjectedProps;

const styles: {
  expandActions: string;
  normalActions: string;
} = require("./CustomerSearchModal.scss");

class _CustomerSearchModal extends React.PureComponent<ICustomerSearchModalProps> {
  static defaultProps: Partial<ICustomerSearchModalBaseProps> = {
    canExpandSearch: true
  };

  @bind
  private _onAdd() {
    this.props.customerService.openAddEditCustomerModal(
      "CustomerSearchModal",
      {
        ...this.props.addSeed,
        isCaller: this.props.customerType === "Caller" ? true : undefined,
        isShipper: this.props.customerType === "Shipper" || this.props.customerType === "Consignee" ? true : undefined,
        isConsignee: this.props.customerType === "Consignee" || this.props.customerType === "Shipper"? true : undefined,
        billingStatus: "CheckCredit"
      }
    );
  }

  @bind
  private _onClose() {
    if (this.props.onCustomerModalClose) {
      this.props.onCustomerModalClose();
    }
  }

  @bind
  private _onCustomerSelect() {
    if (this.props.onCustomerSelect) {
      this.props.onCustomerSelect(this.props.customerType);
    }
  }

  render() {
    const {
      isOpen,
      customerType,
      selectedRow,
      canAdd,
      canExpandSearch,
      hiddenColumns,
      searchAll,
      callerId
    } = this.props;

    return (
      <Dialog
        open={isOpen === true}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>
          Customer Search
        </DialogTitle>
        <DialogContent>
          <CustomerSearchResults
            onEnterPressWithSelectedRow={this._onCustomerSelect}
            hiddenColumns={_.concat(["phoneNumber", "cellNumber", "emailAddress", "actions"], hiddenColumns ?? [])}
            hideGridIfNoResults
            limitGridHeight
            restrictToCustomerType={customerType}
            canExpandSearch={customerType === "BillTo" ? false : canExpandSearch}
            searchAll={searchAll}
            callerId={callerId}
            isBillTo={customerType === "BillTo"}
          />
        </DialogContent>
        <DialogActions>
          <div className={styles.expandActions}>
            {canAdd && (
              <Button
                onClick={this._onAdd}
                color="primary"
              >
                Add New {customerType ?? "Customer"}
              </Button>
            )}
          </div>

          <div className={styles.normalActions}>
            <Button
              onClick={this._onCustomerSelect}
              color="primary"
              disabled={!selectedRow}
            >
              Select
            </Button>
            <Button
              onClick={this._onClose}
              variant="outlined"
            >
              Cancel
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    )
  }
}

export const CustomerSearchModal = CustomerService.inject(_CustomerSearchModal);
