
import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  Button
} from "$Imports/MaterialUIComponents"

import {
  Customer,
  CustomerBillingStatusEnum,
  BillingStatusQuote
} from "$Generated/api";

interface IActionMenuProps {
  billingStatus: CustomerBillingStatusEnum;
  customer: Customer | undefined;
  quote: BillingStatusQuote | undefined;
  openEditCustomerModal: (customer: Customer) => void;
  onPaymentCollectedClick: (quote: BillingStatusQuote) => void;
}

export class ActionMenu extends React.PureComponent<IActionMenuProps> {

  @bind
  private _onEditBillToClick() {
    const { customer } = this.props;
    if (!customer) {
      return;
    }
    
    this.props.openEditCustomerModal(customer);
  }
  
  @bind
  private _onPaymentCollectedClick() {
    const { quote } = this.props;
    if (!quote) {
      return;
    }
    
    this.props.onPaymentCollectedClick(quote);
  }

  render() {
    const isCheckCredit = this.props.billingStatus === "CheckCredit";
    return (
      <>
        
        <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
          <Button
            color="primary"
            onClick={isCheckCredit ? this._onEditBillToClick : this._onPaymentCollectedClick}
          >
            {isCheckCredit ? "Edit Bill-To" : "Payment Collected"}
          </Button>
        </div>
      </>
    );
  }
}
