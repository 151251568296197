import {
  _,
  React,
  bind
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  Customer,
  CustomerContact,
  CustomerSource
} from "$Generated/api";

import {
  IconButton,
  Button,
  Link,
  Grid
} from "$Imports/MaterialUIComponents";

import {
  CardLinedHeader,
  UserAccessControl,
  CustomerContactModal,
  AddEditCustomerModal
} from "$Imports/CommonComponents";

import {
  Announcement,
  Edit,
  Launch,
  Paid
} from "$Imports/MaterialUIIcons";

import {
  StateService,
  IStateServiceInjectedProps
} from "$State/RegionFreezerService";

import {
  AlertCustomerType,
  IQuoteEntryServiceInjectedProps,
  QuoteEntryService
} from "$State/QuoteEntryFreezerService";

import {
  CustomerService,
  ICustomerServiceInjectedProps
} from "$State/CustomerFreezerService";

import {
  EmployeeService,
  IEmployeeServiceInjectedProps
} from "$State/EmployeeFreezerService";

import {
  ICustomerDetailServiceInjectedProps,
  CustomerDetailService,
  CustomerContactValidationSchema
} from "$State/CustomerDetailFreezerService";

import {
  ContactPersonDisplay
} from "./ContactPersonDisplay";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import {
  AddressDisplay
} from "./QuoteStopEntryComponents/AddressDisplay";

import {
  BillToDisplay
} from "./QuoteStopEntryComponents/BillToDisplay";

import {
  SecurityContext
} from "$Utilities/Security/ApplicationSecuritySettings";

const styles: {
  titleContainer: string;
  alertIcon: string;
  paidIcon: string;
} = require("./CustomerCard.scss");

interface ICustomerCardState {
  addEditContactModalIsOpen: boolean;
  addEditContact: CustomerContact | undefined;
  contactValidationErrors: ValidationError | null;
}

interface ICustomerCardBaseProps {
  customerSources: CustomerSource[];
  onCopyClick: () => void;
  onCustomerAlertClick: (alertCustomers: Customer[], alertCustomerType: AlertCustomerType) => void;
}

type ICustomerCardProps = ICustomerCardBaseProps
  & IQuoteEntryServiceInjectedProps
  & IStateServiceInjectedProps
  & ICustomerServiceInjectedProps
  & IEmployeeServiceInjectedProps
  & ICustomerDetailServiceInjectedProps;

class _CustomerCard extends React.Component<ICustomerCardProps, ICustomerCardState> {
  state: ICustomerCardState = {
    addEditContactModalIsOpen: false,
    addEditContact: undefined,
    contactValidationErrors: null
  }

  async componentDidMount() {
    this.props.regionService.fetchStates();
    this.props.employeeService.fetchAccOrBusDevManagers();

    const { quote, selectedCustomer } = this.props.QuoteEntryService.getState();
    if ((!quote.status || quote.status === "Convert") && selectedCustomer?.displayAlert) {
      // always show caller alert on new quotes, or converting customer quotes
      this._openCallerAlertModal();
    }
  }

  @bind
  private _openEditModal() {
    const {
      selectedCustomer,
      viewOnly
    } = this.props.QuoteEntryService.getState();

    if (selectedCustomer && !viewOnly) {
      this.props.customerService.openAddEditCustomerModal("CustomerCard", selectedCustomer);
    }
  }

  @bind
  private _cancelEditModal() {
    this.props.customerService.closeAddEditModal();
  }

  @bind
  private async _saveCustomer(customer: Customer) {
    await this.props.customerService.updateSelectedCustomer(customer);

    const result = this.props.customerService.getState().saveCustomerResults;

    if (result?.data) {
      this.props.QuoteEntryService.setSelectedCustomer(result.data);
      this.props.QuoteEntryService.updateAddressInformation();
      this.props.customerService.closeAddEditModal();
    }
  }

  @bind
  private _openAddEditContactModal(contactId?: number) {
    const {
      selectedCustomer,
      selectedCustomerContactsResults
    } = this.props.QuoteEntryService.getState();

    const contact = contactId ? _.find(selectedCustomerContactsResults?.data, c => c.id === contactId) : undefined;
    const addEditContact = contact ?? {
      isActive: true,
      customerId: selectedCustomer?.id
    };

    this.setState({
      addEditContactModalIsOpen: true,
      contactValidationErrors: null,
      addEditContact: addEditContact
    });
  }

  @bind
  private _closeAddEditContactModal() {
    this.setState({
      addEditContactModalIsOpen: false,
      contactValidationErrors: null,
      addEditContact: undefined
    });
  }

  @bind
  private async _saveContact() {
    const errors = await validateSchema(CustomerContactValidationSchema, this.state.addEditContact, {
      abortEarly: false,
      context: { emailRequired: true }
    });
    this.setState({ contactValidationErrors: errors });

    if (errors) {
      return;
    }

    if (this.state.addEditContact) {
      this.props.QuoteEntryService.saveContact(this.state.addEditContact, "Caller");
    }

    this._closeAddEditContactModal();
  }

  @bind
  private _addContactChange(contact: Partial<CustomerContact>) {
    this.setState((prev) => ({
      addEditContact: {
        ...prev.addEditContact,
        ...contact
      }
    }));
  }

  @bind
  private _openCallerAlertModal() {
    const {
      selectedCustomer
    } = this.props.QuoteEntryService.getState();

    if (selectedCustomer) {
      this.props.onCustomerAlertClick([selectedCustomer], "Caller");
    }
  }

  @bind
  private _onChangeContact(contactId: number | undefined) {
    this.props.QuoteEntryService.updateSelectedContact(contactId);
  }

  @bind
  private _onCustomerSearchClick() {
    this.props.QuoteEntryService.onCustomerSearchModalOpen("BillTo");
  }

  public render() {
    const {
      quote,
      selectedCustomer,
      selectedBillToCustomer,
      viewOnly,
      editMode,
      selectedCustomerContactsResults
    } = this.props.QuoteEntryService.getState();
    const {
      regionFetchResults
    } = this.props.regionService.getState();
    const {
      customerModalIsOpen,
      addEditCustomer,
      saveCustomerResults
    } = this.props.customerService.getState();
    const {
      activeAccOrBusDevManagers
    } = this.props.employeeService.getState();
    const {
      nameSuffixFetchResults,
      contactTypeFetchResults
    } = this.props.customerDetailService.getState();

    let suffixData = nameSuffixFetchResults.data ?? [];
    suffixData = _.orderBy(suffixData, s => s.suffixValue);
    let contactTypeData = contactTypeFetchResults.data ?? [];
    contactTypeData = _.orderBy(contactTypeData, c => c.type);

    const regions = regionFetchResults.data ?? [];
    const accOrBusDevManagers = _.orderBy(activeAccOrBusDevManagers ?? [], s => s.firstName);

    const disableContact = editMode || quote.status === "Accepted" || quote.status === "Declined" || quote.status === "Expired" || quote.status === "AcceptanceRejected";
    const isCarrierRep = SecurityContext.isInGroup("/CarrierRep");

    const contacts = selectedCustomerContactsResults.data ?? [];
    const contactId = quote.customerContactId ?? contacts?.find(c => c.isPrimary)?.id;

    const HeaderComponents = () =>
      <div style={{ marginLeft: "auto", display: "flex" }}>
        {viewOnly &&
          <UserAccessControl roles={["quote:create", "quote:edit"]}>
            <Button
              color="primary"
              onClick={this.props.onCopyClick}
              style={{ marginBottom: "10px" }}
            >
              Copy
            </Button>
          </UserAccessControl>
        }
        <IconButton
          onClick={this._openEditModal}
          disabled={viewOnly}
          style={{ marginBottom: "-5px", paddingTop: "0px" }}
        >
          <Edit />
        </IconButton>
        {!isCarrierRep && <Link
          href={`/${selectedCustomer?.isProspect ? 'prospect' : 'customer'}/${selectedCustomer?.id}`}
          target="_blank"
          style={{ color: "inherit", display: "inline-flex" }}
        >
          <IconButton
            style={{ marginBottom: "-5px", paddingTop: "0px" }}
          >
            <Launch />
          </IconButton>
        </Link>}
      </div>

    return (
      <>
        <CardLinedHeader
          titleText={
            <div className={styles.titleContainer}>
              Caller Information
              {selectedCustomer?.displayAlert &&
                <IconButton
                  className={styles.alertIcon}
                  onClick={this._openCallerAlertModal}
                  size="small"
                >
                  <Announcement fontSize="inherit" />
                </IconButton>
              }
            </div>
          }
          titleComponents={<HeaderComponents />}
          style={{ margin: "10px 0 0 10px" }}
        >
          {selectedCustomer &&
            <Grid container direction="row" wrap="wrap" spacing={1.25}>
              <Grid item xs={4}>
                <AddressDisplay regions={regions} customer={selectedCustomer} place={undefined} />
              </Grid>
              <Grid item xs={4}>
                <ContactPersonDisplay
                  contactId={contactId}
                  contacts={contacts ?? []}
                  disableContact={disableContact}
                  viewOnly={viewOnly}
                  onChangeContact={this._onChangeContact}
                  onUpdateContact={this._openAddEditContactModal}
                  customerType="Caller" />
                {(!viewOnly && !disableContact) && (
                  <UserAccessControl roles={["quote:create", "quote:edit"]}>
                    <Button onClick={() => this._openAddEditContactModal()}>Add Contact</Button>
                  </UserAccessControl>
                )}
              </Grid>
              <Grid item xs={4}>
                {selectedBillToCustomer &&
                  <BillToDisplay customer={selectedBillToCustomer} />
                }
                <UserAccessControl roles={["quote:create", "quote:edit"]}>
                  <Button disabled={viewOnly} onClick={this._onCustomerSearchClick}><Paid className={styles.paidIcon} />Change Bill-To</Button>
                </UserAccessControl>
              </Grid>
            </Grid>
          }
        </CardLinedHeader>
        <AddEditCustomerModal
          isOpen={customerModalIsOpen === "CustomerCard"}
          isFetching={saveCustomerResults.isFetching}
          customer={addEditCustomer ?? {}}
          regions={regions}
          accOrBusDevManagers={accOrBusDevManagers}
          customerSources={this.props.customerSources}
          onSave={this._saveCustomer}
          onCancel={this._cancelEditModal}
          disableCallerCheckbox
        />
        <CustomerContactModal
          isOpen={this.state.addEditContactModalIsOpen}
          contact={this.state.addEditContact}
          validationErrors={this.state.contactValidationErrors}
          onSave={this._saveContact}
          onCancel={this._closeAddEditContactModal}
          onChange={this._addContactChange}
          suffixes={suffixData}
          contactTypes={contactTypeData}
          isEmailRequired={true}
          fromQuote
        />
      </>
    );
  }
}

export const CustomerCard = QuoteEntryService.inject(
  StateService.inject(
    CustomerService.inject(
      EmployeeService.inject(
        CustomerDetailService.inject(
          _CustomerCard
        )
      )
    )
  )
);