import {
  React,
  _,
  bind,
  moment
} from "$Imports/Imports";

import {
  ICustomerDetailServiceInjectedProps,
  CustomerDetailService
} from "$State/CustomerDetailFreezerService";

import {
  Grid,
  Icon,
  IconButton
} from "$Imports/MaterialUIComponents";

import {
  AjaxActionIndicator,
  CardLinedHeader,
  DisplayFormattedNumber,
  DisplayFormattedDatetime,
  ActivitiesCard,
  UserAccessControl,
  AddEditCustomerModal,
  CustomerContactModal,
  CustomerReminders,
  ContactsCard,
  directionType
} from "$Imports/CommonComponents";

import {
  IEmployeeServiceInjectedProps,
  EmployeeService
} from "$State/EmployeeFreezerService";

import {
  StateService,
  IStateServiceInjectedProps
} from "$State/RegionFreezerService";

import {
  CustomerSourceService,
  ICustomerSourceServiceInjectedProps
} from "$State/CustomerSourceFreezerService";

import {
  getFormattedZipPostalCode
} from "$Shared/utilities/helpers";

import {
  Edit,
  Info
} from "$Imports/MaterialUIIcons";

import {
  Customer,
  CustomerContact,
  Activity
} from "$Generated/api";

import {
  decisionMakingTypeTextMap,
  getPercentageToCloseNumber,
  industryTypeTextMap,
  pricingTypeTextMap
} from "$Utilities/enumUtil";

import {
  CURRENCY_FORMAT,
  DATE_ONLY_FORMAT
} from "$Shared/utilities/formatUtil";
import { CustomersQuotes } from "$Pages/CustomerDetailViewPage/Quotes/CustomersQuotes";

interface IProspectDetailViewState {
  activity: Activity;
  isActivityModalOpen: boolean;
}

interface IProspectDetailViewBaseProps {
  companyId: number | undefined;
  customerId: number | undefined;
}

type IProspectDetailViewProps = IProspectDetailViewBaseProps
  & IEmployeeServiceInjectedProps
  & ICustomerDetailServiceInjectedProps
  & IEmployeeServiceInjectedProps
  & IStateServiceInjectedProps
  & ICustomerSourceServiceInjectedProps;

const styles: {
  mainContainer: string;
  column: string;
  infoHeader: string;
  row: string;
  rowColumn: string;
  address: string;
  fieldPair: string;
  fieldPairIcon: string;
  fieldLabelOne: string;
  fieldLabelTwo: string;
  fieldLabelThree: string;
  fieldValue: string;
  website: string;
  icon: string;
  infoIcon: string
} = require("./ProspectDetailView.scss");

class _ProspectDetailView extends React.Component<IProspectDetailViewProps, IProspectDetailViewState> {
  state: IProspectDetailViewState = {
    activity: {},
    isActivityModalOpen: false
  };

  componentDidMount() {
    const { customerId } = this.props;

    if (customerId) {
      this.props.customerDetailService.updateQuoteSearchCriteria({ statuses: [] });
      this.props.customerDetailService.fetchCustomerDetailData(customerId);
      this.props.customerDetailService.fetchCustomerContacts(customerId, true);
      this.props.customerDetailService.fetchCustomerQuotes(customerId, true);
      this.props.customerDetailService.fetchCustomerNotes(customerId, true);
    }
    this.props.regionService.fetchStates();
    this.props.employeeService.fetchSalesReps();
    this.props.employeeService.fetchAccOrBusDevManagers();
    this.props.customerSourceService.fetchCustomerSources(true, true);
    this.props.customerDetailService.fetchNameSuffixes();
    this.props.customerDetailService.fetchContactTypes();
  }

  componentDidUpdate(prevProps: IProspectDetailViewProps) {
    const { customerId } = this.props;

    if (customerId && customerId !== prevProps.customerId) {
      this.props.customerDetailService.fetchCustomerDetailData(customerId);
      this.props.customerDetailService.fetchCustomerContacts(customerId, true);
      this.props.customerDetailService.fetchCustomerQuotes(customerId, true);
      this.props.customerDetailService.fetchCustomerNotes(customerId, true);
    }
  }

  componentWillUnmount() {
    this.props.customerDetailService.clearFreezer();
  }

  @bind
  private _openDetailModal() {
    this.props.customerDetailService.openDetailModal();
  }

  @bind
  private _closeDetailModal() {
    this.props.customerDetailService.closeDetailModal();
  }

  @bind
  private _saveCustomerDetails(customer: Customer | undefined) {
    if (!customer){
      return
    }

    this.props.customerDetailService.saveCustomer(customer);
  }

  @bind
  private _addEditContact(contact?: CustomerContact) {
    this.props.customerDetailService.openCustomerContactModal(contact);
  }

  @bind
  private _setPrimaryContact(contact: CustomerContact) {
    this.props.customerDetailService.setPrimaryContact(contact);
  }

  @bind
  private _toggleShowInactiveContacts(showInactive: boolean) {
    this.props.customerDetailService.updateShowInactiveToggle(showInactive);
    const {customerDetailFetchResults} = this.props.customerDetailService.freezer.get();

    if (customerDetailFetchResults.data?.id){
      this.props.customerDetailService.fetchCustomerContacts(customerDetailFetchResults.data?.id, true, showInactive);
    }
  }

  @bind
  private _onContactChange(contact: Partial<CustomerContact>) {
    this.props.customerDetailService.contactOnChange(contact);
  }

  @bind
  private _onContactSave() {
    this.props.customerDetailService.saveCustomerContact();
  }

  @bind
  private _onContactSortChange(columnName: string | undefined, direction: directionType) {
    this.props.customerDetailService.setContactSortState({
      sortColumnName: columnName,
      sortDirection: direction,
    });
  }

  @bind
  private _closeContactModal() {
    this.props.customerDetailService.closeCustomerContactModal();
  }

  render() {
    const {
      customerDetailFetchResults,
      saveCustomerResults,
      detailModalState,
      contactsFetchResults,
      saveContactResults,
      contactModalState,
      nameSuffixFetchResults,
      contactTypeFetchResults,
      contactSortState,
      quoteFetchResults,
      showInactiveContacts
    } = this.props.customerDetailService.getState();

    const {
      customerId,
      companyId
    } = this.props;

    const customerData = customerDetailFetchResults.data ?? {};
    const contactData = contactsFetchResults.data ?? [];
    const customersQuotes = quoteFetchResults.data?.results ?? [];
    
    let suffixData = nameSuffixFetchResults.data ?? [];
    suffixData = _.orderBy(suffixData, s => s.suffixValue);
    let contactTypeData = contactTypeFetchResults.data ?? [];
    contactTypeData = _.orderBy(contactTypeData, c => c.type);

    const {
      salesRepFetchResults,
      accOrBusDevManagerFetchResults,
      activeSalesReps,
      activeAccOrBusDevManagers
    } = this.props.employeeService.getState();
    const salesReps = _.orderBy(activeSalesReps ?? [], s => s.lastName);
    const accOrBusDevManagers = _.orderBy(activeAccOrBusDevManagers ?? [], s => s.firstName);

    const {
      regionFetchResults
    } = this.props.regionService.getState();
    const regions = regionFetchResults.data ?? [];

    const {
      customerSourceFetchResults,
      activeCustomerSources
    } = this.props.customerSourceService.getState();
    const customerSources = _.orderBy(activeCustomerSources ?? [], s => s.name);
    const customerSource = _.find(customerSourceFetchResults.data, s => s.id === customerData.customerSourceId);

    let customerSince: moment.Moment | null = null;

    if (customerData?.customerSince) {
      customerSince = moment(moment(customerData.customerSince).utc().format("YYYY-MM-DD"));
    }

    const RevenuePerMo = customerData?.estAvgMonthlyFreightBills && customerData?.estAvgRevenuePerFreightBill ? customerData?.estAvgMonthlyFreightBills * customerData?.estAvgRevenuePerFreightBill : undefined;

    return (
      <Grid container className={styles.mainContainer}>
        <Grid container item xs={6}>
          <Grid item xs={12} className={styles.column}>
            <CardLinedHeader
              titleText={
                <span className={styles.infoHeader}>
                  {customerData?.customerName}
                </span>
              }
              titleComponents={
                <UserAccessControl roles={["quote:create", "quote:edit"]}>
                  <IconButton onClick={this._openDetailModal} size="small">
                    <Edit fontSize="small" style={{ verticalAlign: "middle" }} />
                  </IconButton>
                </UserAccessControl>
              }
            >
              <AjaxActionIndicator state={[customerDetailFetchResults, saveCustomerResults, salesRepFetchResults, accOrBusDevManagerFetchResults, customerSourceFetchResults]} />
              <div className={styles.row}>
                <div className={styles.rowColumn}>
                  <div className={styles.address}>
                    <div>{customerData?.address1 ?? ""}</div>
                    <div>{customerData?.address2 ?? ""}</div>
                    <div>{`${customerData?.city ?? ""}, ${customerData?.region?.regionAbbreviation ?? ""}, ${getFormattedZipPostalCode(customerData) ?? ""}`}</div>
                  </div>
                </div>                
                <div className={styles.rowColumn}>
                  <div className={styles.fieldPair}>
                    <div className={styles.fieldLabelTwo}>Industry Type:</div>
                    <div className={styles.fieldValue}>{industryTypeTextMap[customerData?.prospect?.industryType ?? ""]}</div>
                  </div>
                  <div className={styles.fieldPair}>
                    <div className={styles.fieldLabelTwo}>Decision Type:</div>
                    <div className={styles.fieldValue}>{decisionMakingTypeTextMap[customerData?.prospect?.decisionMakingType ?? ""]}</div>
                  </div>
                  <div className={styles.fieldPair}>
                    <div className={styles.fieldLabelTwo}>Pricing Type:</div>
                    <div className={styles.fieldValue}>{pricingTypeTextMap[customerData?.prospect?.pricingType ?? ""]}</div>
                  </div>
                </div>
                <div className={styles.rowColumn}>
                  <div className={styles.fieldPair}>
                    <div className={styles.fieldLabelThree}>Business Dev Manager:</div>
                    <div className={styles.fieldValue}>{customerData?.businessDevManager ? customerData?.businessDevManager?.firstName + " " + customerData?.businessDevManager?.lastName : "Not Assigned"}</div>
                  </div>
                  <div className={styles.fieldPair}>
                    <div className={styles.fieldLabelThree}>Prospect Since:</div>
                    <div className={styles.fieldValue}><DisplayFormattedDatetime value={customerSince?.toDate()} formatString={DATE_ONLY_FORMAT} /></div>
                  </div>
                  <div className={!!customerData.sourceDetails ? styles.fieldPairIcon : styles.fieldPair}>
                    <div className={styles.fieldLabelThree}>Lead Source:</div>
                    <div className={styles.fieldValue}>{customerSource?.name ?? "Unknown"}
                      {customerData.sourceDetails &&
                        <Icon title={customerData.sourceDetails} className={styles.icon}>
                          <Info className={styles.infoIcon} />
                        </Icon>}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.rowColumn}>
                  <div className={styles.fieldPair}>
                    <div className={styles.fieldLabelOne}>Phone:</div>
                    <div className={styles.fieldValue}>{customerData?.phoneNumber?.trim() || "N/A"}</div>
                  </div>
                  <div className={styles.website}>
                    <div className={styles.fieldLabelOne}>Website:</div>
                    <a href={customerData?.website ?? ""} target="_blank">{customerData?.website ?? ""}</a>
                  </div>
                </div>
                <div className={styles.rowColumn}>
                  <div className={styles.fieldPair}>
                    <div className={styles.fieldLabelTwo}>Current Provider:</div>
                    <div className={styles.fieldValue}>{customerData?.prospect?.currentProvider ?? ""}</div>
                  </div>
                  <div className={styles.fieldPair}>
                    <div className={styles.fieldLabelTwo}>% to Close:</div>
                    <div className={styles.fieldValue}>{`${getPercentageToCloseNumber(customerData?.prospect?.percentageToClose)}%`}</div>
                  </div>
                  <div className={styles.fieldPair}>
                    <div className={styles.fieldLabelTwo}>Start Date:</div>
                    <div className={styles.fieldValue}>{<DisplayFormattedDatetime value={customerData?.prospect?.startDate} formatString={DATE_ONLY_FORMAT} />}</div>
                  </div>
                </div>
                <div className={styles.rowColumn}>
                  <div className={styles.fieldPair}>
                    <div className={styles.fieldLabelThree}>Monthly Freight Bills:</div>
                    <div className={styles.fieldValue}>{customerData?.estAvgMonthlyFreightBills ?? ""}</div>
                  </div>
                  <div className={styles.fieldPair}>
                    <div className={styles.fieldLabelThree}>Revenue per FB:</div>
                    <div className={styles.fieldValue}><DisplayFormattedNumber value={customerData?.estAvgRevenuePerFreightBill} formatString={CURRENCY_FORMAT}/></div>
                  </div>
                  <div className={styles.fieldPair}>
                    <div className={styles.fieldLabelThree}>Monthly Revenue:</div>
                    <div className={styles.fieldValue}><DisplayFormattedNumber value={RevenuePerMo} formatString={CURRENCY_FORMAT}/></div>
                  </div>
                </div>
              </div>
            </CardLinedHeader>
            <ContactsCard
              contacts={contactData}
              addEditContact={this._addEditContact}
              setPrimaryContact={this._setPrimaryContact}
              onShowInactive={this._toggleShowInactiveContacts}
              sortState={contactSortState}
              onSortChange={this._onContactSortChange}
              isFetching={contactsFetchResults.isFetching || saveContactResults.isFetching}
              showInactiveContacts={showInactiveContacts}
              isProspect
            />
            <CustomersQuotes
              quotes={customersQuotes}
              customerId={customerId}
              isProspect
            />
          </Grid>
        </Grid>
        <Grid container item xs={6}>
          <Grid item xs={12} className={styles.column}>
            <CustomerReminders
              customer={customerData}
              salesReps={salesReps}
              companyId={companyId}
            />
          </Grid>
          <Grid item xs={12} className={styles.column}>
            <ActivitiesCard
              customerId={this.props.customerId}
              salesReps={salesReps}
            />
          </Grid>
          <Grid item xs={12} className={styles.column}>
            <ActivitiesCard
              customerId={this.props.customerId}
              salesReps={salesReps}
              isNotes
            />
          </Grid>
        </Grid>
        <AddEditCustomerModal
          isOpen={detailModalState.isOpen}
          isFetching={saveCustomerResults.isFetching}
          customer={detailModalState.editCustomer ?? {}}
          regions={regions}
          accOrBusDevManagers={accOrBusDevManagers}
          customerSources={customerSources}
          onSave={this._saveCustomerDetails}
          onCancel={this._closeDetailModal}
        />
        <CustomerContactModal
          isOpen={contactModalState.isOpen}
          contact={contactModalState.editContact}
          validationErrors={contactModalState.validationErrors}
          onSave={this._onContactSave}
          onCancel={this._closeContactModal}
          onChange={this._onContactChange}
          suffixes={suffixData}
          contactTypes={contactTypeData}
          isEmailRequired={contactModalState.isEmailRequired}
          isProspect
        />
      </Grid>
    )
  }
}

export const ProspectDetailView =
  CustomerDetailService.inject(
    EmployeeService.inject(
      StateService.inject(
        CustomerSourceService.inject(
          _ProspectDetailView
  ))));