
import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  AccessorialChargeValue,
  Quote,
  QuoteFreight,
  QuoteStop,
  Region
} from "$Generated/api";

import {
  Button
} from "$Imports/MaterialUIComponents";

import {
  AjaxActionIndicator,
  DisplayFormattedDatetime,
  DisplayFormattedNumber,
  DisplayFormattedFeet,
  QuoteStatus
} from "$Imports/CommonComponents";

import {
  QuoteEntryService,
  IQuoteEntryServiceInjectedProps,
} from "$State/QuoteEntryFreezerService";

import {
  StateService,
  IStateServiceInjectedProps
} from "$State/RegionFreezerService";

import {
  LoadingInstructionService,
  ILoadingInstructionServiceInjectedProps
} from "$State/LoadingInstructionFreezerService";

import {
  FreightTotalData
} from "$State/QuoteEntryValidationSchema";

import jsPDF, {
  HTMLOptions
} from "jspdf";

import {
  CURRENCY_FORMAT,
  CURRENCY_NO_DECIMAL_FORMAT,
  DATE_ONLY_FORMAT,
  PERCENTAGE_TWO_DECIMALS,
  NUMERIC_SEPARATED_FORMAT
} from "$Shared/utilities/formatUtil";

import {
  CompanyEnum,
  getCompanyLogo
} from "$Utilities/companyUtil";

import { getIcon } from "$Utilities/iconUtil";
import { RateEngineResult } from "$Utilities/rateEngineUtil";
import { renderToStaticMarkup } from "$Shared/imports/Imports";
import { getFormattedZipPostalCode } from "$Shared/utilities/helpers";

const styles: {
  controlsContainer: string,
  pdfButton: string,
  masterContainer: string,
  contentContainer: string;
  mainContainer: string,
  mainHeader: string,
  pageHeader: string,
  pageHeaderSpace: string,
  logo: string,
  headerTitle: string,
  pageNumber: string,
  sectionContainer: string,
  sectionHeader: string,
  sectionInfo: string,
  quoteTable: string,
  shipperConsigneeTable: string,
  freightTable: string,
  rateTable: string,
  termsContainer: string,
  signatureTable: string,
  signature: string,
  date: string,
  redText: string,
  textAlignTop: string,
  icon: string,
  flexbox: string,
  borderLeft: string
} = require("./PrintQuoteView.scss");

interface IOwnState {
  disableEmail: boolean;
  disableSave: boolean;
  pdfGenerating: boolean;
}

interface IPrintQuoteViewBaseProps {
  quoteId?: string;
}

type IPrintQuoteViewProps = IPrintQuoteViewBaseProps
  & IQuoteEntryServiceInjectedProps
  & IStateServiceInjectedProps
  & ILoadingInstructionServiceInjectedProps;

const yUnitsPerPage = 948;
const baseSettings: Partial<HTMLOptions> = {
    width: 760,
    windowWidth: 800
};

class _PrintQuoteView extends React.Component<IPrintQuoteViewProps, IOwnState> {
  state: IOwnState = {
    disableEmail: false,
    disableSave: false,
    pdfGenerating: false
  };

  private divRef = React.createRef<HTMLDivElement>();

  componentDidMount() {
    this.props.QuoteEntryService.fetchQuoteData(+(this.props.quoteId ?? 0), true);
    this.props.QuoteEntryService.fetchAccessorialChargeValues(+(this.props.quoteId ?? 0), true);
    this.props.regionService.fetchStates();
    this.props.loadingInstructionService.fetchLoadingInstructions(true);
  }

  @bind
  private async savePdf() {
    this.setState({
      disableEmail: true,
      disableSave: true,
      pdfGenerating: true
    });

    this.generatePDF("savePDF");
  }

  @bind
  private async emailPdf() {
    this.setState({
      disableEmail: true,
      disableSave: true,
      pdfGenerating: true
    });

    this.generatePDF("emailPDF");
  }

  @bind
  private async generatePDF(sender: string) {
    if (!this.divRef.current) {
      return;
    }

    const {
      quote
    } = this.props.QuoteEntryService.getState();
    const quoteStops = quote?.quoteStops ?? [];

    const fileName = `${quote.billTo?.customerName} Q${quote.quoteNumber}.pdf`;

    const pdf = new jsPDF({ format: "letter", unit: "px", hotfixes: ["px_scaling"] });
    pdf.setProperties({ title: fileName });

    const contentHeight = this.divRef.current.clientHeight;

    const pageSettings: HTMLOptions = {
      ...baseSettings,
      autoPaging: 'text',
      margin: [112, 30, 20, 30]
    };

    if (contentHeight > yUnitsPerPage) {
      const quoteInfo = document.getElementById("quoteInfo");
      const shipperConsignee = document.getElementById("shipperConsigneeSection");
      const freightDetails = document.getElementById("freightDetails");
      const rateDetails = document.getElementById("rateDetails");
      const confirmationSignature = document.getElementById("confirmationSignature");
      let currentHeight = quoteInfo?.clientHeight ?? 0;
      let currentContentString = quoteInfo?.outerHTML ?? "";
      let isFirstPage = true;
      const stopPadding = 10;

      if (currentHeight + (shipperConsignee?.clientHeight ?? 0) > yUnitsPerPage) {
        const stopDivs = shipperConsignee?.childNodes;
        if(stopDivs && stopDivs.length > 1) {
          for (let i = 0; i < stopDivs.length; i += 1) {
            if (currentHeight + (stopDivs[i] as Element).clientHeight + stopPadding > yUnitsPerPage) {
              await this.forcePageBreak(pdf, currentContentString, pageSettings, isFirstPage);
              currentHeight = (stopDivs[i] as Element).clientHeight ?? 0;
              currentContentString = (stopDivs[i] as Element).outerHTML ?? "";
              isFirstPage = false;
            } else {
              currentHeight += ((stopDivs[i] as Element).clientHeight + stopPadding);
              if (i === 0) {
                currentContentString += '<div id="shipperConsigneeSection">';
              }
              currentContentString += (stopDivs[i] as Element).outerHTML
            }
            if (i + 1 === stopDivs.length) {
              currentHeight += stopPadding;
              currentContentString += '</div>';
            }
          };
        } else {
          await this.forcePageBreak(pdf, currentContentString, pageSettings, isFirstPage);
          currentHeight = shipperConsignee?.clientHeight ?? 0;
          currentContentString = shipperConsignee?.outerHTML ?? "";
          isFirstPage = false;
        }
      } else {
        currentHeight += shipperConsignee?.clientHeight ?? 0;
        currentContentString += shipperConsignee?.outerHTML ?? "";
      }
      if (currentHeight + (freightDetails?.clientHeight ?? 0) > yUnitsPerPage) {
        await this.forcePageBreak(pdf, currentContentString, pageSettings, isFirstPage);
        currentHeight = freightDetails?.clientHeight ?? 0;
        currentContentString = freightDetails?.outerHTML ?? "";
        isFirstPage = false;
      } else {
        currentHeight += freightDetails?.clientHeight ?? 0;
        currentContentString += freightDetails?.outerHTML ?? "";
      }
      if (currentHeight + (rateDetails?.clientHeight ?? 0) > yUnitsPerPage) {
        await this.forcePageBreak(pdf, currentContentString, pageSettings, isFirstPage);
        currentHeight = rateDetails?.clientHeight ?? 0;
        currentContentString = rateDetails?.outerHTML ?? "";
        isFirstPage = false;
      } else {
        currentHeight += rateDetails?.clientHeight ?? 0;
        currentContentString += rateDetails?.outerHTML ?? "";
      }
      if (currentHeight + (confirmationSignature?.clientHeight ?? 0) > yUnitsPerPage) {
        await this.forcePageBreak(pdf, currentContentString, pageSettings, isFirstPage);
        currentHeight = confirmationSignature?.clientHeight ?? 0;
        currentContentString = confirmationSignature?.outerHTML ?? "";
        isFirstPage = false;
      } else {
        currentHeight += confirmationSignature?.clientHeight ?? 0;
        currentContentString += confirmationSignature?.outerHTML ?? "";
      }
      await this.forcePageBreak(pdf, currentContentString, pageSettings, isFirstPage);
    } else {
      await pdf.html(this.divRef.current, { autoPaging: 'text', width: 770, windowWidth: 800, margin: 25 });
    }

    const totalPageCount = pdf.getNumberOfPages();
    await this.generateHeaders(pdf, quote, quoteStops, totalPageCount);

    if (sender === "savePDF"){
      pdf.save(fileName);
    } else if (sender === "emailPDF") {
      const pdfOutput = pdf.output("arraybuffer");
      this.props.QuoteEntryService.emailQuotePDF(quote, pdfOutput).then((emailSent) => {
        setTimeout(() => {window.close()}, 3000);
      });
    }
    this.setState({
      disableEmail: false,
      disableSave: false,
      pdfGenerating: false
    });
  }

  @bind
  private forcePageBreak(pdf: jsPDF, content: string | HTMLElement, pageSettings: HTMLOptions, isFirstPage: boolean) {
    if (isFirstPage) {
      return pdf.html(content, pageSettings);
    } else {
      const pagesSoFar = pdf.getNumberOfPages();
      pageSettings.y = (pagesSoFar * yUnitsPerPage) - 20;

      pdf.addPage("letter", "portrait");

      return pdf.html(content, pageSettings);
    }
  };

  @bind
  private async generateHeaders(pdf: jsPDF, quote: Quote, quoteStops: QuoteStop[], pageCount: number) {
    const pageSettings = {
      ...baseSettings,
      margin: [20, 30, 20, 30]
    };

    for (let i = 0; i < pageCount; i += 1) {
      // adjust header placement for smaller top page margin
      pageSettings.y = (i * (yUnitsPerPage + 72));

      const header = this.buildMainHeader(quote, quoteStops, (i+1), pageCount);
      const headerDiv = renderToStaticMarkup(header);

      if (headerDiv) await pdf.html(headerDiv, pageSettings);
    }
  }

  @bind
  private buildMainHeader(quote: Quote, quoteStops: QuoteStop[], currentPage?: number, totalPages?: number) {
    return(
      <div className={styles.mainHeader} id="mainHeader">
        <img src={getCompanyLogo(quote.companyId)} className={styles.logo} title="Kaiser Logo" />
        <div className={styles.headerTitle}>
          <span>SHIPPING RATE CONFIRMATION</span>
          {quote.status === "Accepted" ? 
            <span>FREIGHT BILL #{quoteStops[0].freightBillNumber}</span> :
            <span>QUOTE #Q{quote.quoteNumber}</span>}
        </div>
        {(currentPage && totalPages) ?
          <div className={styles.pageNumber}>{`Page ${currentPage} of ${totalPages}`}</div>
        : null}
      </div>
    );
  }

  @bind
  private buildQuoteInfoSection(quote: Quote, quoteStops: QuoteStop[]) {
    return(
      <div className={styles.sectionContainer} id="quoteInfo">
        <div className={styles.sectionHeader}>
          Quote Information
        </div>
        <div className={styles.sectionInfo}>
          <table className={styles.quoteTable}>
            <tbody>
              <tr>
                <td>Sales Representative:</td>
                <td><b>{`${quote?.createdBy?.firstName} ${quote?.createdBy?.lastName}`}</b></td>
                <td>Total Declared Value*:</td>
                <td>
                  <b>{<DisplayFormattedNumber
                    value={_.sum(_.map(quoteStops, (qs) => qs.declaredValue ?? 0))}
                    formatString={CURRENCY_NO_DECIMAL_FORMAT}
                  />}</b>
                </td>
              </tr>
              <tr>
                <td>Quote Status:</td>
                <td className={(quote?.status === "Pending" || quote?.status === "PendingNeedsCustomers") ? styles.redText : undefined}>
                  <b>{quote?.status ? <QuoteStatus quoteStatus={quote.status} /> : null}</b>
                </td>
                <td>Requested Equipment:</td>
                <td><b>{quote.equipmentType?.name ?? null}</b></td>
              </tr>
              <tr>
                <td>Quote Date:</td>
                <td><b><DisplayFormattedDatetime value={quote?.quoteDate} formatString={DATE_ONLY_FORMAT} /></b></td>
                {(quote.status === "Pending" || quote.status === "PendingNeedsCustomers") ?
                  <><td>Expiration Date:</td>
                  <td><b><DisplayFormattedDatetime value={quote?.expirationDate} formatString={DATE_ONLY_FORMAT} /></b></td></>
                  : null}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  @bind
  private buildShipperConsigneeSection(quoteStops: QuoteStop[], regions: Region[]) {
    const {
      loadingInstructionFetchResults
    } = this.props.loadingInstructionService.getState();
    const loadingInstructions = loadingInstructionFetchResults.data ?? [];
    
    return (
      <div id="shipperConsigneeSection">
        {_.map(quoteStops, (qs, idx) => {
          const shipper = _.find(qs.addresses, a => a.addressType === "Shipper" && a.isCurrent === true);
          const consignee = _.find(qs.addresses, a => a.addressType === "Consignee" && a.isCurrent === true);
          const shipperName = shipper?.customer?.customerName ?? "";
          const shipperAddress1 = shipper?.address1 ?? "";
          const shipperAddress2 = shipper?.address2;
          const shipperAddress3 = `${shipper?.city ?? ""}, ${(shipper?.region?.regionAbbreviation ?? _.find(regions, r => r.id === shipper?.regionId)?.regionAbbreviation) ?? ""} ${getFormattedZipPostalCode(shipper) ?? ""}`;
          const consigneeName = consignee?.customer?.customerName ?? "";
          const consigneeAddress1 = consignee?.address1 ?? "";
          const consigneeAddress2 = consignee?.address2 ?? "";
          const consigneeAddress3 = `${consignee?.city ?? ""}, ${(consignee?.region?.regionAbbreviation ?? _.find(regions, r => r.id === consignee?.regionId)?.regionAbbreviation) ?? ""} ${getFormattedZipPostalCode(consignee) ?? ""}`;
          const shipperInstructionIds = shipper?.customer?.customerLoadingInstructions?.map((i) => i.loadingInstructionId);
          const consigneeInstructionIds = consignee?.customer?.customerLoadingInstructions?.map((i) => i.loadingInstructionId);
          const shipperInstructions = loadingInstructions.filter(i => shipperInstructionIds?.includes(i.id) && i.appliesTo !== 'ConsigneesOnly');
          const consigneeInstructions = loadingInstructions.filter(i => consigneeInstructionIds?.includes(i.id) && i.appliesTo !== 'ShippersOnly');
          return (
          <div className={styles.sectionContainer} key={idx} id="shipperConsignee">
            <table className={styles.shipperConsigneeTable}>
              <tbody>
                <tr>
                  <th colSpan={2}>{quoteStops.length > 1 ? `Shipper (Stop ${qs.stopNumber})` : "Shipper"}</th>
                  <th colSpan={2}>{quoteStops.length > 1 ? `Consignee (Stop ${qs.stopNumber})` : "Consignee"}</th>
                </tr>
                <tr>
                  <td colSpan={2}><b>
                    {shipperName ? <div>{shipperName}</div> : null}
                    {shipperAddress1 ? <div>{shipperAddress1}</div> : null}
                    {shipperAddress2 ? <div>{shipperAddress2}</div> : null}
                    {shipperAddress3 ? <div>{shipperAddress3}</div> : null}
                  </b></td>
                  <td colSpan={2} className={styles.borderLeft}><b>
                    {consigneeName ? <div>{consigneeName}</div> : null}
                    {consigneeAddress1 ? <div>{consigneeAddress1}</div> : null}
                    {consigneeAddress2 ? <div>{consigneeAddress2}</div> : null}
                    {consigneeAddress3 ? <div>{consigneeAddress3}</div> : null}
                  </b></td>
                </tr>
                <tr>
                  <td>Contact:</td>
                  <td><b>{qs.shipperContactId ? qs.shipperContact ? `${qs.shipperContact?.firstName} ${qs.shipperContact?.lastName}` : null : shipper?.customer?.contactName ?? null}</b></td>
                  <td className={styles.borderLeft}>Contact:</td>
                  <td><b>{qs.consigneeContact ? qs.consigneeContact ? `${qs.consigneeContact?.firstName} ${qs.consigneeContact?.lastName}` : null : consignee?.customer?.contactName ?? null}</b></td>
                </tr>
                <tr>
                  <td>Phone:</td>
                  <td><b>{qs.shipperContactId ? qs.shipperContact?.phoneNumber ?? null : shipper?.customer?.phoneNumber ?? null}</b></td>
                  <td className={styles.borderLeft}>Phone:</td>
                  <td><b>{qs.consigneeContact ? qs.consigneeContact?.phoneNumber ?? null : consignee?.customer?.phoneNumber ?? null}</b></td>
                </tr>
                <tr>
                  <td>Cell:</td>
                  <td><b>{qs.shipperContactId ? qs.shipperContact?.cellNumber ?? null : null}</b></td>
                  <td className={styles.borderLeft}>Cell:</td>
                  <td><b>{qs.consigneeContact ? qs.consigneeContact?.cellNumber ?? null : null}</b></td>
                </tr>
                <tr>
                  <td>Email:</td>
                  <td><b>{qs.shipperContactId ? qs.shipperContact?.emailAddress ?? null : shipper?.customer?.emailAddress ?? null}</b></td>
                  <td className={styles.borderLeft}>Email:</td>
                  <td><b>{qs.consigneeContact ? qs.consigneeContact?.emailAddress ?? null : consignee?.customer?.emailAddress ?? null}</b></td>
                </tr>
                <tr>
                  <td>Pickup Window:</td>
                  <td>
                    <b>{qs.shipperStartDate ? 
                      qs.shipperEndDate ? 
                        <div>
                          <DisplayFormattedDatetime value={qs.shipperStartDate} formatString={DATE_ONLY_FORMAT} /> - <DisplayFormattedDatetime value={qs.shipperEndDate} formatString={DATE_ONLY_FORMAT} />
                        </div> :
                        <DisplayFormattedDatetime value={qs.shipperStartDate} formatString={DATE_ONLY_FORMAT} /> :
                        null
                    }</b>
                  </td>
                  <td className={styles.borderLeft}>Delivery Window:</td>
                  <td>
                    <b>{qs.consigneeStartDate ? 
                      qs.consigneeEndDate ? 
                        <div>
                          <DisplayFormattedDatetime value={qs.consigneeStartDate} formatString={DATE_ONLY_FORMAT} /> - <DisplayFormattedDatetime value={qs.consigneeEndDate} formatString={DATE_ONLY_FORMAT} />
                        </div> :
                        <DisplayFormattedDatetime value={qs.consigneeStartDate} formatString={DATE_ONLY_FORMAT} /> :
                        null
                    }</b>
                  </td>
                </tr>
                <tr>
                  <td>Appointment Time:</td>
                  <td><b>{qs.shipperHardTime ? Number(qs.shipperHardTime.split(':')[0]) < 12 ? `${qs.shipperHardTime} AM` : `${qs.shipperHardTime} PM` : "N/A"}</b></td>
                  <td className={styles.borderLeft}>Appointment Time:</td>
                  <td><b>{qs.consigneeHardTime ? Number(qs.consigneeHardTime.split(':')[0]) < 12 ? `${qs.consigneeHardTime} AM` : `${qs.consigneeHardTime} PM` : "N/A"}</b></td>
                </tr>
                <tr>
                  <td>Load Instructions:</td>
                  <td>{shipperInstructions.length > 0 ?
                    _.map(shipperInstructions, (i, idx) => (
                      <div key={idx}><b>- {i.instruction}</b></div>
                    )) :
                    <b>N/A</b>}
                  </td>
                  <td className={styles.borderLeft}>Unload Instructions:</td>
                  <td>{consigneeInstructions.length > 0 ?
                    _.map(consigneeInstructions, (i, idx) => (
                      <div key={idx}><b>- {i.instruction}</b></div>
                    )) :
                    <b>N/A</b>}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>Service Level:</td>
                  <td><b>{qs.isShipperExpedited ? "Expedited" : "LTL"}</b></td>
                  <td>Military Base:</td>
                  <td><b>{qs.isMilitaryBase ? "Yes" : "No"}</b></td>
                </tr>
                <tr>
                  <td>Tarp Type:</td>
                  <td><b>{qs.tarp?.tarpName ?? "No Tarp"}</b></td>
                  <td>TWIC Card Required:</td>
                  <td><b>{qs.isTwicCardRequired ? "Yes" : "No"}</b></td>
                </tr>
                <tr>
                  <td>PO Number:</td>
                  <td><b>{qs.ponumber ?? null}</b></td>
                  <td>Notes:</td>
                  <td><b>{qs.externalNotes && qs.externalNotes !== "" ? qs.externalNotes : "N/A"}</b></td>
                </tr>
              </tfoot>
            </table>
          </div>);
        })}
      </div>
    );
  }

  @bind
  private buildFreightDetailsSection(freight: QuoteFreight[], freightTotalData: FreightTotalData, isMultiStop: boolean, showGroupedInfo: boolean) {
    return (
      <div className={styles.sectionContainer} id="freightDetails">
        <div className={styles.sectionHeader}>
          Freight Details
        </div>
        <div className={styles.sectionContainer}>
          {this.buildFreightTable(freight, freightTotalData, isMultiStop)}
          {showGroupedInfo ? <div className={styles.flexbox} >
            <img src={getIcon("autoAwesomeMotion")} className={styles.icon} title="Grouped" /> - indicates freight where listed measurements are totals instead of values for each piece
          </div> : null}
        </div>
      </div>
    );
  }

  @bind
  // no frills, no interactivity, no sorting. only tabular data.
  private buildFreightTable(freight: QuoteFreight[], freightTotalData: FreightTotalData, isMultiStop: boolean) {
    let totalFreightLength = freightTotalData.totalLength;
    if (freightTotalData.isOverdimensional){
      totalFreightLength = this.props.QuoteEntryService.calculateFreightLengthForPrint();
    }
    let headerRow = (
      <tr>
        {isMultiStop ? <th>Stop&nbsp;#</th> : null}
        <th>Commodity</th>
        <th>Pieces</th>
        <th>Length</th>
        <th>Width</th>
        <th>Height</th>
        <th>Weight</th>
        <th>Serial/Ref&nbsp;#</th>
        <th>Stackable</th>
        <th>Side&nbsp;by&nbsp;Side</th>
      </tr>
    );

    let totalRow = (
      <tr>
        <td>TOTAL</td>
        {isMultiStop ? <td></td> : null}
        <td>{freightTotalData.totalNumOfPieces}</td>
        <td>{<DisplayFormattedFeet value={totalFreightLength} />}</td>
        <td></td>
        <td></td>
        <td>{<DisplayFormattedNumber value={freightTotalData.totalWeight} formatString={"0"} postfix={" lbs"} />}</td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    );

    return (
      <table className={styles.freightTable}>
        <thead>
          {headerRow}
        </thead>
        <tbody>
          {_.map(freight, (qf, idx) => {
            return (<tr key={idx}>
              {isMultiStop ? <td>{qf.quoteStop?.stopNumber}</td> : null}
              <td>{qf.description && qf.description.length > 0 ? qf.description : qf.commodity?.commodityShortName ?? qf.commodity?.commodityName ?? null}</td>
              <td><div className={styles.flexbox}>{qf.numberOfPieces} {qf.isGrouped ? <img src={getIcon("autoAwesomeMotion")} className={styles.icon} title="Grouped" /> : null}</div></td>
              <td><DisplayFormattedFeet value={qf.length}/></td>
              <td><DisplayFormattedFeet value={qf.width}/></td>
              <td><DisplayFormattedFeet value={qf.height}/></td>
              <td><DisplayFormattedNumber value={qf.weight} formatString={"0"} postfix={" lbs"} /></td>
              <td>{qf.serialRefNumber ?? null}</td>
              <td>{qf.isStackable ? "Yes" : "No"}</td>
              <td>{qf.isSideBySide ? "Yes" : "No"}</td>
            </tr>);
          })}
        </tbody>
        <tfoot>
          {totalRow}
        </tfoot>
      </table>
    );
  }

  @bind
  private buildRateDetailsSection(quote: Quote, rateEngineResult: RateEngineResult | undefined, accessorialChargeValues: AccessorialChargeValue[]) {
    const accessorialChargesValue = accessorialChargeValues.reduce((sum, el) => sum += el.amount ?? 0, 0);
    const totalRate = quote.negotiatedRate ? quote.negotiatedRate + accessorialChargesValue : undefined;
    let lineHaulRate: number | undefined = undefined;
    if (quote?.negotiatedRate && rateEngineResult?.fuelSurcharge) {
      lineHaulRate = quote.negotiatedRate - (rateEngineResult.fuelSurcharge + (rateEngineResult?.tarpRate ?? 0) + (rateEngineResult?.locationFee ?? 0));
    }
    return (
      <div className={styles.sectionContainer} id="rateDetails" style={{margin: "10px 0"}}>
        <div className={styles.sectionHeader}>
          Rate Details
        </div>
        <div className={styles.sectionContainer} style={{margin: "0 24px"}}>
          <table className={styles.rateTable}>
            <tbody>
              <tr>
                <td>Line Haul</td>
                <td>
                  <DisplayFormattedNumber
                    value={lineHaulRate}
                    formatString={CURRENCY_FORMAT}
                  />
                </td>
              </tr>
              <tr>
                <td>Tarp</td>
                <td>
                  <DisplayFormattedNumber
                    value={rateEngineResult?.tarpRate}
                    formatString={CURRENCY_FORMAT}
                  />
                </td>
              </tr>
              {(rateEngineResult?.locationFee && rateEngineResult?.locationFee > 0) ?
                <tr>
                  <td>Location Fee</td>
                  <td>
                    <DisplayFormattedNumber
                      value={rateEngineResult?.locationFee}
                      formatString={CURRENCY_FORMAT}
                    />
                  </td>
                </tr> : null}
              <tr>
                <td>
                  Fuel Surcharge
                  {" ("}
                  <DisplayFormattedNumber
                    value={rateEngineResult?.fuelSurchargePercentage}
                    formatString={PERCENTAGE_TWO_DECIMALS}
                  />
                  {")"}
                </td>
                <td>
                  <DisplayFormattedNumber
                    value={rateEngineResult?.fuelSurcharge}
                    formatString={CURRENCY_FORMAT}
                  />
                </td>
              </tr>
              {
                _.map(accessorialChargeValues, (acv, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{acv.accessorialCharge?.description ?? `Accessorial Charge ${idx + 1}`}</td>
                      <td>
                        <DisplayFormattedNumber
                          value={acv.amount}
                          formatString={CURRENCY_FORMAT}
                        />
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
            <tfoot>
              <tr>
                <td>Total Rate</td>
                <td>
                  <DisplayFormattedNumber
                    value={totalRate}
                    formatString={CURRENCY_FORMAT}
                  />
                </td>
              </tr>
            </tfoot>
          </table>
          <p></p>
          <table className={styles.rateTable}>
            <tbody>
              <tr>
                <td>Mileage:</td>
                <td><b>
                  {quote?.miles ?
                    <DisplayFormattedNumber
                      value={quote?.miles}
                      formatString={NUMERIC_SEPARATED_FORMAT}
                    /> : null}
                </b></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  @bind
  private buildConfirmationSignatureSection(quote: Quote, regions: Region[]) {
    const address1 = quote.billTo?.hasBillingAddress ? quote.billTo?.billingAddress1 : quote.billTo?.address1;
    const address2 = quote.billTo?.hasBillingAddress ? quote.billTo?.billingAddress2 : quote.billTo?.address2;
    const city = quote.billTo?.hasBillingAddress ? quote.billTo?.billingCity : quote.billTo?.city;
    const region = quote.billTo?.hasBillingAddress 
      ? quote.billTo?.billingRegion?.regionAbbreviation ?? _.find(regions, r => r.id === quote.billTo?.billingRegionId)?.regionAbbreviation 
      : quote.billTo?.region?.regionAbbreviation ?? _.find(regions, r => r.id === quote.billTo?.regionId)?.regionAbbreviation;
    const zipPostalCode = getFormattedZipPostalCode(quote.billTo?.hasBillingAddress ? quote.billTo?.billingZipPostalCode : quote.billTo);

    return (
      <div className={styles.sectionContainer} id="confirmationSignature">
        <div className={styles.sectionHeader}>
          Confirmation Signatures
        </div>
        <div style={{paddingLeft: "10px", fontSize: "10pt"}}>
          We agree to the terms, conditions and recitals set forth in this Shipping Rate Confirmation.
        </div>
        <div className={styles.termsContainer}>
          {
            quote.companyId === CompanyEnum.Logistics ?
              <ul>
                <li>*Kaiser Logistics, Inc. intends to rely on the Total Declared Value provided by the customer to ensure that cargo insurance is adequate in the event of a loss. Kaiser Logistics, Inc. will not be responsible for any loss or damage to cargo in transit or otherwise beyond the Total Declared Value.</li>
                <li>Kaiser Logistics, Inc. shall charge $500.00 for "Truck Ordered But Not Used" and $175.00 per hour for each truck detained more than one (1) hour after arrival at each freight pick-up and/or destination location(s).</li>
                <li>CUSTOMER/SHIPPER/BROKER: All amounts are due and payable to Kaiser Logistics, Inc. in U.S. Dollars within fifteen (15) days of invoice date (subject to credit approval by Kaiser Logistics, Inc. or other Payment Terms as set forth herein). All amounts not paid when due shall be subject to a late fee of one and one-half percent (1.5%) per month or the highest rate of interest permitted by applicable law, whichever is less. In the event Kaiser Logistics, Inc. is required to engage an attorney(s) or collection agency(ies) to collect unpaid amounts from customer, customer agrees to pay all attorneys' and collection agency fees and cost incurred.</li>
                <li>The shipment described herein is accepted by Kaiser Logistics, Inc. acting in its capacity as a licensed property broker under License No. MC-302833 issued to it by the Federal Motor Carrier Safety Administration and Kaiser Logistics, Inc. shall have no liability to the owner of the cargo for loss of or damage to goods in transit under the Carmack Amendment to the Interstate Commerce Act, 49 U.S.C. 14706 and such liability shall be assumed by every licensed motor carrier performing any portion of the transportation. Kaiser Logistics, Inc. reserves the right to charge a 6.37% Insurance Surcharge on all invoices.</li>
              </ul> :
            quote.companyId === CompanyEnum.Transport ?
              <ul>
                <li>*Kaiser Transport, Inc. intends to rely on the Total Declared Value provided by the customer to ensure that cargo insurance is adequate in the event of a loss. Kaiser Transport, Inc. will not be responsible for any loss or damage to cargo in transit or otherwise beyond the Total Declared Value.</li>
                <li>Kaiser Transport, Inc. shall charge $500.00 for "Truck Ordered But Not Used" and $175.00 per hour for each truck detained more than one (1) hour after arrival at each freight pick-up and/or destination location(s).</li>
                <li>CUSTOMER/SHIPPER/BROKER: All amounts are due and payable to Kaiser Transport, Inc. in U.S. Dollars within fifteen (15) days of invoice date (subject to credit approval by Kaiser Transport, Inc. or other Payment Terms as set forth herein). All amounts not paid when due shall be subject to a late fee of one and one-half percent (1.5%) per month or the highest rate of interest permitted by applicable law, whichever is less. In the event Kaiser Transport, Inc. is required to engage an attorney(s) or collection agency(ies) to collect unpaid amounts from customer, customer agrees to pay all attorneys' and collection agency fees and cost incurred.</li>
                <li>Kaiser Transport, Inc. reserves the right to exercise the carrier's lien on freight set forth in the Uniform Commercial Code, and failure to timely pay billed charges may result in a lien on future shipments including cost of storage and appropriate security.</li>
                <li>The shipment described herein may be referred to and accepted by Kaiser Logistics, Inc. acting in its capacity as a freight forwarder under Freight Forwarder Registration No. FF-008453 issued to it by the Federal Motor Carrier Safety Administration. Upon acceptance of the shipment, Kaiser Logistics, Inc. is liable to the owner of the cargo for loss of or damage to goods in transit under the Carmack Amendment to the Interstate Commerce Act, 49 U.S.C. 14706, notwithstanding its use of one or more licensed motor carriers for all or any portion of the transportation. Kaiser Transport, Inc. is a carrier MC# 302831. Kaiser Transport, Inc. reserves the right to charge an Insurance Surcharge of 6.37% on all invoices.</li>
              </ul> : null
          }
        </div>
        <div>
          <table className={styles.signatureTable}>
            <tbody>
              <tr>
                <td colSpan={2}>
                  <div>Customer: <b>{quote.billTo?.customerName ?? null}</b></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div><b>{address1}</b></div>
                  {address2 ? <div><b>{address2}</b></div> : null}
                  <div><b>{`${city ?? null}, ${region ?? null} ${zipPostalCode ?? null}`}</b></div>
                </td>
                <td>
                  <div>Agent: <b>{`${quote.customerContact?.firstName ?? ""} ${quote.customerContact?.lastName ?? ""}`}</b></div>
                  <div>Phone: <b>{`${quote.customerContact?.phoneNumber ?? ""}`}</b></div>
                  <div>Email: <b>{`${quote.customerContact?.emailAddress ?? ""}`}</b></div>
                </td>
                <td>
                  <div className={styles.signature}><i>Customer / Customer Agent Signature</i></div>
                  <div className={styles.date}>
                    <div><i>Date: </i></div>
                    <div style={{width: "100%", borderBottom: "1px solid black"}}></div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  render() {
    const {
      quoteGetResults,
      calculateRatingVariableResults,
      rateEngineResult,
      freightTotalData,
      quote,
      accessorialChargeValueFetchResults
    } = this.props.QuoteEntryService.getState();
    const quoteStops = quote?.quoteStops ?? [];
    const isMultiStop = (quoteStops.length ?? 0) > 1;
    const quoteFreights = quote?.quoteFreights ?? [];
    const showGroupedInfo = quoteFreights.filter(qf => qf.isGrouped).length > 0;
    const accessorialChargeValues = accessorialChargeValueFetchResults.data ? accessorialChargeValueFetchResults.data.filter(d => d.status === "Approved") : [];

    const {
      regionFetchResults
    } = this.props.regionService.getState();
    const regions = regionFetchResults?.data ?? [];

    const {
      disableEmail,
      disableSave,
      pdfGenerating
    } = this.state;

    // make sure everything we need is loaded
    const isFetching = quoteGetResults.isFetching
      || calculateRatingVariableResults.isFetching
      || regionFetchResults.isFetching;

    return (
      <div className={styles.masterContainer}>
        <div className={styles.controlsContainer}>
          <div style={{ textAlign: "right" }}>
            <Button
              onClick={this.emailPdf}
              disabled={isFetching || disableEmail}
              className={styles.pdfButton}
            >
              Email
            </Button>
            <Button
              onClick={this.savePdf}
              disabled={isFetching || disableSave}
              className={styles.pdfButton}
            >
              Save PDF
            </Button>
          </div>
          <AjaxActionIndicator showProgress={isFetching || pdfGenerating} />
        </div>

        
        <table className={styles.contentContainer} >
          <thead>
            <tr><td>
              <div className={styles.pageHeaderSpace}>
                {this.buildMainHeader(quote, quoteStops)} {/*https://stackoverflow.com/questions/41778117/set-margin-padding-for-each-page-to-print-html-css*/}
              </div>
            </td></tr>
          </thead>
          <tbody>
            <tr><td>
              <div className={styles.mainContainer} ref={this.divRef}>
                {this.buildQuoteInfoSection(quote, quoteStops)}
                
                {this.buildShipperConsigneeSection(quoteStops, regions)}

                {this.buildFreightDetailsSection(quoteFreights, freightTotalData, isMultiStop, showGroupedInfo)}

                {this.buildRateDetailsSection(quote, rateEngineResult, accessorialChargeValues)}

                {this.buildConfirmationSignatureSection(quote, regions)}
              </div>
            </td></tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export const PrintQuoteView = QuoteEntryService.inject(
  StateService.inject(
    LoadingInstructionService.inject(
      _PrintQuoteView
    )
  )
);