import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  ZipCodePair,
  Place,
  QuoteStop,
  Quote,
  Customer,
  CustomerContact
} from "$Generated/api";

import {
  IconButton,
  Button
} from "$Imports/MaterialUIComponents";

import {
  Edit,
  Clear,
  Room,
  Announcement
} from "$Imports/MaterialUIIcons";

import {
  AdvanceTextField,
  UserAccessControl
} from "$Imports/CommonComponents";

import {
  AddressDisplay
} from "./AddressDisplay";

import {
  CustomerType,
  QuoteEntryService,
  IQuoteEntryServiceInjectedProps
} from "$State/QuoteEntryFreezerService";

import {
  CustomerService,
  ICustomerServiceInjectedProps
} from "$State/CustomerFreezerService";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  ContactPersonDisplay
} from "../ContactPersonDisplay";

import { getTrimmedZipPostalCode } from "$Shared/utilities/helpers";

const styles: {
  shipperConsigneeDiv: string,
  contactPersonDiv:string,
  label: string,
  announcement:string,
  findButton: string,
  zipCodeDiv: string
} = require("./QuoteStopEntry.scss");

interface IShipperConsigneeEntryBaseProps {
  customerType: CustomerType;
  thisQuoteStopIndex: number;
  quote: Quote;
  quoteStop?: QuoteStop;
  place?: Place;
  viewOnly: boolean;
  onCustomerSearchClick?: (customerType: CustomerType) => void;
  onCustomerClear?: (customerType: CustomerType) => void;
  onCustomerChanged?: (zipcode: string) => void;
  onCustomerCityStateSearchClick?: (customerType: CustomerType) => void;
  onCustomerAlertClick?: (customer: Customer) => void;
  onCustomerContactChanged?: (contactId: number | undefined, customerId: number | undefined, customerType: CustomerType) => void;
  onOpenAddEditContactModal?: (customerId: number | undefined, customerIsCaller: boolean | undefined, customerType: CustomerType, contact?: CustomerContact) => void;
  validationErrors?: ValidationError | null;
}

type IShipperConsigneeEntryProps = IShipperConsigneeEntryBaseProps
& IQuoteEntryServiceInjectedProps
& ICustomerServiceInjectedProps;

class _ShipperConsigneeEntry extends React.Component<IShipperConsigneeEntryProps> {
  @bind
  private _onCustomerSearchClick(customerType: CustomerType) {
    if (this.props.onCustomerSearchClick) {
      this.props.onCustomerSearchClick(customerType);
    }
  }

  @bind
  private _onCustomerClear(customerType: CustomerType) {
    if (this.props.onCustomerClear) {
      this.props.onCustomerClear(customerType);
    }
  }

  @bind
  private _onCustomerChanged(zipcode: string) {
    const trimmedZipCode = getTrimmedZipPostalCode(zipcode);
    if (this.props.onCustomerChanged && trimmedZipCode) {
      this.props.onCustomerChanged(trimmedZipCode);
    }
  }

  @bind
  private _onCustomerCityStateSearchClick(customerType: CustomerType) {
    if (this.props.onCustomerCityStateSearchClick) {
      this.props.onCustomerCityStateSearchClick(customerType);
    }
  }

  @bind
  private _onCustomerAlertClick(customer: Customer) {
    if (this.props.onCustomerAlertClick) {
      this.props.onCustomerAlertClick(customer);
    }
  }

  @bind
  private _onChangeContact(contactId: number | undefined, customerId: number | undefined) {
    const { customerType } = this.props;
    if (this.props.onCustomerContactChanged) {
      this.props.onCustomerContactChanged(contactId, customerId, customerType);
    }
  }

  @bind
  private _openAddEditContactModal(customer: Customer, customerType: CustomerType, contactId?: number) {
    if (customer && this.props.onOpenAddEditContactModal) {
      const { shipperContactsResults, consigneeContactsResults } = this.props.QuoteEntryService.getState();
      const contacts = customerType === "Shipper" ? shipperContactsResults.data : consigneeContactsResults.data;
      const contact = contactId ? _.find(contacts, c => c.id === contactId) : undefined;
      this.props.onOpenAddEditContactModal(customer.id, customer.isCaller, customerType, contact);
    }
  }

  @bind
  private _onOpenEditCustomerModal(customer: Customer) {
    this.props.customerService.openAddEditCustomerModal(
      "ShipperConsigneeEntry",
      customer
    );
  }

  render() {
    const {
      editMode,
      isCityStateSearchModalOpen,
      shipperContactsResults,
      consigneeContactsResults
    } = this.props.QuoteEntryService.getState();
    const {
      thisQuoteStopIndex,
      customerType,
      quote,
      quoteStop,
      place,
      viewOnly,
      validationErrors,
    } = this.props;
    
    const stop = quote.quoteStops && quote.quoteStops.hasOwnProperty(thisQuoteStopIndex) ? quote.quoteStops[thisQuoteStopIndex] : quoteStop;
    const address = _.find(stop?.addresses, adr => (adr.isCurrent === true) && (adr.addressType === customerType));
    const zipPostalCode = address?.zipPostalCode;
    const customer = address?.customer;

    const validationsParserZipcode = new ValidationErrorParser<ZipCodePair>(validationErrors);

    const validationString: keyof ZipCodePair = customerType === "Shipper" ? "originZipPostalCode" : "destZipPostalCode";

    const zipPostalCodeError = !!zipPostalCode && !!place && place?.stateProvince === undefined;
    const disableContact = !editMode ? (viewOnly || quote.status === "Pending" || quote.status === "Canceled" || quote.status === "Accepted" || quote.status === "Declined" || quote.status === "Expired" || quote.status === "AcceptanceRejected") : false;

    let contacts = customerType === "Shipper" ? shipperContactsResults.data ?? [] : consigneeContactsResults.data ?? [];
    const contactId = customerType === "Shipper" ? stop?.shipperContactId : stop?.consigneeContactId;

    return (
      <>
        <div className={styles.shipperConsigneeDiv}>
          {quote.quoteType === "Full" ?
            customer ?
              <div style={{ alignSelf: "flex-end", marginRight: "5px" }}>
                <div className={styles.label}>
                  {customerType}
                  
                  {customer?.displayAlert &&
                    <IconButton onClick={() => this._onCustomerAlertClick(customer)} size="small">
                      <Announcement fontSize="small" className={styles.announcement} />
                    </IconButton>
                  }

                </div>
                <AdvanceTextField
                  size={"small"}
                  disabled={true}
                  title={customer.customerName ?? ""}
                  style={{ width: "18.75rem" }}
                  InputProps={{
                    endAdornment: (
                      <>
                        <IconButton disabled={viewOnly} size="small" onClick={() => this._onOpenEditCustomerModal(customer)}>
                          <Edit />
                        </IconButton>
                        <IconButton disabled={viewOnly} size="small" onClick={() => this._onCustomerClear(customerType)}>
                          <Clear />
                        </IconButton>
                      </>
                    )
                  }}
                  value={customer.customerName ?? ""}
                />
              </div> :
              <Button
                color="secondary"
                variant="outlined"
                className={styles.findButton}
                disabled={viewOnly}
                size="small"
                onClick={() => this._onCustomerSearchClick(customerType)}
              >
                Find&nbsp;{customerType}
              </Button> : 
            ""
          }

          {!customer ?
            <>
              <div className={styles.zipCodeDiv} >
                <div className={styles.label}>{`${customerType} Zipcode`}</div>
                <AdvanceTextField
                  onDebouncedChange={this._onCustomerChanged}
                  debounceWaitTime={750}
                  size={"small"}
                  disabled={viewOnly || !!customer}
                  value={zipPostalCode ?? ""}
                  error={zipPostalCodeError || !validationsParserZipcode.isValidDeep(`[${thisQuoteStopIndex}].${validationString}`)}
                  helperText={zipPostalCodeError ? "Error: No matching ZIP found" : validationsParserZipcode.validationMessageDeep(`[${thisQuoteStopIndex}].${validationString}`)}
                />
              </div>

              <IconButton
                disabled={viewOnly || isCityStateSearchModalOpen || !!customer}
                onClick={() => this._onCustomerCityStateSearchClick(customerType)}
              >
                <Room />
              </IconButton>
            </> :
            ""
          }

          <AddressDisplay regions={undefined} address={address} place={place} />

          {customer ?
            <div style={{ display: "flex", flex: "2" }}>
              <div className={styles.contactPersonDiv}>
                <ContactPersonDisplay 
                  contactId={contactId} 
                  contacts={contacts} 
                  disableContact={disableContact} 
                  viewOnly={viewOnly}
                  customerType={customerType}
                  onChangeContact={(contactId) => this._onChangeContact(contactId, customer.id)}
                  onUpdateContact={(contactId) => this._openAddEditContactModal(customer, customerType, contactId)}
                  thisQuoteStopIndex={thisQuoteStopIndex}
                  validationErrors={validationErrors} />
              </div>
              <div style={{ margin: "0.75rem 0 0 0" }}>
                {!disableContact && (
                    <UserAccessControl roles={["quote:create", "quote:edit"]}>
                      <Button onClick={() => this._openAddEditContactModal(customer, customerType)}>Add</Button>
                    </UserAccessControl>
                )}
              </div>
            </div> :
            ""
          }
        </div>
      </>
    )
  }
}

export const ShipperConsigneeEntry = QuoteEntryService.inject(
  CustomerService.inject(
    _ShipperConsigneeEntry
  ));