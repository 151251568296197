import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  Quote,
  CustomerContact,
} from "$Generated/api";

import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  IconButton,
} from "$Imports/MaterialUIComponents";

import { 
  QuoteEntryService,
  IQuoteEntryServiceInjectedProps,
  CustomerType
} from "$State/QuoteEntryFreezerService";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  Edit,
  MobileScreenShare
} from "$Imports/MaterialUIIcons";

const styles: {
  formControl: string,
  contactContainer: string,
  editContactHidden: string,
  iconButton: string,
  phoneContainer: string,
  phoneIcon: string,
  error: string
} = require("./ContactPersonDisplay.scss"); 

interface IContactPersonDisplayBaseProps {
  contactId: number | undefined,
  contacts: CustomerContact[],
  disableContact: boolean,
  viewOnly: boolean,
  customerType: CustomerType,
  onChangeContact: (contactId: number | undefined) => void,
  onUpdateContact: (contactId: number | undefined) => void,
  validationErrors?: ValidationError | null,
  thisQuoteStopIndex?: number
}

type IContactPersonDisplayProps = IContactPersonDisplayBaseProps & IQuoteEntryServiceInjectedProps;

interface IContactPersonDisplayState {
  displayCellNumber: boolean;
}

export class _ContactPersonDisplay extends React.PureComponent<IContactPersonDisplayProps, IContactPersonDisplayState> {
  state: IContactPersonDisplayState = {
    displayCellNumber: false
  }

  @bind
  private _onChangeContact(event: SelectChangeEvent<number | string>) {
    this.props.onChangeContact(event.target.value ? event.target.value as number : undefined);
    this.setState({
      displayCellNumber: false
    });
  }

  @bind
  private _onPhoneIconClick() {
    this.setState({
      displayCellNumber: !this.state.displayCellNumber
    });
  }

  @bind
  private _onUpdateContact(contactId: number | undefined) {
    this.props.onUpdateContact(contactId);
  }

  render() {
    const {
      displayCellNumber
    } = this.state;

    const {
      contactId,
      contacts,
      disableContact,
      viewOnly,
      customerType,
      validationErrors,
      thisQuoteStopIndex
    } = this.props;    

    const selectedContact = _.find(contacts, c => c.id == contactId);
    const validationsParserQuote = new ValidationErrorParser<Quote>(validationErrors);
    const isDisabled = customerType === "Caller" ? disableContact || viewOnly : disableContact;
    
    return (
      <>  
        <div className={selectedContact ? styles.contactContainer : styles.editContactHidden}>
          <FormControl className={styles.formControl} 
            error={thisQuoteStopIndex !== undefined ? customerType === "Shipper" 
                ? !validationsParserQuote.isValidDeep(`quoteStops[${thisQuoteStopIndex}].shipperContactId`) 
                : customerType === "Consignee"
                  ?!validationsParserQuote.isValidDeep(`quoteStops[${thisQuoteStopIndex}].consigneeContactId`)
                  : false
              : false}
          >
            <InputLabel>Contact Person</InputLabel>
              <Select
                value={selectedContact?.id ?? ""}
                required
                onChange={this._onChangeContact}
                disabled={isDisabled}
              >
                {contacts.map((c, idx) =>
                  <MenuItem value={c.id} key={idx}>
                    {`${c.firstName} ${c.lastName}`}
                  </MenuItem>
                )}
              </Select>
          </FormControl>
          {selectedContact &&
            <IconButton className={styles.iconButton} disabled={viewOnly} size="small" onClick={() => this._onUpdateContact(selectedContact?.id ?? undefined)}>
              <Edit />
            </IconButton>}
        </div>
        {selectedContact ?
          <>
            {customerType === "Caller" ? <div>{selectedContact.contactType?.type} - {selectedContact.title}{selectedContact.isPrimary ? " (Primary)" : ""}</div> : ""}
            <div className={styles.phoneContainer}>
              {displayCellNumber ? 
                <div><b>Cell:</b>  {selectedContact.cellNumber}</div> : 
                <div><b>Phone:</b> {selectedContact.phoneNumber ?? ""}</div>
              }
              {selectedContact.cellNumber && 
                  <IconButton
                    size="small"
                    onClick={() => this._onPhoneIconClick()}
                  >
                    <MobileScreenShare className={styles.phoneIcon} />
                  </IconButton>
              }
            </div>
            <div><b>Email:</b> {selectedContact.emailAddress ?? ""}</div>
          </>
          :
          !disableContact && <div className={styles.error}>No contact assigned</div>
        }
      </>
    )
  }
}

export const ContactPersonDisplay = QuoteEntryService.inject(_ContactPersonDisplay);