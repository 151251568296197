import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  Customer,
} from "$Generated/api";

import {
  Button,
  Tabs,
  Tab,
  Grid,
  Avatar
} from "$Imports/MaterialUIComponents";

import {
  ThemeProvider,
  createTheme
} from "$Imports/MaterialUIStyles"

import {
  ThemeConsumer
} from "$Providers/index";

import {
  AjaxActionIndicator,
  CardLinedHeader,
  UserAccessControl,
  ReminderListView,
  AddEditCustomerModal
} from "$Imports/CommonComponents";

import {
  ISalesRepHomeServiceInjectedProps,
  SalesRepHomeService,
} from "$State/SalesRepHomeFreezerService";

import {
  CustomerService,
  ICustomerServiceInjectedProps
} from "$State/CustomerFreezerService";

import {
  StateService,
  IStateServiceInjectedProps
} from "$State/RegionFreezerService";

import {
  EmployeeService,
  IEmployeeServiceInjectedProps
} from "$State/EmployeeFreezerService";

import {
  LoadingInstructionService,
  ILoadingInstructionServiceInjectedProps
} from "$State/LoadingInstructionFreezerService";

import {
  ICustomerQuoteServiceInjectedProps,
  CustomerQuoteService,
  DEFAULT_CUSTOMER_QUOTE_SEARCH
} from "$State/CustomerQuotesFreezerService";

import {
  CustomerSourceService,
  ICustomerSourceServiceInjectedProps
} from "$State/CustomerSourceFreezerService";

import {
  QuoteEntryService
} from "$State/QuoteEntryFreezerService";

import {
  NavigationService
} from "$State/NavigationFreezerService";

import {
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings";

import {
  CustomerSearchModal
} from "$Pages/SalesPortalView/CustomerSearchModal";

import {
  ISelectedCompanyContext
} from "$Providers/CompanyProvider";

import {
  MyQuotes
} from "./MyCustomers/MyQuotes";

import {
  MyCustomerQuotes
} from "./MyCustomers/MyCustomerQuotes";

import {
  MyMetrics
} from "./MyMetrics/MyMetrics";

import {
  MyCustomers
} from "./MyCustomers/MyCustomers";

import {
  MySalesPipeline
} from "./MyCustomers/MySalesPipeline";

import {
  MyProspects
} from "./MyCustomers/MyProspects";

const styles: {
  avatar: string;
} = require("./SalesRepHomeView.scss");

const containerStyles: {
  mainContainer: string;
} = require("$Shared/styles/Modal.scss");

interface ISalesRepHomeViewState {
  customersTab: "Quotes" | "QuickQuotes" | "CustomerQuotes" | "Customers" | "SalesPipeline" | "Prospects";
  isCustomerSearchModalOpen: boolean;
  isAddEditCustomerModalOpen: boolean;
  addProspect: Customer | null;
}

interface ISalesRepHomeViewBaseProps {
  companyContext: ISelectedCompanyContext;
}

type ISalesRepHomeViewProps = ISalesRepHomeViewBaseProps
  & ISalesRepHomeServiceInjectedProps
  & ICustomerServiceInjectedProps
  & IStateServiceInjectedProps
  & IEmployeeServiceInjectedProps
  & ICustomerQuoteServiceInjectedProps
  & ILoadingInstructionServiceInjectedProps
  & ICustomerSourceServiceInjectedProps;

class _SalesRepHomeView extends React.Component<ISalesRepHomeViewProps, ISalesRepHomeViewState> {

  state: ISalesRepHomeViewState = {
    customersTab: "Quotes",
    isCustomerSearchModalOpen: false,
    isAddEditCustomerModalOpen: false,
    addProspect: null
  };

  componentDidMount() {
    this.props.customerQuotesService.clearQuotesFetchResults();
    this.props.customerQuotesService.onResetSearchModel({ companyId: this.props.companyContext.companyId, ...DEFAULT_CUSTOMER_QUOTE_SEARCH });
    this.props.regionService.fetchStates();
    const userId = this._getCurrentEmployeeId();
    this.props.customerQuotesService.getRequestedQuotesCount(this.props.companyContext.companyId, userId);
    this.props.loadingInstructionService.fetchLoadingInstructions(true);
    this.props.customerSourceService.fetchCustomerSources(true, true);
  }

  componentDidUpdate(prevProps: Readonly<ISalesRepHomeViewProps>) {
    if (this.props.companyContext.companyId !== prevProps.companyContext.companyId) {
      if (this.state.customersTab !== "CustomerQuotes") {
        const userId = this._getCurrentEmployeeId();
        this.props.customerQuotesService.clearQuotesFetchResults();
        this.props.customerQuotesService.getRequestedQuotesCount(this.props.companyContext.companyId, userId);
      }
      this.props.customerQuotesService.freezer.get().set({
        searchCriteria: {
          companyId: this.props.companyContext.companyId,
          ...DEFAULT_CUSTOMER_QUOTE_SEARCH
        }
      });
    }
  }

  componentWillUnmount() {
    this.props.customerQuotesService.clearRequestedQuotesCount();  
    this.props.customerQuotesService.freezer.get().set({ 
      searchCriteria: { ...DEFAULT_CUSTOMER_QUOTE_SEARCH }
    });
  }

  @bind
  private _onCustomersTabChange(event: React.ChangeEvent<{}>, value: any) {
    this.setState({ customersTab: value });
  }

  @bind
  private _openAddCustomerModal() {
    const currentUserId = this._getCurrentEmployeeId();
    this.props.customerService.openAddEditCustomerModal("SalesRepHomeView", {
      isCaller: true,
      isActive: true,
      isProspect: false,
      isBillTo: false,
      billingStatus: "CheckCredit",
      accountManagerId: currentUserId,
      businessDevManagerId: currentUserId
    });
  }

  @bind
  private _cancelAddCustomerModal() {
    this.props.customerService.closeAddEditModal();
  }

  @bind
  private async _saveCustomer(customer: Customer) {
    await this.props.customerService.addCustomer(customer);

    const result = this.props.customerService.getState().saveCustomerResults;

    if (result?.data) {
      if (this.state.customersTab === "Quotes" || this.state.customersTab === "QuickQuotes") {
        this._createQuoteForCustomer(result.data);
      }
      else {
        this.props.salesRepHomeService.fetchCustomers(true);
      }

      this.props.customerService.closeAddEditModal();
    }
  }

  @bind
  private _addProspectClick() {
    const currentUserId = this._getCurrentEmployeeId();
    const blankCustomer: Customer = {
      isActive: true,
      isProspect: true,
      isCaller: true,
      isBillTo: false,
      billingStatus: "CheckCredit",
      accountManagerId: currentUserId,
      businessDevManagerId: currentUserId,
      prospect: {} 
    };

    this.setState({
      isAddEditCustomerModalOpen: true,
      addProspect: blankCustomer
    });
  }

  @bind
  private async _saveCustomerProspect(customer: Customer | undefined) {
    if (!customer){
      return
    }

    await this.props.customerService.addCustomer(customer);

    const result = this.props.customerService.getState().saveCustomerResults;

    if (result?.data) {
      this.props.salesRepHomeService.fetchCustomers(true);
      this._closeAddEditCustomerModal();
    }
  }

  @bind
  private _closeAddEditCustomerModal() {
    this.setState({
      isAddEditCustomerModalOpen: false,
      addProspect: null
    });
  }

  @bind
  private _openCustomerSearchModal() {
    this.setState({
      isCustomerSearchModalOpen: true
    })
  }

  @bind
  private _closeCustomerSearchModal() {
    this.setState({
      isCustomerSearchModalOpen: false
    });
  }

  @bind
  private _selectQuickQuoteCustomer() {
    const { selectedRow } = this.props.customerService.getState();

    this._createQuoteForCustomer(selectedRow);
  }

  @bind
  private async _createQuoteForCustomer(customer: Customer | null) {
    if (!customer) {
      return;
    }

    const isActiveCustomer = await this.props.customerService.customerIsActiveInTruckMate(customer.tmcustomerId);
    if (isActiveCustomer) {
      QuoteEntryService.setSelectedCustomer(customer);
      this._closeCustomerSearchModal();
      NavigationService.navigateTo("/salesportal");
    } else {
      this._closeCustomerSearchModal();
    }
  }

  @bind
  private _createQuickQuote() {
    NavigationService.navigateTo("/salesportal");
  }
  
  @bind
  private _getCurrentEmployeeId(): number | undefined {
    const {
      accOrBusDevManagerFetchResults
    } = this.props.employeeService.getState();

    let userId: number | undefined = undefined;
    if (accOrBusDevManagerFetchResults.data && SharedSecurityContext.getUserId()) {
      const currentEmployee = _.find(accOrBusDevManagerFetchResults.data, (e) => e.userId?.toLowerCase() === SharedSecurityContext.getUserId()?.toLowerCase());
      userId = currentEmployee?.id;
    }

    return userId;
  }

  render() {
    const {
      isAddEditCustomerModalOpen,
      addProspect
    } = this.state;
    
    const {
      customerModalIsOpen,
      addEditCustomer,
      saveCustomerResults,
      searchResults,
      selectedRow
    } = this.props.customerService.getState();

    const {
      quotesFetchResults
    } = this.props.customerQuotesService.getState();

    const {
      quoteFetchResults,
      customerFetchResults,
      opportunityFetchResults
    } = this.props.salesRepHomeService.getState();
    // I'm choosing to not make this styled tab control a reusable component at this time
    // because I don't want to guess how it might be used in the future
    // cross that bridge when we get to it

    const {
      regionFetchResults
    } = this.props.regionService.getState();
    const {
      activeSalesReps,
      activeAccOrBusDevManagers
    } = this.props.employeeService.getState();
    const regions = regionFetchResults.data ?? [];
    const salesReps = _.orderBy(activeSalesReps ?? [], s => s.lastName);
    const accOrBusDevManagers = _.orderBy(activeAccOrBusDevManagers ?? [], s => s.firstName);

    const {
      customerSourceFetchResults
    } = this.props.customerSourceService.getState();
    const customerSources = _.orderBy(customerSourceFetchResults.data ?? [], s => s.name);

    const isAddEditModalOpen = (customerModalIsOpen === "SalesRepHomeView") ||
      (customerModalIsOpen === "CustomerSearchModal");

    const currentUserId = this._getCurrentEmployeeId();

    const quickQuoteAddSeed: Partial<Customer> = {
      isCaller: true,
      isActive: true,
      accountManagerId: currentUserId,
    };

    const {
      requestedQuotesFetchResults
    } = this.props.customerQuotesService.getState();
    if (currentUserId && !requestedQuotesFetchResults.isFetching && !requestedQuotesFetchResults.hasFetched && !requestedQuotesFetchResults.data) {
      this.props.customerQuotesService.getRequestedQuotesCount(this.props.companyContext.companyId, currentUserId);
    }
    const customerQuotesCount = quotesFetchResults.data?.totalRecords ?? requestedQuotesFetchResults.data;

    return (
      <>
        <Grid className={containerStyles.mainContainer} container>
          <Grid item xs={7} style={{ display: "flex", flexDirection: "column" }}>
            <CardLinedHeader
              titleText="My Customers"
              titleComponents={
                <div style={{ marginBottom: "0.5rem" }}>
                  <UserAccessControl roles={["quote:create"]}>
                    {(this.state.customersTab === "Customers" || this.state.customersTab === "SalesPipeline") &&
                    <Button onClick={this._openAddCustomerModal}>Add Customer</Button>}
                    {this.state.customersTab === "Prospects" &&
                    <Button onClick={this._addProspectClick}>Add Prospect</Button>}
                    {(this.state.customersTab === "Quotes" || this.state.customersTab ===  "QuickQuotes") &&
                    <>
                      <Button onClick={this._openCustomerSearchModal}>New Full Quote</Button>
                      <Button onClick={this._createQuickQuote} style={{ marginLeft: "0.5rem" }}>New Quick Quote</Button>
                    </>
                    }
                  </UserAccessControl>
                </div>
              }
            >
              <AjaxActionIndicator
                state={[quoteFetchResults, quotesFetchResults, customerFetchResults, opportunityFetchResults]}
              />
              <ThemeConsumer>{(themeContext) => {
                const tabTheme = createTheme(themeContext.themeConfig.themeOptions, themeContext.themeConfig.coloredTabControl);

                return (
                  <ThemeProvider theme={tabTheme}>
                    <Tabs
                      value={this.state.customersTab}
                      onChange={this._onCustomersTabChange}
                    >
                      <Tab label="Full Quotes" value="Quotes" />
                      <Tab label="Quick Quotes" value="QuickQuotes" />
                      <Tab label="Customer Quotes" value="CustomerQuotes" icon={customerQuotesCount && customerQuotesCount > 0 ? <Avatar className={styles.avatar}>{customerQuotesCount}</Avatar> : ""} iconPosition="end" />
                      <Tab label="Customers" value="Customers" />
                      <Tab label="Sales Pipeline" value="SalesPipeline" />
                      <Tab label="Prospects" value="Prospects" />
                    </Tabs>
                  </ThemeProvider>
                )
              }}</ThemeConsumer>
              {
                this.state.customersTab === "Quotes" &&
                <MyQuotes
                  companyId={this.props.companyContext.companyId} quoteType={"Full"} />
              }
              {
                this.state.customersTab === "QuickQuotes" &&
                <MyQuotes
                  companyId={this.props.companyContext.companyId} quoteType={"Quick"} />
              }
              {
                this.state.customersTab === "CustomerQuotes" &&
                <MyCustomerQuotes
                  companyId={this.props.companyContext.companyId} currentUserId={currentUserId} />
              }
              {
                this.state.customersTab === "Customers" &&
                <MyCustomers />
              }
              {
                this.state.customersTab === "SalesPipeline" &&
                <MySalesPipeline salesReps={salesReps} companyHighlightColor={this.props.companyContext.highlightColor} />
              }
              {
                this.state.customersTab === "Prospects" &&
                <MyProspects />
              }
            </CardLinedHeader>

            <ReminderListView salesReps={salesReps} companyId={this.props.companyContext.companyId} />
          </Grid>
          <Grid item xs={5}>
            <MyMetrics companyId={this.props.companyContext.companyId} />
          </Grid>
        </Grid>

        <AddEditCustomerModal
          isOpen={isAddEditModalOpen}
          isFetching={saveCustomerResults.isFetching}
          customer={addEditCustomer ?? {}}
          regions={regions}
          accOrBusDevManagers={accOrBusDevManagers}
          customerSources={customerSources}
          onSave={this._saveCustomer}
          onCancel={this._cancelAddCustomerModal}
        />
        
        <AddEditCustomerModal
          isOpen={isAddEditCustomerModalOpen}
          isFetching={saveCustomerResults.isFetching}
          customer={addProspect ?? {}}
          regions={regions}
          accOrBusDevManagers={accOrBusDevManagers}
          customerSources={customerSources}
          onSave={this._saveCustomerProspect}
          onCancel={this._closeAddEditCustomerModal}
        />

        <CustomerSearchModal
          isOpen={this.state.isCustomerSearchModalOpen}
          customerType="Caller"
          onCustomerModalClose={this._closeCustomerSearchModal}
          onCustomerSelect={this._selectQuickQuoteCustomer}
          selectedRow={selectedRow}
          canAdd={searchResults.hasFetched}
          addSeed={quickQuoteAddSeed}
          canExpandSearch={false}
          hiddenColumns={["isCaller", "isShipper", "isConsignee"]}
          searchAll
        />
      </>
    );
  }
}

export const SalesRepHomeView = SalesRepHomeService.inject(
  CustomerService.inject(
    StateService.inject(
      EmployeeService.inject(
        CustomerQuoteService.inject(
          LoadingInstructionService.inject(
            CustomerSourceService.inject(
              _SalesRepHomeView
            )
          )
        )
      )
    )
  )
);
