import {
  React,
  _
} from "$Imports/Imports";

import {
  QuotesBillingStatusView
} from "./QuotesBillingStatusView";

import { CustomerBillingStatusEnum } from "$Generated/api";

interface IQuotesBillingStatusViewPageProps {
  billingStatus: CustomerBillingStatusEnum;
}

export class QuotesBillingStatusViewPage extends React.Component<IQuotesBillingStatusViewPageProps> {
  render() {
    return (
      <QuotesBillingStatusView quoteType={"Full"} billingStatus={this.props.billingStatus} />
    );
  }
}
