import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  ShipperConsigneeEntry
} from "./ShipperConsigneeEntry";

import {
  Customer,
  CustomerContact,
  Place,
  Quote,
  QuoteStop
} from "$Generated/api";

import { 
  CustomerType,
  QuoteEntryService, 
  IQuoteEntryServiceInjectedProps, 
  AlertCustomerType
} from "$State/QuoteEntryFreezerService";

import {
  CustomerContactModal,
  MetricCell
} from "$Imports/CommonComponents";

import {
  ICustomerDetailServiceInjectedProps,
  CustomerDetailService,
  CustomerContactValidationSchema
} from "$State/CustomerDetailFreezerService";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

const styles: {
  subSection: string,
  addressesContainer: string,
  milesCard: string
} = require("./QuoteStopAddressesEntry.scss");

interface IQuoteStopAddressesEntryState {
  addEditContactModalIsOpen: boolean;
  addEditContact: CustomerContact | undefined;
  contactValidationErrors: ValidationError | null;
  contactCustomerType: CustomerType | undefined;
  isEmailRequired: boolean;
}

interface IQuoteStopAddressesEntryBaseProps {
  quote: Quote;
  quoteStop: QuoteStop;
  shipperPlace: Place | undefined;
  consigneePlace: Place | undefined;
  thisQuoteStopIndex: number;
  themeColor: string;
  viewOnly: boolean;
  onCustomerAlertClick: (alertCustomers: Customer[], alertCustomerType: AlertCustomerType) => void;
  onZipCodeChange: (zipcode: string) => void;
}

type IQuoteStopAddressesEntryProps = IQuoteStopAddressesEntryBaseProps
& IQuoteEntryServiceInjectedProps
& ICustomerDetailServiceInjectedProps;

export class _QuoteStopAddressesEntry extends React.PureComponent<IQuoteStopAddressesEntryProps, IQuoteStopAddressesEntryState> {
  state: IQuoteStopAddressesEntryState = {
    addEditContactModalIsOpen: false,
    addEditContact: undefined,
    contactValidationErrors: null,
    contactCustomerType: undefined,
    isEmailRequired: false
  };

  @bind
  private _onAddressChanged(zipcode: string, customerType: Exclude<CustomerType, "Caller">) {
    this.props.QuoteEntryService.onPlaceChanged(zipcode, customerType);
    
    if (this.props.quote.quoteType === "Full" && zipcode.length === 5) {
      this.props.onZipCodeChange(zipcode);
    }
  }

  @bind
  private _onCustomerSearchClick(customerType: CustomerType) {
    this.props.QuoteEntryService.onCustomerSearchModalOpen(customerType);
  }

  @bind
  private _onCustomerCityStateSearchClick(customerType: CustomerType) {
    this.props.QuoteEntryService.onCustomerCityStateSearchOpen(customerType);
  }

  @bind
  private _onCustomerClear(customerType: CustomerType) {
    this.props.QuoteEntryService.onCustomerClear(customerType);
  }

  @bind
  private _onCustomerContactChanged(contactId: number | undefined, customerId: number | undefined, customerType: CustomerType) {
    this.props.QuoteEntryService.updateAllQuoteStopsCustomerTypeContacts(customerType, contactId, customerId);
  }

  @bind
  private _openAddEditContactModal(customerId: number | undefined, customerIsCaller: boolean | undefined, customerType: CustomerType, contact?: CustomerContact) {
    const addEditContact = contact ?? {
      isActive: true,
      customerId: customerId
    };

    this.setState({
      addEditContactModalIsOpen: true,
      contactValidationErrors: null,
      contactCustomerType: customerType,
      isEmailRequired: customerIsCaller ?? false,
      addEditContact: addEditContact
    });
  }

  @bind
  private _closeAddEditContactModal() {
    this.setState({
      addEditContactModalIsOpen: false,
      contactValidationErrors: null,
      addEditContact: undefined
    });
  }

  @bind
  private async _saveContact() {
    if (!this.state.addEditContact) {
      return;
    }

    const errors = await validateSchema(CustomerContactValidationSchema, this.state.addEditContact, {
      abortEarly: false,
      context: { emailRequired: this.state.isEmailRequired }});
    this.setState({ contactValidationErrors: errors });

    if (errors) {
      return;
    }

    await this.props.QuoteEntryService.saveContact(this.state.addEditContact, this.state.contactCustomerType);

    this._closeAddEditContactModal();
  }

  @bind
  private _addContactChange(contact: Partial<CustomerContact>) {
    this.setState((prev) => ({
      addEditContact: {
        ...prev.addEditContact,
        ...contact
      }
    }));
  }

  render() {
    const {
      shipperConsigneeValidationErrors
    } = this.props.QuoteEntryService.getState();

    const {
      quote,
      quoteStop,
      shipperPlace,
      consigneePlace,
      themeColor,
      thisQuoteStopIndex,
      viewOnly,
      onCustomerAlertClick
    } = this.props;

    const {
      isEmailRequired
    } = this.state;

    const {
      nameSuffixFetchResults,
      contactTypeFetchResults
    } = this.props.customerDetailService.getState();

    let suffixData = nameSuffixFetchResults.data ?? [];
    suffixData = _.orderBy(suffixData, s => s.suffixValue);
    let contactTypeData = contactTypeFetchResults.data ?? [];
    contactTypeData = _.orderBy(contactTypeData, c => c.type);

    return (      
      <>
        <div className={styles.subSection} style={{ backgroundColor: themeColor }}>Address Information</div>
        <div className={styles.addressesContainer}>
          <div style={{width: "100%"}}>
              <ShipperConsigneeEntry
                thisQuoteStopIndex={thisQuoteStopIndex}
                customerType={"Shipper"}
                quote={quote}
                quoteStop={quoteStop}
                place={shipperPlace}
                viewOnly={viewOnly}
                onCustomerSearchClick={this._onCustomerSearchClick}
                onCustomerClear={this._onCustomerClear}
                onCustomerChanged={(zipcode) => this._onAddressChanged(zipcode, "Shipper")}
                onCustomerCityStateSearchClick={this._onCustomerCityStateSearchClick}
                onCustomerAlertClick={(customer) => onCustomerAlertClick([customer], "Shipper")}
                onCustomerContactChanged={this._onCustomerContactChanged}
                onOpenAddEditContactModal={this._openAddEditContactModal}
                validationErrors={shipperConsigneeValidationErrors}
              />
            <br />
              <ShipperConsigneeEntry
                thisQuoteStopIndex={thisQuoteStopIndex}
                customerType={"Consignee"}
                quote={quote}
                quoteStop={quoteStop}
                place={consigneePlace}
                viewOnly={viewOnly}
                onCustomerSearchClick={this._onCustomerSearchClick}
                onCustomerClear={this._onCustomerClear}
                onCustomerChanged={(zipcode) => this._onAddressChanged(zipcode, "Consignee")}
                onCustomerCityStateSearchClick={this._onCustomerCityStateSearchClick}
                onCustomerAlertClick={(customer) => onCustomerAlertClick([customer], "Consignee")}
                onCustomerContactChanged={this._onCustomerContactChanged}
                onOpenAddEditContactModal={this._openAddEditContactModal}
                validationErrors={shipperConsigneeValidationErrors} 
              />
          </div>
          {quote.quoteType === "Quick" && 
            <div className={styles.milesCard}>
              <MetricCell
                title="Miles"
                majorValue={quote?.miles?.toString() ?? "-"}
                xs={0}
              />
            </div>
          }
        </div>

        <CustomerContactModal
          isOpen={this.state.addEditContactModalIsOpen}
          contact={this.state.addEditContact}
          validationErrors={this.state.contactValidationErrors}
          onSave={this._saveContact}
          onCancel={this._closeAddEditContactModal}
          onChange={this._addContactChange}
          suffixes={suffixData}
          contactTypes={contactTypeData}
          isEmailRequired={isEmailRequired}
          fromQuote
        />
      </>
    );
  }
}

export const QuoteStopAddressesEntry = QuoteEntryService.inject(
  CustomerDetailService.inject(_QuoteStopAddressesEntry));