import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState
} from "$Imports/Imports";

import {
  Employee,
  EmployeeApiFactory,
  EmployeeSearchCriteria
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  ErrorService
} from "./ErrorFreezerService";

interface IEmployeeServiceState {
  employeeFetchResults: IAjaxState<Employee[]>;
  accOrBusDevManagerFetchResults: IAjaxState<Employee[]>;
  salesRepFetchResults: IAjaxState<Employee[]>;
  carrierRepFetchResults: IAjaxState<Employee[]>;
  activeAccOrBusDevManagers: Employee[];
  activeSalesReps: Employee[];
  activeCarrierReps: Employee[];
}

const InjectedPropName = "employeeService";

const initialState = {
  employeeFetchResults: managedAjaxUtil.createInitialState(),
  accOrBusDevManagerFetchResults: managedAjaxUtil.createInitialState(),
  salesRepFetchResults: managedAjaxUtil.createInitialState(),
  carrierRepFetchResults: managedAjaxUtil.createInitialState(),
  activeAccOrBusDevManagers: [],
  activeSalesReps: [],
  activeCarrierReps: []
} as IEmployeeServiceState;

class EmployeeFreezerService extends FreezerService<IEmployeeServiceState, typeof InjectedPropName> {
  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  private clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public async fetchAllEmployees(forceUpdate: boolean = false) {
    const { employeeFetchResults } = this.freezer.get();

    if (employeeFetchResults.hasFetched && !forceUpdate) {
      return;
    }

    return this.fetchEmployees("employeeFetchResults", "employees", { includeInactive: true });
  }

  public async fetchSalesReps(forceUpdate: boolean = false) {
    const { salesRepFetchResults } = this.freezer.get();

    if (salesRepFetchResults.hasFetched && !forceUpdate) {
      return;
    }

    return this.fetchEmployees("salesRepFetchResults", "sales representatives", { isSalesRep: true, includeInactive: true });
  }

  public async fetchAccOrBusDevManagers(forceUpdate: boolean = false) {
    const { accOrBusDevManagerFetchResults } = this.freezer.get();

    if (accOrBusDevManagerFetchResults.hasFetched && !forceUpdate) {
      return;
    }

    return this.fetchEmployees("accOrBusDevManagerFetchResults", "sales representatives", { isAccOrBusDevManager: true, includeInactive: true });
  }

  public async fetchCarrierReps(forceUpdate: boolean = false) {
    const { carrierRepFetchResults: carrierRepFetchResults } = this.freezer.get();

    if (carrierRepFetchResults.hasFetched && !forceUpdate) {
      return;
    }

    return this.fetchEmployees("carrierRepFetchResults", "carrier managers", { isCarrierRep: true, includeInactive: true });
  }

  private async fetchEmployees(property: keyof IEmployeeServiceState, type: string, criteria: EmployeeSearchCriteria) {
    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: property,
      params: {
        body: criteria
      },
      onExecute: (apiOptions, params, option) => {
        const factory = EmployeeApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.searchEmployees(params);
      },
      onOk: (data: Employee[]) => {
        if(property === "carrierRepFetchResults" || property === "salesRepFetchResults" || property === "accOrBusDevManagerFetchResults") {
          const activeProp = property === "accOrBusDevManagerFetchResults" ? "activeAccOrBusDevManagers" : property === "carrierRepFetchResults" ? "activeCarrierReps" : "activeSalesReps";
          this.freezer.get().set({
            [activeProp]: data.filter(e => e.isActive)
          });
        }
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage(`Failed to fetch ${type}`);
      }
    });
  }

  public getCurrentEmployeeId(userGuid: string | undefined):number | undefined {
    const { salesRepFetchResults } = this.freezer.get();

    let salesRepId: number | undefined = undefined;
      if (salesRepFetchResults.data && userGuid) {
        const currentEmployee = _.find(salesRepFetchResults.data, (e) => e.userId?.toLowerCase() === userGuid?.toLowerCase());
        salesRepId = currentEmployee?.id;
      }

      return salesRepId;
  }
}

export const EmployeeService = new EmployeeFreezerService();
export type IEmployeeServiceInjectedProps = ReturnType<EmployeeFreezerService["getPropsForInjection"]>;
