import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  AddEditCustomerModal,
  AjaxActionIndicator,
  BinaryChoiceDialog,
  CompanyIcon,
  CustomerLink,
  DisplayFormattedDatetime,
  DisplayFormattedNumber
} from "$Imports/CommonComponents";

import {
  Card,
  CardActions,
  CardHeader,
  GridColDef,
  GridRenderCellParams,
  DataGridPro,
  GridSortModel
} from "$Imports/MaterialUIComponents";

import {
  QuoteQuoteTypeEnum,
  BillingStatusQuote,
  Customer,
  CustomerBillingStatusEnum
} from "$Generated/api";

import {
  QuoteEntryService,
  IQuoteEntryServiceInjectedProps,
} from "$State/QuoteEntryFreezerService";

import {
  IQuoteServiceInjectedProps,
  QuoteService
} from "$State/QuoteFreezerService";

import {
  ICustomerServiceInjectedProps,
  CustomerService
} from "$State/CustomerFreezerService";

import {
  StateService,
  IStateServiceInjectedProps
} from "$State/RegionFreezerService";

import {
  EmployeeService,
  IEmployeeServiceInjectedProps
} from "$State/EmployeeFreezerService";

import {
  LoadingInstructionService,
  ILoadingInstructionServiceInjectedProps
} from "$State/LoadingInstructionFreezerService";

import {
  CustomerSourceService,
  ICustomerSourceServiceInjectedProps
} from "$State/CustomerSourceFreezerService";

import {
  ActionMenu
} from "./ActionMenu";

import {
  QuotesBillingStatusSearchForm
} from "./QuotesBillingStatusSearchForm";

import {
  DATE_WITH_TIME_MERIDIAN_FORMAT,
  DATE_ONLY_FORMAT,
  CURRENCY_FORMAT
} from "$Shared/utilities/formatUtil";

import { getFormattedZipPostalCode } from "$Shared/utilities/helpers";
import { billingStatusEnumTextMap } from "$Utilities/enumUtil";

const styles: {
  mainContainer: string;
  cardStyle: string;
  resultsGrid: string;
  resultsMessage: string;
  resultCell: string;
} = require("./QuotesBillingStatusView.scss");

interface IQuotesBillingStatusViewBaseProps {
  quoteType: QuoteQuoteTypeEnum;
  billingStatus: CustomerBillingStatusEnum;
}

interface IQuotesBillingStatusViewState {
  paymentConfirmationModalIsOpen: boolean;
  paymentConfirmationMessage: string;
  paymentConfirmationQuoteId?: number;
}

type IQuotesBillingStatusViewProps = IQuotesBillingStatusViewBaseProps
  & IQuoteEntryServiceInjectedProps
  & IQuoteServiceInjectedProps
  & ICustomerServiceInjectedProps
  & IStateServiceInjectedProps
  & IEmployeeServiceInjectedProps
  & ILoadingInstructionServiceInjectedProps
  & ICustomerSourceServiceInjectedProps;

const companyLogoCol = {
  headerName: "",
  field: "companyKey",
  disableColumnMenu: true,
  width: 28,
  sortable: false,
  renderCell: (params: GridRenderCellParams<any, BillingStatusQuote>) => {
    return <CompanyIcon companyKey={params.value}/>
  }
};

const freightBillNoCol = {
  headerName: "Freight Bill #",
  field: "freightBillNumber",
  width: 100
};

const quoteDateCol = {
  headerName: "Quote Date",
  field: "quoteDate",
  renderCell: (params: GridRenderCellParams<Date, BillingStatusQuote>) => {
    return <DisplayFormattedDatetime value={params.value} formatString={DATE_WITH_TIME_MERIDIAN_FORMAT} showTimeZone />;
  },
  width: 110
};

const pickupDateCol = {
  headerName: "Pickup Date",
  field: "pickupDate",
  width: 110,
  renderCell: (params: GridRenderCellParams<Date, BillingStatusQuote>) => {
    return params.row.showPickupTime
      ? <DisplayFormattedDatetime value={params.value} formatString={DATE_WITH_TIME_MERIDIAN_FORMAT} manualOffset={true} showTimeZone />
      : <DisplayFormattedDatetime value={params.value} formatString={DATE_ONLY_FORMAT} manualOffset={true} />;
  }
};

const negotiatedRateCol = {
  headerName: "Negotiated Rate",
  field: "negotiatedRate",
  renderCell: (params: GridRenderCellParams<number | undefined>) =>
    params.value && <DisplayFormattedNumber value={params.value} formatString={CURRENCY_FORMAT} />,
  flex: 1
};

const salesRepCol = {
  headerName: "Sales Rep",
  field: "createdBy",
  flex: 1
};

const billToCol = {
  headerName: "Bill To",
  field: "billTo",
  renderCell: (params: GridRenderCellParams<Customer>) => {
    return (
      <CustomerLink
        customerId={params.value?.id}
        customerName={params.value?.customerName ?? ""}
        isProspect={params.value?.isProspect}
      />
    );
  },
  flex: 1
};

const billingAddressCol = {
  headerName: "Billing Address",
  field: "billingAddress",
  renderCell: (params: GridRenderCellParams<any, BillingStatusQuote>) => {
    const billTo = params.row.billTo;
    const useBillingAddress = billTo?.hasBillingAddress;
    const addrElement = <>
      <div>{useBillingAddress ? billTo?.billingAddress1 : billTo?.address1 ?? ""}</div>
      <div>{useBillingAddress ? billTo?.billingAddress2 : billTo?.address2 ?? ""}</div>
      <div>{useBillingAddress ? billTo?.billingCity : billTo?.city ?? ""} {useBillingAddress ? billTo?.billingRegion?.regionAbbreviation : billTo?.region?.regionAbbreviation ?? ""} {getFormattedZipPostalCode(useBillingAddress ? billTo?.billingZipPostalCode : billTo) ?? ""}</div>
    </>;
    return addrElement;
  },
  flex: 2
};

const billingStatusCol = {
  headerName: "Billing Status",
  field: "billingStatus",
  renderCell: (params: GridRenderCellParams<CustomerBillingStatusEnum, BillingStatusQuote>) => billingStatusEnumTextMap[params.row.billTo?.billingStatus as CustomerBillingStatusEnum],
  flex: 1
};

const dueDaysCol = {
  headerName: "Due Days",
  field: "dueDays",
  renderCell: (params: GridRenderCellParams<number, BillingStatusQuote>) => params.row.billTo?.dueDays ?? "",
  width: 80
};

class _QuotesBillingStatusView extends React.Component<IQuotesBillingStatusViewProps, IQuotesBillingStatusViewState> {
  state: IQuotesBillingStatusViewState = {
    paymentConfirmationModalIsOpen: false,
    paymentConfirmationMessage: "",
    paymentConfirmationQuoteId: undefined
  };

  componentDidMount() {
    const { billingStatus } = this.props;
    this.props.quoteService.onResetBillingStatusSearchModel(billingStatus);
    this.props.employeeService.fetchAccOrBusDevManagers();
    this.props.quoteService.fetchBillingStatusQuotes(true);
    this.props.customerService.getBillToCustomers(billingStatus);
  }

  componentWillUnmount() {
    this.props.quoteService.onResetBillingStatusSearchModel();
  }

  private getActionCol() {
    return {
      headerName: "",
      field: "Action",
      renderCell: (params: GridRenderCellParams<any, BillingStatusQuote>) =>
        <ActionMenu
          billingStatus={this.props.billingStatus}
          customer={params.row.billTo}
          quote={params.row}
          openEditCustomerModal={this._openEditCustomerModal}
          onPaymentCollectedClick={this._onPaymentCollectedClick}
        />,
      width: this.props.billingStatus === "CheckCredit" ? 143 : 205,
      sortable: false,
      disableColumnMenu: true
    }
  }

  @bind
  private _getCreditCheckColumns(): GridColDef[] {
    return [
      companyLogoCol,
      freightBillNoCol,
      quoteDateCol,
      pickupDateCol,
      negotiatedRateCol,
      salesRepCol,
      billToCol,
      billingAddressCol,
      billingStatusCol,
      {
        headerName: "Credit Limit",
        field: "creditLimit",
        renderCell: (params: GridRenderCellParams<number, BillingStatusQuote>) => <DisplayFormattedNumber value={params.row.billTo?.creditLimit} formatString={CURRENCY_FORMAT} />,
        flex: 1
      },
      dueDaysCol,
      this.getActionCol()
    ];
  }

  @bind
  private _getCODCollectionsColumns(): GridColDef[] {
    return [
      companyLogoCol,
      freightBillNoCol,
      quoteDateCol,
      pickupDateCol,
      negotiatedRateCol,
      salesRepCol,
      billToCol,
      billingAddressCol,
      billingStatusCol,
      dueDaysCol,
      this.getActionCol()
    ];
  }

  @bind
  private _openEditCustomerModal(customer: Customer) {
    this.props.customerService.openAddEditCustomerModal("CreditChecksViewPage", customer);
  }

  @bind
  private _onPaymentCollectedClick(quote: BillingStatusQuote) {
    this.setState({
      paymentConfirmationModalIsOpen: true,
      paymentConfirmationMessage: `Are you sure you want to indicate that payment has been collected for freight bill ${quote.freightBillNumber}?`,
      paymentConfirmationQuoteId: quote.id
    });
  }

  @bind
  private async _onPaymentConfirm() {
    const quoteId = this.state.paymentConfirmationQuoteId;
    if (!quoteId) {
      return;
    }

    await this.props.QuoteEntryService.updateQuotePaymentCollected(quoteId);

    const result = this.props.QuoteEntryService.getState().quotePaymentCollectedResults;

    if (result?.data) {
      this.props.customerService.getBillToCustomers(this.props.billingStatus);
      this.props.quoteService.fetchBillingStatusQuotes(true);
      this.setState({
        paymentConfirmationModalIsOpen: false
      });
    }
  }

  @bind
  private async _saveCustomer(customer: Customer) {
    await this.props.customerService.updateSelectedCustomer(customer);

    const result = this.props.customerService.getState().saveCustomerResults;

    if (result?.data) {
      const { billingStatusSearchCriteria: creditChecksSearchCriteria } = this.props.quoteService.getState();
      if (creditChecksSearchCriteria.customerId) {
        this.props.quoteService.onSearchModelChanged({ customerId: undefined }, true);
      }
      this.props.customerService.getBillToCustomers(this.props.billingStatus);
      this.props.quoteService.fetchBillingStatusQuotes(true);
      this._cancelEdit();
    }
  }

  @bind
  private _cancelEdit() {
    this.props.customerService.closeAddEditModal();
  }

  @bind
  private _onSortChange(sortModel: GridSortModel) {
    const { billingStatus } = this.props;
    this.props.quoteService.onSearchModelChanged({
      sortColumn: sortModel.length > 0 ? sortModel[0].field : billingStatus === "CheckCredit" ? "billTo" : "pickupDate",
      sortAscending: sortModel.length > 0 ? sortModel[0].sort === "asc" : false
    }, true);

    this.props.quoteService.fetchBillingStatusQuotes(true);
  }

  render() {
    const {
      billingStatus
    } = this.props;

    const {
      paymentConfirmationModalIsOpen,
      paymentConfirmationMessage
    } = this.state;

    const {
      billingStatusQuotesFetchResults: checkCreditQuotesFetchResults
    } = this.props.quoteService.getState();

    const {
      regionFetchResults
    } = this.props.regionService.getState();
    const regionData = regionFetchResults.data ?? [];

    const {
      activeAccOrBusDevManagers
    } = this.props.employeeService.getState();
    const accOrBusDevManagers = _.orderBy(activeAccOrBusDevManagers ?? [], s => s.firstName);

    const {
      activeCustomerSources
    } = this.props.customerSourceService.getState();
    const customerSources = _.orderBy(activeCustomerSources ?? [], s => s.name);

    const {
      customerModalIsOpen,
      addEditCustomer
    } = this.props.customerService.getState();

    const quoteData = checkCreditQuotesFetchResults.data?.results ?? [];

    const isCheckCredit = billingStatus === "CheckCredit";

    const sortModel: GridSortModel = isCheckCredit 
      ? [{ field: "billTo", sort: "asc" }, { field: "pickupDate", sort: "asc" }] 
      : [{ field: "pickupDate", sort: "asc" }];

    return (
      <div className={styles.mainContainer}>
        <Card className={styles.cardStyle}>
          <CardHeader
            title={isCheckCredit ? "Quotes with Bill-To Requiring Credit Checks" : "Quotes Requiring Cash on Delivery"}
          />
          <CardActions disableSpacing>
            <QuotesBillingStatusSearchForm billingStatus={billingStatus}/>
          </CardActions>
          <AjaxActionIndicator
            state={[checkCreditQuotesFetchResults]}
          />
          <div className={styles.resultsGrid}>
            {(checkCreditQuotesFetchResults.hasFetched && (checkCreditQuotesFetchResults.data?.totalRecords ?? 0) > (checkCreditQuotesFetchResults.data?.numberOfRecords ?? 0)) ? (
              <div className={styles.resultsMessage}>
                {checkCreditQuotesFetchResults.data?.totalRecords} results found, {checkCreditQuotesFetchResults.data?.numberOfRecords} shown - please refine your search.
              </div>
            ) : undefined}

            <DataGridPro
              columns={isCheckCredit ? this._getCreditCheckColumns() : this._getCODCollectionsColumns()}
              rows={quoteData}
              classes={{
                cell: styles.resultCell
              }}
              density="compact"
              initialState={{
                sorting: { sortModel: sortModel }
              }}
              sortingMode="server"
              sortingOrder={["asc", "desc"]}
              getRowHeight={() => 64}
              onSortModelChange={this._onSortChange}
              disableSelectionOnClick
              hideFooter
            />
          </div>
        </Card>
        <AddEditCustomerModal
          isOpen={customerModalIsOpen === "CreditChecksViewPage"}
          isFetching={checkCreditQuotesFetchResults.isFetching}
          customer={addEditCustomer ?? {}}
          regions={regionData}
          accOrBusDevManagers={accOrBusDevManagers}
          customerSources={customerSources}
          onSave={this._saveCustomer}
          onCancel={this._cancelEdit}
        />
        <BinaryChoiceDialog
          isOpen={paymentConfirmationModalIsOpen}
          trueText="Confirm"
          falseText="Cancel"
          onClick={(value) => {
            if (value) {
              this._onPaymentConfirm();
            } else {
              this.setState({ paymentConfirmationModalIsOpen: false });
            }
          }}
          title="Payment Confirmation"
          message={paymentConfirmationMessage}
        />
      </div>
    );
  }
}

export const QuotesBillingStatusView =
  QuoteEntryService.inject(
    QuoteService.inject(
      CustomerService.inject(
        StateService.inject(
          EmployeeService.inject(
            LoadingInstructionService.inject(
              CustomerSourceService.inject(
            _QuotesBillingStatusView
  )))))));
